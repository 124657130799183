import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Privacyheader = () => {
  return (
    <>
      <header className="privacy_header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 text-center ">
                        <a href="/BMC_V2"> <img src="/assets/BMC_V2/img/logo.png" alt="" /></a>
                    </div>
                </div>
            </div>
        </header>
    </>
  );
};

export default Privacyheader;