import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../../assets/Signature/css/SignatureModal.scss";

const SignaturePreviewPopup = ({ show, onHide, ...props }) => {
  return (
    <Fragment>
      <Modal
        show={show}
        onHide={onHide}
        tabIndex="-1"
        aria-labelledby="Signpreview"
        size="md"
        className="modal-sp"
      >
        <Modal.Body className="modal-body-sp text-center">
          <h2>Does this look like the signature on your ID?</h2>
          <h3 className="pop3">
            <i>
              We must provide lenders with the matching details in their system
              to ensure a successful outcome. It should appear as if it were
              handwritten.
            </i>
          </h3>
          <img src={props?.signaturePreviewImage} alt="SignaturePreview" />
        </Modal.Body>
        <Modal.Footer className="modal-footer-sp">
          <Button className="btn-clear" onClick={props?.clearAction}>
            Retake
          </Button>
          <Button
            className="btn-submit"
            onClick={props?.submitAction}
            disabled={props?.isLoading}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default SignaturePreviewPopup;
