import React, { useEffect, useState, useRef, useContext } from "react";
import Footer from "../../Includes/Layouts/BMC_V2​/Footer";
import Faq from "../../Includes/Layouts/BMC_V2​/Faq";
import "../../../assets/BMC_V2/css/BMC_V2.scss";
import "../../../assets/BMC_V2/css/animate.css";
import "../../../assets/BMC_V2/css/choices.min.css";
import FormBMC_V2 from "../../Forms/FormBMC_V2";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";

const BMC_V2 =()=>{

    return(
      <>
    <AdtopiaLoadLP pageType="LP" splitName="BMC_V2" />
    <div className="BMC_V2">
    <header>
    <div className="container">
      <div className="shapes-container d-none d-lg-block">
        <div className="bg-shape" />
      </div>
    </div>
    <div className="col-lg-12 notification">
      <p>Looking for some good news? Boomerang Claims can help you get a refund on your junk insurance today!</p>  
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-12 col-md-12 col-lg-6 banner-part">
          <div className="logo">
            <img src="assets/BMC_V2/img/logo.png" alt="" />
          </div>
          <h1> Register your Refund for Junk Insurance Today. You Could be Owed $1000's!</h1>
          <p>According to the government, Junk Insurance policies like CCI were sold to millions of people in Australia; millions of people have paid a very high price for products that turned out to have very little value, or even no benefit at all.</p>
          <ul className="d-none d-lg-block">
            <li>No Account Number Needed</li>
            <li>No Paperwork Required</li>
            <li>Starting your claim is easy and 100% online</li>
          </ul>
        </div>
          <FormBMC_V2/>
          <div className="banner-part">
            <ul className="d-block d-lg-none ">
              <li>No Account Number Needed</li>
              <li>No Paperwork Required</li>
              <li>Starting your claim is easy and 100% online</li>
            </ul>
          </div>
      </div>
    </div>
  </header>
  <Faq/>
  <Footer/>
  </div>
  </>
    );
}
export default BMC_V2;