import React, { Fragment, useState, useEffect, useReducer } from "react";
import { queryString, urlParams } from "../../../Utility/QueryString";
import "../../../assets/Followup/css/Followup.scss";
import { Api } from "../.././../api/Api";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import Loader from "../../Includes/Layouts/Followup/Loader";
import { useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useUserDocumentFlp } from "../../../Hooks/useUserDocument";

var complete_arr = [];
const initialState = {
  showButton1: "hide",
  showTick1: "hide",
};

const userfile = {
  preview: undefined,
  title: undefined,
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "showButton": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "showTick": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showTick1: "show",
          showButton1: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};
const Followup = () => {
  const [clickedSlide, setClickedSlide] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [nationalIDCard, setNationalIDCard] = useState(false);
  const [driversLicense, setDriversLicense] = useState(false);
  const [passport, setPassport] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [signatureClass, SetSignatureClass] = useState(false);
  const [pendingStatus, setPendingStatus] = useState([]);
  const [showLoad, setshowLoad] = useState("show");
  const [showUserName, setUserName] = useState("");
  const [allFollowupData, setFollowupData] = useState({});
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
  const [pending, setPending] = useState(true);
  const [followupStatus, setFollowupStatus] = useState("Pending");
  const [currentUuid, setCurrentUuid] = useState("");
  const history = useHistory();
  const { saveUserDocumentFlp, isLoadingFlp } = useUserDocumentFlp();
  const userToken = urlParams.get("atp_sub2");
  const atpSub6 = urlParams.get("atp_sub6") ? urlParams.get("atp_sub6") : "FLP";
  localStorage.setItem("user_token", userToken);
  localStorage.setItem("atp_sub6", atpSub6);
  const setLoader = (e) => {
    setshowLoad("show");
  };
  const hideLoader = (e) => {
    setshowLoad("hide");
  };
  const showThankYou = (uuid) => {
    history.push("/thankyou?uuid=" + uuid);
  };

  const selectDocId = (e) => {
    retakeClick();
    // setSelectedDocType(e.target.value);
  };

  const retakeClick = () => {
    setDocumentModalShow(false);

    setNationalIDCard(false);
    setDriversLicense(false);
    setPassport(false);

    setClickedSlide({ slide: "retake" });
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }

      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc" == event.target.name) {
        setPassport(true);
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const changeCameraHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc_cam" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc_cam" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc_cam" == event.target.name) {
        setPassport(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const savedocument = async () => {
    let formdata = {
      imageFile: base64Image.base64TextString,
      captureType: selectedFileType.substring(selectedFileType.indexOf("doc")),
      documentType: selectedFileType.substr(0, selectedFileType.indexOf("_")),
    };
    let uuid = allFollowupData.flpData.user_info.uuid;
    let visitor_parameters = {
      uuid: uuid,
    };
    const response = saveUserDocumentFlp(
      formdata,
      allFollowupData.flpData.followup_data,
      allFollowupData.flpData.user_info,
      visitor_parameters
    );

    history.push("/thankyou?uuid=" + uuid);
  };

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/v1/followup/get-pending-details?query_string=" + queryString,
        {}
      );
      const splitName = response.data.response.user_info.split_name;
      if (["BMC_P_V5", "BMC_P_5.1", "BMC_P_5.2"].includes(splitName)) {
        return history.push(`/followup_p_v5?${queryString}`);
      }

      if (["BMC_P_6"].includes(splitName)) {
        return history.push(`/followup_p_v6?${queryString}`);
      }

      const followupDetails = response.data.response.followup_data;
      if (userToken == null) {
        localStorage.setItem("user_token", followupDetails.atp_sub2);
      }
      setUserName(response.data.response.user_info.user_name);
      setFollowupData({
        flpData: response.data.response,
      });
      localStorage.setItem(
        "user_info",
        JSON.stringify(response.data.response.user_info)
      );
      setCurrentUuid(response.data.response.user_info.uuid);
      if (response.data.status === "Success") {
        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          "FLP-ID",
          queryString,
          "v2"
        );
        setTimeout(() => {
          hideLoader();
        }, 2000);
        if (response.data.response.pending_details.is_user_complete === 1) {
          showThankYou(response.data.response.user_info.uuid);
          return false;
        }
        if (response.data.response.pending_details.is_user_sign === 0) {
          setPendingStatus({
            status_btn: "signature",
            status_flag: "showButton",
          });
        } else {
          setPendingStatus({
            status_btn: "signature",
            status_flag: "showTick",
          });
        }
      }
    })();
  }, []);
  useEffect(() => {
    if (pendingStatus) {
      dispatch({ type: pendingStatus.status_flag, payload: { pendingStatus } });
      if (pendingStatus.status_flag == "showTick") {
        complete_arr.push(pendingStatus.status_btn);
        if (complete_arr.indexOf("signature") > -1) {
          setPending(false);
          setFollowupStatus("Review Stage");
        }
      }
    }
  }, [pendingStatus]);
  return (
    <Fragment>
      <div className="followupPage">
        <Loader showLoad={showLoad}></Loader>
        <div
          id="myDiv2"
          className="slide2 myDiv2"
          style={
            showLoad === "hide" ? { display: "block" } : { display: "none" }
          }
        >
          <header>
            <div className="container">
              <div className="row">
                <div
                  className={`col-xl-12 col-12 col-md-12 col-lg-12  logo text-center`}
                >
                  <img src="assets/UserDocument/img/logo.png" alt="" />
                </div>
              </div>
            </div>
          </header>

          <section className="content">
            <div className={`container text-center`}>
              <div className="intro col-xl-12 col-lg-12 col-12">
                <img src="assets/UserDocument/img/top-img.jpg" alt="" />
                <h2>Verify Your identity</h2>
                <h3>You must provide 1 proof(s) of address to proceed.</h3>
                <p>
                  Tip - Please provide a photo of one of the following:{" "}
                  <strong>National ID Card, Driver's License, Passport</strong>
                </p>
              </div>
              <form
                name="docs_form"
                id="user_form"
                method="POST"
                encType="multipart/form-data"
              >
                <div className="uploading-part col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-12">
                  <Accordion className="accordion  accordion-flush" id="faqs">
                    <Accordion.Item
                      eventKey="1"
                      className="accordion-item accordion-item"
                      onClick={selectDocId}
                    >
                      <Accordion.Button className="accordion-button accordion-button collapsed idone">
                        National ID Card
                      </Accordion.Button>
                      <Accordion.Body>
                        <div className="upload-area col-lg-12 col-12">
                          {nationalIDCard && !isInValidfFile ? (
                            <img
                              src={userfile.preview}
                              alt={userfile.title}
                              id="nationalIDCard"
                            />
                          ) : (
                            <img
                              src="assets/UserDocument/img/upload-demo.jpg"
                              alt=""
                            />
                          )}

                          <div className="col-lg-12 col-12">
                            {!nationalIDCard && (
                              <div>
                                <span className="button-tab browsebut">
                                  <img
                                    src="assets/UserDocument/img/browse.png"
                                    alt=""
                                  />{" "}
                                  Browse from Your Device
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="idTypeFile"
                                    name="nationalIDCard_doc"
                                    onChange={changeFilesHandler}
                                    onClick={(e) => (e.target.value = null)}
                                  />
                                </span>
                                <a className="button-tab">
                                  <img
                                    src="assets/UserDocument/img/camera.png"
                                    alt=""
                                  />{" "}
                                  Take picture of Your Id{" "}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    id="idTypeFileCamera"
                                    name="nationalIDCard_doc_cam"
                                    onChange={changeCameraHandler}
                                    onClick={(e) => (e.target.value = null)}
                                  />
                                </a>
                              </div>
                            )}
                            {nationalIDCard && (
                              <div>
                                {isInValidfFile && <p>{errorMessage.txt}</p>}
                                {!isInValidfFile && (
                                  <span className="button-tab browsebut">
                                    Submit
                                    <input
                                      type="button"
                                      value="Submit"
                                      onClick={savedocument}
                                    />
                                  </span>
                                )}
                                <span className="button-tab browsebut red">
                                  Retake Photo
                                  <input
                                    type="button"
                                    value="Retake Photo"
                                    onClick={retakeClick}
                                  />
                                </span>
                                {isLoadingFlp && <p>Please Wait...</p>}
                              </div>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="2"
                      className="accordion-item"
                      onClick={selectDocId}
                    >
                      <Accordion.Button className="accordion-button accordion-button collapsed idone">
                        Driver's License
                      </Accordion.Button>
                      <Accordion.Body>
                        <div className="upload-area col-lg-12 col-12">
                          {driversLicense && !isInValidfFile ? (
                            <img src={userfile.preview} alt={userfile.title} />
                          ) : (
                            <img
                              src="assets/UserDocument/img/upload-demo.jpg"
                              alt=""
                            />
                          )}

                          <div className="col-lg-12 col-12">
                            {!driversLicense && (
                              <div>
                                <span className="button-tab browsebut">
                                  <img
                                    src="assets/UserDocument/img/browse.png"
                                    alt=""
                                  />{" "}
                                  Browse from Your Device
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="idTypeFile"
                                    name="driversLicense_doc"
                                    onChange={changeFilesHandler}
                                    onClick={(e) => (e.target.value = null)}
                                  />
                                </span>
                                <a className="button-tab">
                                  <img
                                    src="assets/UserDocument/img/camera.png"
                                    alt=""
                                  />{" "}
                                  Take picture of Your Id
                                  <input
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    name="driversLicense_doc_cam"
                                    onChange={changeCameraHandler}
                                    onClick={(e) => (e.target.value = null)}
                                  />
                                </a>
                              </div>
                            )}
                            {driversLicense && (
                              <div>
                                {isInValidfFile && <p>{errorMessage.txt}</p>}
                                {!isInValidfFile && (
                                  <span className="button-tab browsebut">
                                    Submit
                                    <input
                                      type="button"
                                      value="Submit"
                                      onClick={savedocument}
                                    />
                                  </span>
                                )}
                                <span className="button-tab browsebut red">
                                  Retake Photo
                                  <input
                                    type="button"
                                    value="Retake Photo"
                                    onClick={retakeClick}
                                  />
                                </span>
                                {isLoadingFlp && <p>Please Wait...</p>}
                              </div>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="3"
                      className="accordion-item"
                      onClick={selectDocId}
                    >
                      <Accordion.Button className="accordion-button accordion-button collapsed idone">
                        Passport
                      </Accordion.Button>
                      <Accordion.Body>
                        <div className="upload-area col-lg-12 col-12">
                          {passport && !isInValidfFile ? (
                            <img src={userfile.preview} alt={userfile.title} />
                          ) : (
                            <img
                              src="assets/UserDocument/img/upload-demo.jpg"
                              alt=""
                            />
                          )}

                          <div className="col-lg-12 col-12">
                            {!passport && (
                              <div>
                                <span className="button-tab browsebut">
                                  <img
                                    src="assets/UserDocument/img/browse.png"
                                    alt=""
                                  />{" "}
                                  Browse from Your Device
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="idTypeFile"
                                    name="passport_doc"
                                    onChange={changeFilesHandler}
                                    onClick={(e) => (e.target.value = null)}
                                  />
                                </span>
                                <a className="button-tab">
                                  <img
                                    src="assets/UserDocument/img/camera.png"
                                    alt=""
                                  />{" "}
                                  Take picture of Your Id
                                  <input
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    id="idTypeFileCamera"
                                    name="passport_doc_cam"
                                    onChange={changeCameraHandler}
                                    onClick={(e) => (e.target.value = null)}
                                  />
                                </a>
                              </div>
                            )}
                            {passport && (
                              <div>
                                {isInValidfFile && <p>{errorMessage.txt}</p>}
                                {!isInValidfFile && (
                                  <span className="button-tab browsebut">
                                    Submit
                                    <input
                                      type="button"
                                      value="Submit"
                                      onClick={savedocument}
                                    />
                                  </span>
                                )}
                                <span className="button-tab browsebut red">
                                  Retake Photo
                                  <input
                                    type="button"
                                    value="Retake Photo"
                                    onClick={retakeClick}
                                  />
                                </span>
                                {isLoadingFlp && <p>Please Wait...</p>}
                              </div>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </form>
              <div className="doc-op">
                <ul>
                  <li> Document should be Clear &amp; Legible</li>
                  <li> Entire Document should be Captured</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default Followup;
