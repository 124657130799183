import React, { useState } from "react";
import ProgressBar from "../../Layouts/MCS_V1/ProgressBar";
import SelectBox from '../../../UI/SelectBox';
import { DobMonth } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";
import BirthDateModal from "./BirthDateModal";
import { NumberValidation } from "../../../../Utility/NumberValidation";

const DOB = ({
  className,
  slideChange,
  textHeading,
  validation,
  validationMsg,
  trigger,
  splitForm,
  setError
}) => {
  const [modalClass, SetModalClass] = useState("modal fade");
  const nextSlide = async (e) => {
    const form = splitForm.current;
    console.log(form["DobDay"].value);
    let errorFlag = 0;
    var txtDobMonth = await trigger("DobMonth");
    var txtDobDay;
    var txtDobYear;
    var flag = 1;
    if (form["DobDay"].value < 1 || form["DobDay"].value > 31) {
      flag = 0;
    }
    if (txtDobMonth) {
      txtDobDay = await trigger("DobDay");
    }
    else {
      return false;
    }
    if (txtDobDay) {
      if (flag == 0) {
        setError("DobDay", {
          type: "manual",
          message: "Please Enter Valid Day",
        });
        return false;
      } else {
        txtDobYear = await trigger("DobYear");
      }
    }
    else {
      return false;
    }
    if (
      !txtDobMonth ||
      !txtDobDay ||
      !txtDobYear
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };
  const handleChange = (e) => {
    const input = e.target.value;
    if (input != "") {
      if (input < 1 || input > 31) {
        setError("DobDay", {
          type: "manual",
          message: "Please Enter Valid Day",
        });
      }
    }
  }
  const showModal = (e) => {
    document.body.classList.add("no-scroll");
    document.getElementById("analayzeModal").classList.add('show');
    document.getElementById("analayzeModal").classList.remove('hide');
    SetModalClass("modal show");
  }
  return (
    <>
      <div id="slide3" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="60" />
        <h3>{textHeading}</h3>
        <p className="text-center mb-4"><a href="#" onClick={(e) => { showModal(e) }}>Why we need your birthdate?</a></p>
        <div className="form-group">
          <div className="col-lg-12 text-center">
            <div className="row">
              <div className=" form-group col-lg-5 col-md-4 col-sm-4 col-12 mb-2">
                <SelectBox
                  className="form-select"
                  OptionValue={DobMonth}
                  name="DobMonth"
                  id="DobMonth"
                  myRef={validation({
                    required: "Please Select Month"
                  })}
                  validationMsg={validationMsg.DobMonth && validationMsg.DobMonth.message}
                />
              </div>
              <div className=" form-group col-lg-4 col-md-4 col-sm-4 col-7 mb-2">
                <TextField
                  type="tel"
                  className="m-0 form-control"
                  placeholder="Day"
                  name="DobDay"
                  id="DobDay"
                  keypress={NumberValidation}
                  onBlur={handleChange}
                  maxlength="2"
                  validation={validation({
                    required: "Please Enter Day",
                    maxLength: {
                      value: 2,
                      message: "Please Enter Day",
                    },
                    pattern: {
                      value: /^[0-9]*$/i,
                      message: "Please Enter Day",
                    },
                  })}
                />
                {validationMsg.DobDay && (
                  <span
                    className="error_msg"
                  >
                    {validationMsg.DobDay.message}
                  </span>
                )}
              </div>
              <div className=" form-group col-lg-3 col-md-4 col-sm-4 col-5 txl">
                <div className="year_bx">
                  <span className="yer">19</span>
                  <TextField
                    type="tel"
                    className="m-0 yr_birth"
                    placeholder="##"
                    name="DobYear"
                    id="DobYear"
                    keypress={NumberValidation}
                    maxlength="2"
                    minLength="2"
                    validation={validation({
                      required: "Please Enter Year",
                      maxLength: {
                        value: 2,
                        message: "Please Enter Year",
                      },
                      minLength: {
                        value: 2,
                        message: "Please Enter Year",
                      },
                      pattern: {
                        value: /^[0-9]*$/i,
                        message: "Please Enter Year",
                      },
                    })}
                  />
                </div>  {validationMsg.DobYear && (
                  <span
                    className="error_msg_dob"
                  >
                    {validationMsg.DobYear.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <InputButton
                name="user_dob"
                className="btn continue_btn next03"
                id=""
                value="Continue >"
                btnType="button"
                onClick={nextSlide}
                style={{ float: "none" }}
              />
              <p className="mb-4"></p>
            </div>
          </div>
        </div>
        <BirthDateModal modalClass={modalClass} />
      </div>
    </>
  );
};

export default DOB;
