import React, { useContext, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { getParameterByName } from "../../Utility/Helper";

require("dotenv").config();
const FbPixel = () => {
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  console.log(visitorParameters);
  let current_domain = visitorParameters.data.existingdomain;

  console.log(current_domain);
  if (current_domain) {
    current_domain = current_domain
      .replace("http://", "")
      .replace("https://", "")
      .split(/[/?#]/)[0];
  }
  const query = new URLSearchParams(window.location.search);
  const localSplitName = localStorage.getItem("split_name");
  const splitName = localSplitName ? localSplitName : query.get("split_name");

  console.log(query.get("pid"));
  //query.set("pid", "bar");
  console.log(window.location);
  console.log(queryString);

  useEffect(() => {
    if (queryString != null) {
      if (queryString.indexOf("&" + field + "=") !== -1) {
        const pid = getParameterByName(field, queryString);
        if (
          current_domain === visitorParameters.data.redirectDomain ||
          // process.env.REACT_APP_URL.includes("http://localhost")
          window.location.origin.includes("http://localhost")
        ) {
          console.log("fb pixel iffff");
          console.log(queryString);
          const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
          const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
          };
          const query_url = window.location.href;
          const full_redirect_url = query_url.substring(
            query_url.indexOf("redirect_url=") + 13
          );
          ReactPixel.init(pid, advancedMatching, options);
          ReactPixel.pageView();
          ReactPixel.track("Lead");

          let uuid = visitorParameters.visitor_parameters.uuid;
          console.log(uuid);
          if (uuid === null || uuid === undefined) {
            uuid = query.get("uuid")
              ? query.get("uuid")
              : localStorage.getItem("uuid");
          }
          console.log(uuid);

          const splitRouteMap = {
            BMC_P_V5: "lender_P_V5",
            "BMC_P_5.1": "lender_P_V5.1",
            "BMC_P_5.2": "lender_P_V5.2",
            BMC_V3: "lender_V2",
            BMC_P_6: "id-upload-v6",
          };

          const route = splitRouteMap?.[splitName];
          console.log({
            route,
            splitName,
            splitRouteMap,
          });
          if (route) {
            history.push(`/${route}?uuid=${uuid}`);
          } else {
            history.push(`/lender?uuid=${uuid}`);
          }
        } else if (splitName == "BMC_V3") {
          console.log("fb pixel BMC_V3");
          const app_url = process.env.REACT_APP_URL;
          const redirect_url = app_url + "lender_V2";
          const advRedirectDomain =
            "https://" + visitorParameters.data.redirectDomain;
          const fb_pixel_url =
            advRedirectDomain +
            "/fbpixel?" +
            queryString +
            "&redirect_url=" +
            redirect_url;
          window.location.href = fb_pixel_url;
          //console.log(fb_pixel_url);
        } else {
          console.log("fb pixel elseee");
          //const app_url = process.env.REACT_APP_URL;
          const app_url = window.location.origin;
          let uuid = visitorParameters.visitor_parameters.uuid;
          console.log(uuid);
          if (uuid === null || uuid === undefined) {
            uuid = query.get("uuid")
              ? query.get("uuid")
              : localStorage.getItem("uuid");
          }

          const redirect_url = app_url + "lender";
          const advRedirectDomain =
            "https://" + visitorParameters.data.redirectDomain;
          const fb_pixel_url =
            advRedirectDomain +
            "/fbpixel?" +
            queryString +
            "&redirect_url=" +
            redirect_url;
          window.location.href = fb_pixel_url;
          //console.log(fb_pixel_url);
        }
      }
    }
  }, [queryString]);
  return <></>;
};

export default FbPixel;
