import React from "react";
import Header from "../../Includes/Layouts/BMC_V1/Header";
import Footer from "../../Includes/Layouts/BMC_V1/Footer";
import "../../../assets/BMC_V1/css/BMC_V1.scss";
import "../../../assets/BMC_V1/css/animate.css";
import FormBMC_V1 from "../../Forms/FormBMC_V1";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FrequentQuestions from "../../Includes/Layouts/BMC_V1/FrequentQuestions";

const BMC_V1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="BMC_V1" />
        <Header />
        <section className="top-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-1 offset-md-1 col-md-4 col-sm-6 col-6">
                <a href="#"><img src="/assets/BMC_V1/img/logo.png" alt="" /></a>
              </div>
              {/* <div className="col-lg-5 offset-lg-1 col-sm-6 col-6 text-end">
                <a href="https://www.afca.org.au/" target="_blank"><img src="/assets/BMC_V1/img/logo-afca.svg" alt="" className="img2" /></a>
              </div> */}
            </div>
          </div>
        </section>
        <section className="bnr_part">
          <div className="container">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
              <div className="form">
                <FormBMC_V1 />
              </div>
            </div>
          </div>
        </section>
        <FrequentQuestions />
        <Footer />
    </>
  );
};

export default BMC_V1;
