import { useCallback, useState } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import { CommonEndApi } from "../api/Api";

export const usePostCodeAddress = () => {
  const path = "api";
  const [isLoading, setIsLoading] = useState(false);

  const getPostcodeAddress = useCallback(async (postcode) => {
    setIsLoading(true);

    let response = await CommonEndApi.get(
      `${path}/get-addr-list?ProjectCode=` +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&postcode=" +
        postcode +
        "&searchby=Address",
      {}
    ).catch(function (error) {
      setIsLoading(false);
      // return any error messages from api once failed
      return error?.response?.data?.Error ?? error?.response?.data?.response;
    });

    setIsLoading(false);

    if (response?.data) {
      response.data = response.data.map((a) => ({
        value: a.id,
        label: a.address,
      }));
    }

    return response;
  }, []);

  const getSplitPostcodeAddress = useCallback(async (AddressID, postcode) => {
    setIsLoading(true);

    let response = await CommonEndApi.get(
      `${path}/get-addr-details?ProjectCode=` +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&addressId=" +
        AddressID,
      {}
    ).catch(function () {
      setIsLoading(false);
    });

    setIsLoading(false);

    if (response.data) {
      response.data = {
        ...response.data,
        address_line_1: response.data.line_1,
        address_line_2: response.data.line_2,
        suburb: response.data.locality,
      };
    }

    return response;
  }, []);

  return { getPostcodeAddress, getSplitPostcodeAddress, isLoading };
};
