import React,{useEffect, useState, useRef, useReducer, useContext} from "react";
import ProgressBar from "../../Layouts/BMC_V2​/ProgressBar";
import Telephone from "../../../UI/Telephone";
import Email from "../../../UI/Email";
import Button from "../../../UI/Button";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";

const ContactDetails = ({
  className,slideChange,validation,validationMsg,setError, getValues,trigger}) => {

    const {emailValidation} = useEmailValidation();
    const { phoneValidation} = usePhoneValidation();
    const [emailCheck, setEmailCheck] = useState('hide');
    const scrollRef = useRef([]);
   
    const basicDetailsValidation = async (e) => {
      const values = getValues();
      let errorFlag=0;
      const txtPhone = values["txtPhone"];
      const txtEmail = values["txtEmail"];
      let txtPhoneResult;
      let txtEmailResult;
      txtPhoneResult = await trigger("txtPhone");
      if (txtPhoneResult) {
        if (
            txtPhone.trim() == "" ||
            txtPhone.trim().length == 0
        ) {
            scrollToElement('txtPhone');
            return false;
        } else {
            txtEmailResult = await trigger("txtEmail");
        }

        if (txtEmailResult) {
                if (
                    txtEmail.trim() == "" ||
                    txtEmail.trim().length == 0
                ) {
                    scrollToElement('txtEmail');
                    return false;
                }
            } else {
                scrollToElement('txtEmail');
                // window.scrollTo(0, 0)
                return false;
            }
        }
        if (txtEmail && txtPhone) {
            if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
                errorFlag = 0;
                slideChange(e);
            } else {
                return false;
            }
        }
  };

  const scrollToElement = (element) => {
    scrollRef.current[element]?.scrollIntoView({
        behavior: "smooth",
        block: 'center',
        inline: 'center'
    });
};
   
    const basic_onchangehandle = async (e) => {
      document.getElementById("slide5").disabled = false;
      trigger(e.target.name);
  };


    const emailValidCheck = async () => {
      const values = getValues();
      const email = values['txtEmail'];
      if (email !== "") {
          if (/^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(email)) {
              setEmailCheck('show');
              const getEmailValidation = await emailValidation(email);
              if (getEmailValidation.data.status_code === 0) {
                  scrollToElement('txtEmail');
                  setError("txtEmail", {
                      type: "manual",
                      message: "Email Already Exist",
                  });
                  setEmailCheck('hide');
                  document.getElementById("slide5").disabled = false;
                  return 0;
              } else if (getEmailValidation.data.status_code === 2) {
                  scrollToElement('txtEmail');
                  setError("txtEmail", {
                      type: "manual",
                      message: "Email is Invalid",
                  });
                  setEmailCheck('hide');
                  document.getElementById("slide5").disabled = false;
                  return 0;
              } else {

                  setEmailCheck('hide');
                  return 1;
              }
          } else {
              scrollToElement('txtEmail');
              setError("txtEmail", {
                  type: "manual",
                  message: "Invalid Email Address",
              });
              document.getElementById("slide5").disabled = false;
              return 0;
          }
      } else {
          document.getElementById("slide5").disabled = false;
          trigger("txtEmail");
          return 0;
      }
  };
   const phoneValidCheck = async () => {
        const values = getValues();
        const phone = values['txtPhone'];
        if (phone !== "") {
            const getPhoneValidation = await phoneValidation(phone)
            if (
                getPhoneValidation.data.status_code === 0 ||
                getPhoneValidation.data.status === "Fail"
            ) {
                scrollToElement('txtPhone');
                setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Phone Number",
                });
                document.getElementById("slide5").disabled = false;
                return 0;
            } else if (getPhoneValidation.data.status_code === 2) {
                scrollToElement('txtPhone');
                setError("txtPhone", {
                    type: "manual",
                    message: "Phone Number Already Exist",
                });
                document.getElementById("slide5").disabled = false;
                return 0;
            } else if (getPhoneValidation.data.status_code === 3) {
                scrollToElement('txtPhone');
                setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Phone Number",
                });
                document.getElementById("slide5").disabled = false;
                return 0;
            } else {
                return 1;
            }
        }
    };


    return(
        <div id="slide5" className={className}>
           <ProgressBar value="80" />
              <div className="mb-3">
                <h4>Enter Your contact details</h4>
              </div>
                <div className="input-group mb-3">
                      {/* <input type="text" className="form-control" placeholder="Mobile Phone" /> */}
                      <Telephone
                        name="txtPhone"
                        className="form-control"
                        placeholder="Mobile Phone"
                        maxlength="11"
                        maxlength="11"
                            onBlur={phoneValidCheck}
                            onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                            validation={validation({ required: "Please Enter Valid Phone Number" })}
                            // validationMsg={validationMsg.txtPhone && validationMsg.txtPhone.message}
                            onChange ={basic_onchangehandle}
                    ></Telephone>
                    <span className="input-group-text"><img src="assets/BMC_V2/img/privacy.png" alt="" /> </span>
                </div>
            
                  {validationMsg.txtPhone && (
                <span
                  className="error_msg "
                  id="phone_err"
                >
                  {validationMsg.txtPhone.message}
                </span>
              )}
                <div className="input-group mb-3">
                        <Email 
                          name="txtEmail"
                          className="form-control"
                          placeholder="Email Address"
                          onBlur={emailValidCheck}
                           onChange ={basic_onchangehandle}
                          validation={validation({
                          required: "Please Enter Valid Email Address",
                          pattern: {
                          value:
                              /^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
                          message: "Invalid Email Address",
                          },
                          })}
                        //    validationMsg={validationMsg.txtEmail && validationMsg.txtEmail.message}
                          >
                      </Email>
                    <span className="input-group-text"><img src="assets/BMC_V2/img/privacy.png" alt="" /> </span>
                </div>
                {validationMsg.txtEmail && (
                    <span
                    className="error_msg "
                    id="email_err"
                    >
                    {validationMsg.txtEmail.message}
                    </span>
                )}
                    
                <div className="mb-3">
                  <Button
                    type="button"
                    className="btn-next"
                    id="slide5"
                    name="slide5"
                    buttonText="Next"
                    onClick={basicDetailsValidation}
                    />
                </div>
      </div>

    );
};

export default ContactDetails;