import React, { useEffect, useRef, useContext } from "react";
import Header from "../../Components/Includes/Layouts/MCS_V1/Header";
import Footer from "../../Components/Includes/Layouts/MCS_V1/Footer";
import { useForm } from "react-hook-form";
import TextField from "../UI/TextField";
import InputButton from "../UI/InputButton";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { CheckUUID } from "../../Utility/CheckUUID";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import Telephone from "../UI/Telephone";
import Email from "../UI/Email";
import { usePhoneValidation } from "../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../Hooks/useEmailValidation";

const UserFormMCS_V1 = () => {
  const { isCheck, } = CheckUUID();
  isCheck();
  const { register, errors, trigger, setError, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const userForm = useRef(null);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { phoneValidation } = usePhoneValidation();
  const { emailValidation } = useEmailValidation();
  const history = useHistory();
  const field = "pid";
  useEffect(async () => {
    if (localStorage.getItem('formData') === null || localStorage.getItem('formData') === '') {
      window.location = "/MCS_V1";

    }
  }, []);
  const formData = JSON.parse(localStorage.getItem('formData'));
  const basicDetailsValidation = async (e) => {
    var txtFName = await trigger("txtFName");
    var txtLName;
    var txtEmail;
    var txtPhone;
    if (txtFName) {
      txtLName = await trigger("txtLName");
    }
    else {
      return false;
    }
    if (txtLName) {
      txtEmail = await trigger("txtEmail");
    }
    else {
      return false;
    }
    if (txtEmail) {
      txtPhone = await trigger("txtPhone");
    }
    else {
      return false;
    }
    if (txtFName && txtLName && txtEmail && txtPhone) {
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        formSubmit();
      }
    }
  }
  const formSubmit = async () => {
    const values = getValues();
    formData.txtFName = values.txtFName;
    formData.txtLName = values.txtLName;
    formData.txtEmail = values.txtEmail;
    formData.txtPhone = values.txtPhone;
    localStorage.setItem('formData', JSON.stringify(formData));
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      'split_form_submit',
      'MCS_V1',
      queryString,
      visitorParameters.data
    )
    if (formSUbmitResult.data.status === "Success") {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppEnv === 'pre' || EnvConstants.AppEnv === 'prod') {
            history.push("/fbpixel");
          } else {
            window.scrollTo(0, 0);
            history.push("/signature?uuid=" + visitorParameters.visitor_parameters.uuid);
          }
        } else {
          window.scrollTo(0, 0);
          history.push("/signature?uuid=" + visitorParameters.visitor_parameters.uuid);
        }
      } else {
        window.scrollTo(0, 0);
        history.push("/signature?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
    }
  }
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
        return 1;
      }
    }
  };
  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
    const txtEmail = await trigger("txtEmail");
    if (txtEmail) {
      const getEmailValidation = await emailValidation(email)
      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: "Email Already Exist",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };
  const validNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
  return (
    <>
      <GetVisitorsParams />
      <div className="medicare_landingpage_02">
        <Header />
        <section className="heding_sec" id="hding">
          <div className="container">
            <div className="col-lg-6 offset-lg-3 col-12 text-center">
              <h1>2022: Find the Best Medicare Plan for You</h1>
            </div>
          </div>
        </section>
        <section className="form-part" id="firstform">
          <div className="container nopad">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
              <form ref={userForm} name="user_form" id="user_form" method="POST" autoComplete="off">
                <div id="slide5" className="col-12 top_minus_1">
                  <div className="progress progrss" data-percentage="99">
                    <span className="progress-left">
                      <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                      <span className="progress-bar" id="progressBar"></span>
                    </span>
                    <div className="progress-value">
                      <div id="progressBar_content">
                        <span className="perce">99%</span><br />
                        <span>completed</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="pb-2 lst_stp"><strong>LAST STEP!</strong></h3>
                  <p className="text-center laststp_p mb-4">
                    Please enter your details below to access the best plans & bonus benefits in your area
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
                      ⓘ
                                        </a>
                  </p>
                  <div className="form-group mb-2">
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="txtFName"
                        id="txtFName"
                        validation={register({
                          required: "Please Enter First Name",
                          minLength: {
                            value: 3,
                            message: "Please Enter Valid First Name",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: "Your Name is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={
                          errors.txtFName && errors.txtFName.message
                        }
                      ></TextField>
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="txtLName"
                        id="txtLName"
                        validation={register({
                          required: "Please Enter Last Name",
                          minLength: {
                            value: 3,
                            message: "Please Enter Valid Last Name",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: "Your Name is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={
                          errors.txtLName && errors.txtLName.message
                        }
                      ></TextField>
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <div className="input-group ">
                      <Email
                        name="txtEmail"
                        id="txtEmail"
                        className="form-control"
                        placeholder="Email Address"
                        onBlur={emailValidCheck}
                        validation={register({
                          required: "Please Enter Valid Email Address",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid Email Address",
                          },
                        })}
                      ></Email>
                      <div className="input-group-append"> <span className="input-group-text">
                        <img src="assets/MCS_V1/img/privacy.png" alt="" />
                      </span>
                      </div>
                      {errors.txtEmail && (
                        <span
                          className="error_msg "
                          id="email_err"
                        >
                          {errors.txtEmail.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" form-group mb-2">
                    <div className="input-group ">
                      <Telephone
                        name="txtPhone"
                        id="txtPhone"
                        className='form-control'
                        placeholder="Phone Number"
                        onBlur={phoneValidCheck}
                        onKeyPress={validNumber}
                        maxlength="16"
                        validation={register({
                          pattern: {
                            value: /^[0-9]*$/i,
                            message: "Please Enter Valid Phone Number",
                          },
                          required: "Please Enter Valid Phone Number",
                        })}
                        validationMsg={
                          errors.txtPhone && errors.txtPhone.message
                        }
                      ></Telephone>
                      <div className="input-group-append"> <span className="input-group-text">
                        <img src="assets/MCS_V1/img/privacy.png" alt="" />
                      </span>
                      </div>
                      {errors.txtPhone && (
                        <span
                          className="error_msg "
                          id="phone_err"
                        >
                          {errors.txtPhone.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12 text-center pad-15">
                    <InputButton
                      name="user_slide"
                      className="btn continue_btn submitt mb-4"
                      id=""
                      value="Get Your Results >"
                      btnType="button"
                      onClick={basicDetailsValidation}
                      style={{ float: "none" }}
                    />
                    <p className="pb-4">
                      A valid phone number and email address is required. We highly respect your privacy
                      and will not send you spam or unwanted emails
                                        </p>
                  </div>
                </div>
              </form>
              <div className="col-lg-12 text-center client_call">
                <p className="spek_agent">Speak to a Licensed Agent</p>
                <h6>
                  <a href="#">
                    <img src="/assets/MCS_V1/img/Call.png" alt="" />
                                        (833) 888-8888 (TTY:111)
                                    </a>
                </h6>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default UserFormMCS_V1;