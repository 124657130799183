import React, { useEffect, useState } from "react";
// import { useLoanList } from "../../../../Hooks/UseLoanList";
import { useLenderList } from "../../../../Hooks/UseLenderList";

const Toplenders = ({setTopLenderSelectedList,reff,setTopLenderValidationMsg,setIsTopLenders})=>{
    
    // const {loanList}  = useLoanList();
	const {lenderList} = useLenderList();
    // const [loansloop , setLoansloop] = useState({});
	const [topLendersloop , setTopLendersloop] = useState({});

    const [topLenderSelectedValue] = useState([]);
    const onSelectTopLender  = (e)=>{
       if(e.target.checked === true) {
		    setIsTopLenders(true);
            topLenderSelectedValue.push({id: +e.target.value})
            setTopLenderValidationMsg(false);
        } else {
			topLenderSelectedValue.pop({id: +e.target.value})
            if(topLenderSelectedValue.length <= 0){
                setIsTopLenders(false);
                setTopLenderValidationMsg("Please select any lender");
            }
        }
        setTopLenderSelectedList(topLenderSelectedValue); 
    };



    let topLenderOption ;
    useEffect (()=>{
       (async ()=>{
           const tplenders = await lenderList();
           setTopLendersloop({toplender:tplenders.data.response.toplender});
       })();
    },[]);

    let bigcls;
    if(topLendersloop.toplender){
        topLenderOption = topLendersloop.toplender.map((topLenderData, index) => {
            if(topLenderData.id == 27 || topLenderData.id == 55 || topLenderData.id == 53)
            {
                bigcls = 'bigcls';
            }
            else {
                bigcls = '';
            }
            return (
                <div className={`form-check`} key={index}>
                    <input type="checkbox" className={`form-check-input custom-checkbox`} onChange={onSelectTopLender} name="chkLoanName[]" id={`loans${index}`}  value={topLenderData.id}/>
                    <label htmlFor={`loans${index}`}  className={`form-check-label ${bigcls}`}>{topLenderData.bank_name}</label>
                </div>
            );
        });
        }

    return(
        <>
            <fieldset className="scheduler-border" ref={(el) => (reff.current['loansec'] = el)}>
                <legend className="float-none w-auto px-3">Select which lenders you've taken out loans in the last 15 years.</legend>
                <div className="col-lg-12">
                    { topLenderOption}
                </div>
            </fieldset>
        </>
    );
}

export default Toplenders;