import React from 'react';
const Footer = (links,label) => {

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>© Boomerang Claims Pty Limited  </h3>
              <p>ABN 71 623 924 087
              </p>
              <p>contact@boomerangclaims.com</p>
              <p>Boomerang Claims Limited, Level 2 41A Bay Road, Taren Point, New South Wales, NSW 2229</p>
              <p>Boomerang Claims are proud members of the Australian Financial Complaints Authority. 
                Our membership number is 89098
              </p>
              <p>When deciding how to proceed with your Junk Insurance claim, remember you always have the right to contact AFCA for free and impartial advice and support with your claim. 
                Call AFCA free on 1800 931 678
              </p>
              <ul>
                <li><a href="/privacy" target="_blank"> Privacy Policy</a></li>
                <li><a href="/terms" target="_blank"> Terms of Use</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;