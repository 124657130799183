import React from 'react';
import "../../assets/Thankyou/css/thankyou.scss"
import Privacyheader from '../Includes/Layouts/BMC_V1/Privacyheader';

const Thankyou = () => {
  localStorage.clear();
  return (
    <>
      <div className="boomer_thanku">
        <Privacyheader />
        <section className="content-section">
            <div className="container ">
                <div className="row ">
                    <div className="col-lg-10 offset-lg-1 col-sm-02 col-12 mp0  text-center animated bounceInDown">
                        <img src="/assets/Thankyou/img/thumb1.png" alt=""/>
                        <h1>Thank you</h1>
                        <p>We have received your enquiry.</p>
                        <p>One of our Agents will get back to you soon.</p>
                    </div>
                </div>
            </div>
         </section>
      </div>
    </>
  )
}

export default Thankyou;
