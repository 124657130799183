import React, { useEffect, useState, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import BasicQuestionnaireOne from "../Includes/Layouts/MCS_V1/BasicQuestionnaireOne";
import BasicQuestionnaireTwo from "../Includes/Layouts/MCS_V1/BasicQuestionnaireTwo";
import DOB from "../Includes/Layouts/MCS_V1/DOB";
import PostCode from "../Includes/Layouts/MCS_V1/PostCode";

const FormMCS_V1 = () => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide10: "hide"
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "question_1") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_2") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "user_dob") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_1") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_3") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide7: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "user_slide") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "address") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "hide",
            showSlide9: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "joint_slide") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide9: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide9: "hide",
            showSlide10: "show",
          };
        }
        else {
          return {
            ...state,
          };
        }
      }
      default:{
        
      }
    }
  };
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const slideChange = (e) => {
    window.scrollTo(0, 0);
    setClickedSlide({ slide: e.target.name });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  return (
    <>
      {/* <!--  ========= FormSection  Area Start  ========= --> */}
      <form ref={splitForm} name="split_form" id="user_form" method="POST" autoComplete="off">

        <BasicQuestionnaireOne
          className={`form-group text-center ${state.showSlide1}`}
          textHeading="Are you currently enrolled in Medicare?"
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
        />
        <BasicQuestionnaireTwo
          className={`form-group text-center ${state.showSlide2}`}
          textHeading="Are you receiving benefits from the MediCal program?"
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
        />
        <DOB
          className={`form-group text-center ${state.showSlide3}`}
          textHeading="What is your date of birth?"
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          splitForm={splitForm}
          setError={setError}
          slideChange={slideChange}
          getValues={getValues}
        />
        <PostCode
          className={`form-group text-center ${state.showSlide4}`}
          textHeading="What is your ZIP Code?"
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          splitForm={splitForm}
          setError={setError}
          getValues={getValues}
          clearErrors={clearErrors}
        />
      </form>
      {/* <!--  ========= FormSection  Area End  ========= --> */}
      <div className="col-lg-12 text-center client_call">
        <p className="spek_agent">Speak to a Licensed Agent</p>
        <h6>
          <a href="#">
            <img src="/assets/MCS_V1/img/Call.png" alt="" />
                        (833) 888-8888 (TTY:111)
              </a>
        </h6>
      </div>
    </>
  );
}

export default FormMCS_V1;