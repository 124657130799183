import React from "react";
import "../../../../assets/BMC_V2/css/BMC_V2.scss";
import "../../../../assets/BMC_V2/css/animate.css";
import Privacyheader from "../BMC_V2​/Privacyheader";
import Privacyfooter from "../BMC_V2​/Privacyfooter";

const TermsV2 = () => {
  const hrStyle = { marginTop: 0, color: "black" };
  const olStyle = { listStyleType: "none", paddingLeft: "0rem" };
  return (
    <>
      <body>
        <Privacyheader />
        <section class="Privacy">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-12 text-start">
                <h4>1. DEFINITIONS</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Advocacy Services: Our efforts to support your Claim for a
                    Refund.
                  </li>
                  <li>
                    Applicable Products: Insurance policies or loans potentially
                    mis-sold to you.
                  </li>
                  <li>
                    Claim: Your authorization for us to seek a Refund on your
                    behalf.
                  </li>
                  <li>
                    Client Portal: Our digital platform for document and
                    information submission.
                  </li>
                  <li>
                    Document Review Services: Our initial assessment to
                    determine the viability of your Claim.
                  </li>
                  <li>
                    Fee: A payment of 36% of the Refund, payable to us upon
                    successful Claim resolution.
                  </li>
                  <li>
                    Provider: Financial institutions such as banks and insurance
                    companies.
                  </li>
                  <li>
                    Refund: The amount reimbursed to you by a Provider as a
                    result of a successful Claim.
                  </li>
                </ol>
                <h4>2. PROCESS OVERVIEW</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Account Setup: Agreement acceptance upon Client Portal
                    registration.
                  </li>
                  <li>
                    Information Submission: Provide required details via the
                    Client Portal for Claim evaluation.
                  </li>
                  <li>
                    Review and Assessment: We assess your Claim's potential
                    through the provided information.
                  </li>
                  <li>
                    Advocacy Execution: If viable, we proceed to advocate for
                    your Claim with the Provider.
                  </li>
                  <li>
                    Refund and Fee: Successful Claims lead to a Refund, from
                    which our Fee is deducted.
                  </li>
                </ol>
                <h4>3. OUR SERVICES</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Document Review: Analysis of your information to decide on
                    Claim progression. No fee is charged if we determine the
                    Claim is unlikely to succeed.
                  </li>
                  <li>
                    Advocacy: Upon a positive initial review, we will move
                    forward and represent your Claim to the Provider, aiming for
                    a successful Refund. If we believe the Provider makes a fair
                    offer to you, we will accept it on your behalf. If we
                    believe the Provider makes an unfair decision, we will refer
                    the case to AFCA on your behalf at no additional charge.
                  </li>
                  <li>
                    Consent to Proceed: In instances where accepting an offer of
                    compensation on your behalf would result in the termination
                    of a live insurance policy then we will seek your consent to
                    proceed, in all other instances where we believe the offer
                    to be fair and reasonable we will accept it.
                  </li>
                </ol>
                <h4>4. YOUR COMMITMENT</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Provide Accurate Information: You must ensure that all
                    information you provide to us related to your Applicable
                    Products is complete and truthful. You must promptly inform
                    us of any changes in your information or circumstances that
                    might affect the Claim.
                  </li>
                  <li>
                    Exclusive Representation: If you engage our services, it's
                    essential that you do so exclusively for the Claim in
                    question. Should you involve another company for the same
                    Claim, either before, during, or after engaging with us, we
                    reserve the right to charge a fee of up to $99 to cover the
                    initial work conducted on your behalf. This fee compensates
                    for the resources expended in assessing a Claim that cannot
                    be successfully pursued due to prior or concurrent
                    representation.
                  </li>
                  <li>
                    Refund Processing: Upon successfully securing a Refund for
                    you, we will attempt to contact you up to 8 times using the
                    email and phone number provided to arrange the transfer of
                    your Refund. If these attempts don't result in a response,
                    we may hold the unclaimed funds in a trust account and make
                    an additional 4 attempts to reach you, incurring a charge of
                    up to $20 per attempt to cover our outreach costs. Should
                    these efforts remain unsuccessful, we reserve the right to
                    retain the funds, with the responsibility then shifting to
                    you to contact us for any claims to the Refund.
                  </li>
                </ol>
                <h4>5. FEES AND PAYMENTS</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    No Win, No Fee: You owe us nothing if we do not secure a
                    Refund.
                  </li>
                  <li>
                    Fee Structure: If a Refund is obtained, you agree to pay us
                    36% of the Refund amount as our Fee
                  </li>
                  <li>
                    Cancellation Policy: You may cancel within 10 days without
                    incurring fees. Post-cooling-off period, a cancellation fee
                    may apply, calculated as the greater of $99 or 36% of the
                    potential Refund estimated by us, to cover the work already
                    performed.
                  </li>
                </ol>
                <h4>6. COMMUNICATIONS</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Client Portal: Our primary communication channel for updates
                    and information exchange.
                  </li>
                  <li>
                    Data Security: We take precautions to protect your
                    information but cannot guarantee absolute security online.
                  </li>
                  <li>
                    Written Confirmations: For clarity and record-keeping, we
                    may request written confirmation of verbal instructions.
                  </li>
                </ol>
                <h4>7. PRIVACY AND CONFIDENTIALITY</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Data Protection: We adhere to privacy laws and our Privacy
                    Policy in handling your personal information.
                  </li>
                  <li>
                    Confidentiality: We commit to keeping your information
                    confidential, disclosing it only with your consent or as
                    required by law.
                  </li>
                </ol>
                <h4>8. DISPUTE RESOLUTION</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Feedback: We welcome your feedback and are committed to
                    resolving any complaints in accordance with our internal
                    procedures.
                  </li>
                  <li>
                    Disputes: Any disputes arising under this Agreement should
                    be communicated to us in writing for resolution. This can be
                    at our registered office or via email at{" "}
                    <a href="#">contact@boomerangclaims.com</a>
                  </li>
                </ol>
                <h4>9. GENERAL TERMS</h4>
                <hr style={hrStyle} />
                <ol type="a" style={olStyle}>
                  <li>
                    Governing Law: This Agreement is governed by the laws of New
                    South Wales, Australia
                  </li>
                  <li>
                    Entire Agreement: This document constitutes the full
                    agreement between you and Boomerang Claims, superseding any
                    prior discussions.
                  </li>
                  <li>
                    Amendments: Any changes to this Agreement must be in writing
                    and agreed upon by both parties.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="BMC_V2">
          <Privacyfooter link2={"/privacyV2"} text2="Privacy Policy" />
        </div>
      </body>
    </>
  );
};

export default TermsV2;
