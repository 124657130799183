import React, { useEffect } from "react";
import "../../assets/Unqualified/css/unqualified.scss";
import UnqualifiedFooter from "../Includes/Layouts/UnqualifiedFooter";
import UnqualifiedHeader from "../Includes/Layouts/UnqualifiedHeader";

const Unqualified = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <div className="HomeLoanCredit_Unqualified_TY_page">
        <UnqualifiedHeader />

        <section>
          <div className="container">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 text-center">
              <img src="/assets/Unqualified/img/smiley.png" alt="" />
              <h1>SORRY!</h1>
              <p>We are unable to process with your claim</p>
            </div>
          </div>
        </section>

        <UnqualifiedFooter />
      </div>
    </>
  );
};

export default Unqualified;
