import React,{useEffect, useState, useRef, useReducer, useContext} from "react";
import SignatureCanvas from "react-signature-canvas";
import InputButton from "../../../UI/InputButton";

const initialState = {
  isCanvasEmpty: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const Signature = ({ className, slideChange,validation,validationMsg,getValues,formSubmit,setSignPad}) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const [isSignValid, setIsSignValid] = useState(false);
  const[active1,setActive1]=useState();
  const[active2,setActive2]=useState();
  const[active3,setActive3]=useState();
  const [isShown, setIsShown] = useState(true);
  const signPadRef = useRef();
  const splitForm = useRef(null);

  const onclass = (e) => {
    setActive1("message pull");
    setActive2("top open");
    setActive3("hide");
  }
  const onsubmit = (e) => {
    const values = getValues();
    const form = splitForm.current;
    const formData = values;
    const signPad = signPadRef.current;
    const signatureData = signPad.getSignaturePad().toData();
    // setActive1("message push");
    // const timer = setTimeout(() => {
    //   setActive2("top");
    //   setIsShown(current => !current); 
    // }, 2500);
    //clearTimeout(timer);
    try
    {

      const signPad = signPadRef.current;
       if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }  else {
        setIsSignValid(false);
        let signdata = signPad.getSignaturePad().toData();
        let signlength = 0;
        signdata.forEach((key) => {
          signlength = signlength + key.length;
        })
        if (signlength > 13) {
          setSignPad(signPad.getCanvas().toDataURL("image/png"));
          setActive1("message push");
          const timer = setTimeout(() => {
            setActive2("top");
            setIsShown(current => !current); 
          }, 2500);
          // formSubmit();
        } else {
          setIsSignValid(true);
          return dispatch({ type: "validSignature", payload: { value: true } });
        }
    } 
  }
    catch (e) 
    {
      console.warn(e);
    }

  };


  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
    setIsSignValid(false);
    // setSignaturePreviewModalShow(false);
    // setSignaturePreviewImage('');
  };

  return(
    <div id="slide6"  className={className}>           
      <div className="mb-3 text-center">
        <h4>Please sign your signature in the box below</h4>
        <p>Please try and make it as close as possible to the signature your bank uses to identify you.</p>
      </div>
      <div className="mb-3 ">
        <div className="frame">
          <div className="confidentail-div" style={{display: isShown ?  'none': 'block'}}>
            <img src="/assets/BMC_V2/img/confidential.png" alt="" />
          </div>
          <div id = "button_open_envelope" className={`button_open_envelope ${active3} evp`} onClick={onclass}>
            Click here to Sign
          </div>
          <div className={`message ${active1}`}>
            <div className="signature-div">
              <SignatureCanvas
                // clearOnResize={false}
                canvasProps={{
                  width: 338, 
                  height: 150
                }}
                ref={signPadRef}
                onEnd={() => {
                  if (!signPadRef.current.isEmpty()) {
                    dispatch({
                      type: "signPadChange",
                      payload: { value: false },
                    });
                  }
                }}
              /> 
            </div>  
            {state.isCanvasEmpty && (
              <span id="signatures_required" className="error_msg">
                Signature is required!
              </span>
            )}
             {isSignValid && (
              <span id="signatures_required" className="error_msg">
                Draw valid signature
              </span>
              )}
              
            <button type="button" className="button-clear mb-0" onClick={clearSign}>Clear</button>
            <input type="button" value="Submit" id="send"  onClick={onsubmit} className="sendb" />

          </div>
          <div className="bottom" />			
          <div className="left" />
          <div className="right" />
          <div className={`top ${active2}`} />
        </div>
      </div>
    </div>
  );
};
export default Signature;