import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useContext,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../UI/Button";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import TextField from "../../UI/TextField";
import AddressTextField from "../../UI/AddressTextField";
import { usePostCodeAddress } from "../../../Hooks/usePostCodeAddress";
import HiddenField from "../../UI/HiddenField";
import SelectBox from "../../UI/SelectBox";
import lineImg from "../../../assets/BMC_P_V5_2/img/line.png";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};

const PrevAddressPopup = ({
  handleClose,
  show,
  validation,
  clearErrors,
  setError,
  trigger,
  validationMsg,
  formSubmit,
}) => {
  const [showAddress, setShowAddress] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const {
    getPostcodeValidation,
    getPostcodeAddress,
    getSplitPostcodeAddress,
    isLoading,
  } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const [titleShow, setTitleShow] = useState("show");
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtSearchKey2 = await trigger("txtSearchKey2");
    if (txtSearchKey2 && e.target.value != "") {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtSearchKey2", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtSearchKey2", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    clearErrors([
      "txtAddressLine1",
      "txtPrevAddress",
      "txtCounty",
      "txtTown2",
      "txtCountry2",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtAddressLine1",
        "txtPrevAddress",
        "txtCounty",
        "txtTown2",
        "txtCountry2",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: {}, manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtSearchKey2");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtPrevHouseNumber").value;
    const addressLine2 = document.getElementById("txtPrevAddress").value;
    const town = document.getElementById("txtTown2").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry2").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const submitPreviousAddress = async (e) => {
    const previousName = await trigger("txtPreName");
    if (previousName) {
      formSubmit();
    }
    // var manual_txtPrevHouseNumber;
    // var manual_txtTown2;
    // var manual_txtCountry2;
    // var txtAddress;
    // if (txtSearchKey2) {
    //   manual_txtPrevHouseNumber = await trigger("txtPrevHouseNumber");
    //   txtAddress = await trigger("address2");
    // }
    // else {
    //   document.getElementById("txtSearchKey2").focus();
    //   return false;
    // }
    // if (manual_txtPrevHouseNumber) {
    //   manual_txtTown2 = await trigger("txtTown2");
    // } else {
    //   return false;
    // }
    // if (manual_txtTown2) {
    //   manual_txtCountry2 = await trigger("txtCountry2");
    // } else {
    //   return false;
    // }
    // if (
    //   !txtSearchKey2 ||
    //   !manual_txtPrevHouseNumber ||
    //   !manual_txtTown2 ||
    //   !manual_txtCountry2
    // ) {
    //   errorFlag = 1;
    // }

    // if (state.clickManualLink === 0) {
    //   const address2 = await trigger("address2");
    //   if (!address2) {
    //     errorFlag = 1;
    //   }
    // }
    // if (errorFlag === 0) {
    //   checkPostCodeEntry();
    //   formSubmit();
    // } else {
    //   return false;
    // }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  const clickAddress = (value) => {
    if (value == 1) {
      setTitleShow("d-none");
      setShowAddress(true);
    } else {
      formSubmit();
    }
  };

  const revertClickAddress = () => {
    setTitleShow("show");
    setShowAddress(false);
  };

  return (
    <div
      className={`modal fade  blackover ${show ? "show" : "hide"}`}
      //className={`modal fade ${show ? "show" : "hide"}`}
      id="submitBtn"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered pop-style">
        <div className="modal-content content-custom">
          {/* <div
            className={`modal-header text-center justify-content-center ${titleShow}`}
            style={{ background: "white" }}
          >
            
          </div>  */}
          <div className="modal-body">
            <button
              type="button"
              className="close-b close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                revertClickAddress();
                handleClose();
              }}
            >
              ×
            </button>

            <div className={`cp1 ${!showAddress ? "" : "d-none"}`}>
              <h1
                className="modal-title imp_head text-center"
                id="exampleModalLabel"
              >
                {" "}
                IMPORTANT
              </h1>
              <p>
                You may have taken your CCI when you lived at a previous address
                and/or under a previous name.{" "}
              </p>
              <p>
                To help your lender identify all the credit agreements you may
                have had, you will now be asked to provide any previous names
                and addresses.
              </p>

              <p>
                <img
                  src={lineImg} // "https://pre.loanclaims.unfairfees.co.uk/assets/0602UFVCC_A2/img/line.png"
                  alt=""
                />
              </p>
              <ul className="options pt-2 pb-0 ps-0">
                <li className="popupLink mb-2">
                  <TextField
                    type="radio"
                    name="preDetails"
                    id="preDetails1"
                    className="custom-checkbox bank-name"
                    defaultValue="No"
                  ></TextField>
                  <label
                    className="mb-0"
                    htmlFor="preDetails1"
                    id="cc"
                    onClick={() => clickAddress(2)}
                  >
                    I don't have any previous name(s) / address(es)
                  </label>
                </li>
                <li className="popupLink">
                  <TextField
                    type="radio"
                    name="preDetails"
                    id="preDetails2"
                    className="custom-checkbox bank-name slide9_nxtbtn"
                    defaultValue="Yes"
                  ></TextField>
                  <label
                    htmlFor="preDetails2"
                    id="previous1"
                    onClick={() => clickAddress(1)}
                  >
                    I have previous name(s) / address(es)
                  </label>
                </li>
              </ul>
            </div>

            <div
              className={`form-bg popup-form ${showAddress ? "" : "d-none"}`}
            >
              <div className="form-body">
                <fieldset className="scheduler-border mt-2">
                  <legend> Enter your previous address (if applicable)</legend>
                  <div className="row preaddress">
                    <div className=" col-sm-7 form-group">
                      <AddressTextField
                        type="text"
                        placeholder="Enter Address Keyword"
                        name="txtSearchKey2"
                        id="txtSearchKey2"
                        className={`form-control`}
                        dataId="txtSearchKey2"
                        onBlur={checkValidation}
                        autoComplete="off"
                        validation={validation({
                          // required: "Please Enter Address Keyword",
                        })}
                        style={{ height: "44px" }}
                      />
                      <i className="validate" aria-hidden="true"></i>
                    </div>
                    <div className="col-sm-5 form-group form-g2 ps-lg-0 ps-md-0  ps-sm-0 mt-3 mt-lg-0 mt-md-0 mt-sm-0">
                      {!isLoading && (
                        <button
                          type="button"
                          className="button-submit"
                          id="lookup2"
                          style={{ height: "44px" }}
                          onClick={lookupValidation}
                        >
                          Lookup Address
                        </button>
                      )}

                      {!!isLoading && (
                        <button
                          name="loading"
                          type="button"
                          className={`button-submit lookup`}
                          disabled
                          style={{ padding: "1px 15px", height: "44px" }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/buttonLoader.gif"
                            }
                            alt="loading..."
                          />
                        </button>
                      )}
                    </div>
                    {validationMsg.txtSearchKey2 && (
                      <span className="address1_error error_msg">
                        Please Enter a Valid Postcode
                      </span>
                    )}
                  </div>
                  <div
                    className="pstcode postcdb mt-3"
                    style={
                      state.checkSelect
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="mb-3">
                      <SelectBox
                        className="form-control"
                        OptionValue={state.getAddress}
                        name="txtAddress2"
                        id="txtAddress2"
                        onChange={getValue}
                        myRef={validation({
                          //required: "Please select address",
                        })}
                        validationMsg={
                          validationMsg.txtAddress2 &&
                          validationMsg.txtAddress2.message
                        }
                      />
                    </div>
                  </div>

                  <div className={`subform2 ${state.showManualAddress}`}>
                    <div className="mb-3">
                      <AddressTextField
                        type="text"
                        placeholder="Address Line 1 *"
                        name="txtPrevAddressLine1"
                        id="txtPrevAddressLine1"
                        className="form-control"
                        dataId="txtPrevAddressLine1"
                        autoComplete="off"
                        readonly={true}
                        validation={validation({
                          // required: "Please Enter Address Line 1",
                        })}
                        validationMsg={
                          validationMsg.txtPrevAddressLine1 &&
                          validationMsg.txtPrevAddressLine1.message
                        }
                        value={state.getDetails.address_line_1}
                      />
                    </div>
                    <div className="mb-3">
                      <AddressTextField
                        type={
                          state.getDetails.address_line_2 === null
                            ? "hidden"
                            : "text"
                        }
                        placeholder="Address Line 2"
                        name="txtPrevAddressLine2"
                        id="txtPrevAddressLine2"
                        className="form-control"
                        dataId="txtPrevAddressLine2"
                        autoComplete="off"
                        readonly={true}
                        validation={validation()}
                        validationMsg=""
                        value={state.getDetails.address_line_2}
                      />
                    </div>
                    <div className={`col-lg-12 form-group adj_width`}>
                      <AddressTextField
                        type="text"
                        placeholder="Suburb"
                        name="txtPrevSuburb"
                        id="txtPrevSuburb"
                        className="form-control mb-3  hero-input"
                        dataId="txtPrevSuburb"
                        autoComplete="off"
                        readonly={true}
                        validation={validation()}
                        validationMsg=""
                        value={state.getDetails.suburb}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`col-lg-12 form-group adj_width}`}>
                      <AddressTextField
                        type="text"
                        placeholder="Previous State"
                        name="txtPrevState"
                        id="txtPrevState"
                        className="form-control mb-3 hero-input"
                        dataId="txtPrevState"
                        autoComplete="off"
                        readonly={true}
                        validation={validation()}
                        validationMsg={
                          validationMsg.txtPrevState &&
                          validationMsg.txtPrevState.message
                        }
                        value={state.getDetails.state}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`col-lg-12 form-group adj_width}`}>
                      <AddressTextField
                        type="text"
                        placeholder="Previous Pincode"
                        name="txtPrevPostcode"
                        id="txtPrevPostcode"
                        className="form-control mb-3 hero-input"
                        dataId="txtPrevPostcode"
                        autoComplete="off"
                        readonly={true}
                        validation={validation()}
                        validationMsg={
                          validationMsg.txtPrevPostcode &&
                          validationMsg.txtPrevPostcode.message
                        }
                        value={state.getDetails.postcode}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="scheduler-border mt-4 mb-3">
                  <legend>
                    {" "}
                    Enter your previous surname below.(if applicable)
                  </legend>
                  <div id="preNameDiv">
                    <div className=" form-group">
                      <TextField
                        type="text"
                        autoComplete="off"
                        id="txtPreName"
                        name="txtPreName"
                        className="form-control"
                        placeholder="Previous Surname"
                        validation={validation({
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: "Your Name is Invalid. Please Recheck",
                          },
                        })}
                      ></TextField>
                      {validationMsg.txtPreName && (
                        <span className="error_msg">
                          {validationMsg.txtPreName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="addressbox"
                    id="add_prename"
                    style={{ display: "none" }}
                  >
                    <h6>Previous surname:</h6>
                    <div
                      className="previous-name-details"
                      id="previous-name-details"
                    ></div>
                    {/* <a href="#" className="hideadrbox">
                      <p className="text-right">
                        <span className="previousNameRemove">
                          <img
                            src="https://loanclaims.unfairfees.co.uk/assets/0602UFMB_A1_1/img/remove.svg"
                            alt=""
                          />
                          Remove
                        </span>
                      </p>
                    </a> */}
                  </div>
                </fieldset>

                <div className="text-center mt-2">
                  <a>
                    <button
                      name="btnPreSubmit"
                      className="button-submit"
                      id="btnPreSubmit"
                      type="button"
                      onClick={submitPreviousAddress}
                    >
                      Submit
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrevAddressPopup;
