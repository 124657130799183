import React, { useEffect, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { useLenderList } from "../../../../Hooks/UseLenderList";

const Banks = ({setLenderSelectedList,reff,setLenderValidationMsg,setIsLenders,setOtherBankSelectedList})=>{
    
    const {lenderList}  = useLenderList();
    const [lendersloop , setLendersloop] = useState({});

    const [lenderSelectedValue, setLenderSelectedValue] = useState([]);

    const onSelectOtherBank = (selectedList) => {
        // if((lenderSelectedValue.length == 10) ||((lenderSelectedValue.length + otherBankSelectedList.length) > 10)){
        //     setBankValidationMsg("You could not select more than 10 banks/lenders");
        // }else{
        // setBankValidationMsg("");
        // }
        setIsLenders(true);
        setOtherBankSelectedList(selectedList);
    };
    const onRemoveOtherBank = (selectedList) => {
        // if((lenderSelectedValue.length + otherBankSelectedList.length) <= 10){
        // setBankValidationMsg("");
        // }
        if(lenderSelectedValue.length <= 0 && setOtherBankSelectedList.length <= 0){
            setIsLenders(false);
            setLenderValidationMsg("Please Select Any Lender");
        }
        setOtherBankSelectedList(selectedList);
    };

    const onSelectLoan  = (e)=>{
        let bankId = e.target.value;
        if(e.target.checked === true) {
            setIsLenders(true);
            lenderSelectedValue.push({id: +e.target.value})
            setLenderValidationMsg(false);
            setLenderSelectedList(lenderSelectedValue); 
        } else {
            let otherFilterProducts = lenderSelectedValue.filter(el => {
              return el.id != bankId;
            });
            
            if(otherFilterProducts.length <= 0 && setOtherBankSelectedList.length <= 0){
                setIsLenders(false);
                setLenderValidationMsg("Please Select Any Lender");
            }
            setLenderSelectedValue(otherFilterProducts);
            setLenderSelectedList(otherFilterProducts); 
        }        
    };

    let lenderOption1 ;
    let lenderOption2 ;
    useEffect (()=>{
       (async ()=>{
           const lender = await lenderList();
           setLendersloop({bigfive:lender.data.response.bigfive,lender:lender.data.response.lender, otherBanks:lender.data.response.other_banks});
       })();
    },[]);

    if(lendersloop.bigfive){
        lenderOption1 = lendersloop.bigfive.map((lenderData, index) => {
            
            return (
                <>
                <ul className="form-check1">
                    <input type="checkbox" className="form-check-input custom-checkbox full-width" onChange={onSelectLoan} name="chkLenderName[]" id={`biglenders${index}`}  value={lenderData.id}/>
                    <label htmlFor={`biglenders${index}`}  className="form-check-label">{lenderData.bank_name}</label>
                </ul>
                </>
            );
        });
    }

    if(lendersloop.lender){
        lenderOption2 = lendersloop.lender.map((lenderData, index) => {
            
            return (
                <>
                <ul className="form-check">
                    <input type="checkbox" className="form-check-input custom-checkbox" onChange={onSelectLoan} name="chkLenderName[]" id={`lenders${index}`}  value={lenderData.id}/>
                    <label htmlFor={`lenders${index}`}  className="form-check-label">{lenderData.bank_name}</label>
                </ul>
                </>
            );
        });
    }

    return(
        <>
            <div className="col-lg-12">

                <div className="clearfix">
                    <fieldset className="scheduler-border lenders-fieldset">
                        <legend className="scheduler-border">Big Four</legend>
                        { lenderOption1 }
                    </fieldset>
                </div>

                <div className="clearfix">
                    <fieldset className="scheduler-border lenders-fieldset">
                        <legend className="scheduler-border">I also had a loan from one of these lenders:</legend>
                    { lenderOption2 }
                    </fieldset>
                </div>
                <div
                    className="col-lg-12 text-center"
                    style={{ padding: "0px" }}
                >
                    <fieldset className="scheduler-border lenders-fieldset">
                        {/* <h3 style={{ marginTop: "10px", textAlign: "left", lineHeight: "20px", fontSize: "17px"}}>
                        Others
                        </h3> */}
                        <legend className="scheduler-border">Others</legend>
                        <Multiselect
                        options={lendersloop.otherBanks} // Options to display in the dropdown
                        onSelect={onSelectOtherBank} // Function will trigger on select event
                        onRemove={onRemoveOtherBank} // Function will trigger on remove event
                        displayValue="bank_name" // Property name to display in the dropdown options
                        />
                    </fieldset>
                </div>
            </div>
            
        </>
    );
}

export default Banks;