import { React, useEffect, useReducer, useState } from "react";
import TextField from "../Components/UI/TextField";
import Anchor from "../Components/UI/Anchor";
import SelectBox from "../Components/UI/SelectBox";
import HiddenField from "../Components/UI/HiddenField";
import Button from "../Components/UI/Button";
import { usePostCodeAddress } from "../Hooks/usePostCodeAddress";
import { useFormContext } from "react-hook-form";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 1,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    default:
      return state;
  }
};
const PostCodeFind = ({
  validation,
  validationMsg,
  onBackClick,
  clearErrors,
  setError,
  reff,
  slideChange,
  trigger,
  CP_V1Style,
  adjustClass,
}) => {
  const { getPostcodeAddress, getSplitPostcodeAddress, isLoading } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);


  const {setValue, errors, watch} = useFormContext()

  const checkValidation = async (value) => {
    // e.preventDefault();
    const txtSearchKey = await trigger("txtSearchKey");
    if (txtSearchKey) {
      const addressList = await getPostcodeAddress(value);
      if (addressList.status != "200") {
        // setError("txtSearchKey", {
        //   type: "manual",
        //   message: "Please Enter Different Keyword",
        // });

        setError("txtSearchKey", { type: "custom", message: addressList });
      } else {
        console.log(addressList.data);
        dispatch({
          type: "validationSucess",
          payload: {
            lookup: false,
            next_click: true,
            postcode: value,
            get_address: addressList,
            get_address: addressList.data,
            check_select: true,
            manual_address: "hide",
          },
        });
      }

      // state.getDetails="";
      // const getValidation = await getPostcodeAddress(e.target.value);
      // if (getValidation.data.status === '0' || getValidation.data == "Please Enter Valid Address Keyword!") {
      //   setError("txtSearchKey", {
      //     type: "manual",
      //     message: "Please Enter Valid Postcode",
      //   });
      // }
      // console.log(getValidation.data);
      // if (getValidation.data != "Please Enter Valid Address Keyword!") {
      //   dispatch({
      //     type: 'validationSucess',
      //     payload: {
      //       lookup: false,
      //       next_click: true,
      //       postcode: e.target.value,
      //       get_address: getValidation.data,
      //       check_select: true,
      //       manual_address: "hide",
      //     }
      //   });
      // }
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    // clearErrors(["txtAddress1", "txtAddress2", "txtTown"]);
    // console.log(state.getPostcode);
    // const getData = await getSplitPostcodeAddress(e.target.value, state.getPostcode);
    // console.log(getData);
    // dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show", manual_click: 0 } });

    if (e.target.value) {
      clearErrors([
        "txtAddressLine1",
        "txtAddressLine2",
        "txtSuburb",
        "txtState",
        "txtPostCode",
      ]);
      const getData = await getSplitPostcodeAddress(e.target.value);
      console.log(getData);

      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      clearErrors([
        "txtAddressLine1",
        "txtAddressLine2",
        "txtSuburb",
        "txtState",
        "txtPostCode",
        "txtAddress",
      ]);
    } else {
      clearErrors([
        "txtAddressLine1",
        "txtAddressLine2",
        "txtSuburb",
        "txtState",
        "txtPostCode",
      ]);
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: {}, manual_address: "hide" },
      });
    }
  };

  const lookupValidation = async (e) => {
    // await trigger("txtSearchKey");

    const value = watch("txtSearchKey")
    checkValidation(value)

  };
  const clearValidationMsg = async (e) => {
    clearErrors(["txtSearchKey"]);
  };

  // const HiddenFields = () => (
  //   <>
  //     <HiddenField
  //       name="txtAddress3"
  //       inputId="txtAddress3"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.Line3 : ""}
  //     />
  //     <HiddenField
  //       name="txtAddress4"
  //       inputId="txtAddress4"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.Line4 : ""}
  //     />
  //     <HiddenField
  //       name="txtLocality"
  //       inputId="txtLocality"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.Locality : ""}
  //     />
  //     <HiddenField
  //       name="txtCounty"
  //       inputId="txtCounty"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.County : ""}
  //     />
  //     <HiddenField
  //       name="txtCountry"
  //       inputId="txtCountry"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.Country : ""}
  //     />
  //     <HiddenField
  //       name="txtDistrict"
  //       inputId="txtDistrict"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.District : ""}
  //     />
  //     <HiddenField
  //       name="hdPostcode"
  //       inputId="hdPostcode"
  //       inputValue={state.getDetails !== "#" ? state.getDetails.Postcode : ""}
  //     />
  //   </>
  // );


  


  useEffect(() => { 
      if (!!errors["txtAddress"]) {
        setError("txtSearchKey", {
         type: "custom",
         message: "Please select address",
       });
     }
     
  }, [errors["txtAddress"]]);

  return (
    <>
      <div
        className=" form-group"
        ref={(el) => (reff.current["txtSearchKey"] = el)}
      >
        <TextField
          type="text"
          //placeholder="Enter Postcode"
          placeholder="Enter Address Keyword"
          name="txtSearchKey"
          className="form-control"
          dataId="txtSearchKey"
          onBlur={(e) => checkValidation(e.target.value)}
          onChange={(e) => {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: true,
                next_click: false,
                check_select: false,
                manual_address: "hide",
                get_address: [],
                manual_click: 1,
              },
            });

            setValue(
              "txtSearchKey",
              e.target.value.replace(/[^a-zA-Z0-9\s]*$/, "")
            );
          }}
          autoComplete="off"
          validation={validation({
            required: "Please Enter Address Keyword",
            pattern: {
              value: /([A-Za-z0-9\s]*)$/,
              message: "Please Enter a Valid Address",
            },
            minLength: {
              value: 4,
              message: "Address keyword requires a minimum of 4 characters",
            },
          })}
        />
        {/* <input autocomplete="off"  placeholder="Postcode" name="txtFName" id="txtFName" value="" type="text" className="form-control " /> */}
      </div>
      {!isLoading && (
        <div className="form-group">
          <Button
            name="postcodevalid"
            className="button-submit anim_ylw lookup"
            value="Lookup Address1"
            type="button"
            buttonText="Lookup Address"
            onClick={lookupValidation}
            style={{ height: "43px" }}
          />
          {/* <button type="button" className="button-submit anim_ylw lookup">Lookup Address</button> */}
        </div>
      )}

      {!!isLoading && (
        <div className="form-group">
          <Button
            name="loading-btn"
            className="button-submit anim_ylw lookup"
            type="button"
            buttonText={
              <img src="assets/images/buttonLoader.gif" alt="loading..." />
            }
            style={{ padding: "1px 15px", height: "43px" }}
            disabled={true}
          />
        </div>
      )}

      {validationMsg.txtSearchKey && (
        <span className="error_msg mb-0 " id="email_err">
          {validationMsg.txtSearchKey.message}
        </span>
      )}

      <div
        className=""
        id="currentAddressCollapse"
        style={state.checkSelect ? { display: "block" } : { display: "none" }}
      >
        <div className="form-group">
          <SelectBox
            className="form-control animated-effect watermark anim_ylw border-style"
            OptionValue={state.getAddress}
            name="txtAddress"
            onChange={getValue}
            myRef={validation({ required: "Please select address" })}
            validationMsg={
              validationMsg.txtAddress && validationMsg.txtAddress.message
            }
          />
        </div>
      </div>
      <div className={`form-group ${state.showManualAddress}`}>
        {/* <div style={{display: state.getDetails.address_line_1 ? "block" : "none"}} className=" form-group"  id="strdivid"> */}
        <div className=" form-group" id="strdivid">
          <TextField
            type="text"
            placeholder="Address Line 1 *"
            readonly="readonly"
            name="txtAddressLine1"
            className="form-control border-style"
            dataId="txtAddressLine1"
            autoComplete="off"
            validation={validation({
              required: "Please Enter Address Line 1",
            })}
            validationMsg={
              validationMsg.txtAddressLine1 &&
              validationMsg.txtAddressLine1.message
            }
            value={state.getDetails.address_line_1}
          />
        </div>
        {/* txtAddress2 */}
        {/* <div style={{display: state.getDetails.address_line_2 ? "block" : "none"}} className=" form-group" id="housedivid" > */}
        <div className=" form-group" id="housedivid">
          <TextField
            type="text"
            placeholder="Address Line 2"
            readonly="readonly"
            name="txtAddressLine2"
            className="form-control border-style"
            dataId="txtAddressLine2"
            autoComplete="off"
            validation={validation()}
            validationMsg={
              validationMsg.txtAddressLine2 &&
              validationMsg.txtAddressLine2.message
            }
            value={state.getDetails.address_line_2}
          />
        </div>
        {/* txt Town */}
        {/* <div style={{display: state.getDetails.suburb ? "block" : "none"}} className=" form-group" 
        id="housedivid" > */}
        <div className=" form-group" id="housedivid">
          <TextField
            type="text"
            //placeholder="Address Line 3"
            placeholder="Suburb"
            readonly="readonly"
            name="txtSuburb"
            className="form-control border-style"
            dataId="txtSuburb"
            autoComplete="off"
            //keypress={e => (/[^A-Za-z ]+$/.test(e.key)) && e.preventDefault()}
            validation={validation()}
            validationMsg={
              validationMsg.txtSuburb && validationMsg.txtSuburb.message
            }
            value={state.getDetails.suburb}
          />
        </div>

        <div className="form-group">
          <TextField
            type="text"
            placeholder="State *"
            name="txtState"
            className="form-control  hero-input"
            dataId="txtState"
            autoComplete="off"
            validation={validation({ required: "Please Enter State" })}
            validationMsg={
              validationMsg.txtState && validationMsg.txtState.message
            }
            value={state.getDetails.state}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className="form-group">
          <TextField
            type="text"
            placeholder="Pincode *"
            name="txtPostCode"
            className="form-control  hero-input"
            dataId="txtPostCode"
            autoComplete="off"
            validation={validation({ required: "Please Enter Address" })}
            validationMsg={
              validationMsg.txtPostCode && validationMsg.txtPostCode.message
            }
            value={state.getDetails.postcode}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
      </div>

      {/* hidden fields */}
      {/* {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null} */}
      <div className="clearfix"></div>
    </>
  );
};
export default PostCodeFind;
