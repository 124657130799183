import React from "react";
import ProgressBar from "../BMC_V2​/ProgressBar";
import {Salutation} from '../../../../Constants/Constants';
import SelectBox from '../../../UI/SelectBox';
import TextField from '../../../UI/TextField';
import Button from "../../../UI/Button";

const BasicDetails = ({className, slideChange,setError,validation,validationMsg,trigger, getValues, clearErrors}) => {
    
      const basic_onchangehandle = async (e) => {
        trigger(e.target.name);
      }
    const basicDetailsValidation = async (e) => {
        const values = getValues();  
        const txtTitle = values["lstSalutation"];
        const txtFName = values["txtFName"];
        const txtLName = values["txtLName"]; 
        let errorFlag = 0;
        let lstSalutationResult = await trigger("lstSalutation");
        let txtFNameResult;
        var txtLNameResult;  
        

        if (lstSalutationResult) {
          txtFNameResult = await trigger("txtFName");
        } else {
          window.scrollTo(0, 0);
          return false;
        }
        if (txtFNameResult) {
          if (
            txtFName.trim() == "" ||
            txtFName.trim().length == 0 ||
            txtFName.trim().length < 3
          ) {
            setError("txtFName", {
              type: "manual",
              message: "Your Name is Invalid. Please Recheck",
            });
            window.scrollTo(0, 0);
            return false;
          } else {
            txtLNameResult = await trigger("txtLName");
          }
        } else {
          window.scrollTo(0, 0);
          return false;
        }
        if (txtLNameResult) {
          if (
            txtLName.trim() == "" ||
            txtLName.trim().length == 0 ||
            txtLName.trim().length < 3
          ) {
            setError("txtLName", {
              type: "manual",
              message: "Your Name is Invalid. Please Recheck",
            });
           window.scrollTo(0, 0)
            return false;
          }
        }
          if (
          !lstSalutationResult ||
          !txtFNameResult ||
          !txtLNameResult 
        ) {
          errorFlag = 1;
        }
        if (errorFlag === 0) {
          slideChange(e);
        } else {
          return false;
        }
        


        /////

        // let errorFlag = 0;
        // let lstTitleResult = await trigger("lstSalutation");
        // let txtFNameResult;
        // var txtLNameResult;
        // if (lstTitleResult) {
        //   txtFNameResult = await trigger("txtFName");
        // }
        // else {
        //   return false;
        // }
        // if (txtFNameResult) {
        //   txtLNameResult = await trigger("txtLName");
        // }
        // else {
        //   return false;
        // }
    
        // if (
        //   !lstTitleResult ||
        //   !txtFNameResult ||
        //   !txtLNameResult 
        // ) {
        //   errorFlag = 1;
        // }
        // if (errorFlag === 0) {
        //   slideChange(e);
        // } else {
        //   return false;
        // }
      };



 return (
        <div id="slide2" className={className}>
          <ProgressBar value="20"/>
            <div className="mb-3">
                <h4>Enter Your Name</h4>
                  <SelectBox className="form-select"
                    OptionValue={Salutation}
                    name="lstSalutation"
                    myRef={validation({ required: "Please Select Salutation" })}
                    validationMsg={
                    validationMsg.lstSalutation &&
                    validationMsg.lstSalutation.message
                    }
                    onChange={basic_onchangehandle}
                    ></SelectBox>
            </div>
            <div className="mb-3">
                <TextField type="text" className="form-control" placeholder="First Name" name="txtFName" id="txtFName"
                    validation={validation({
                    required: "Please Enter First Name",
                 
                    minLength: {
                        value: 3,
                        message: "Please Enter Valid First Name",
                    },
                    pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                    },
                    })}
                    validationMsg={
                        validationMsg.txtFName && validationMsg.txtFName.message
                    }
                    // onBlur={namevalidate}
                    onChange={basic_onchangehandle}
                />
            </div>
            <div className="mb-3">
                <TextField type="text" className="form-control" placeholder="Middle Name" name="txtMName" id="txtMName"
                    validation={validation({
                    //required: "Please Enter First Name",
                 
                    minLength: {
                        value: 3,
                        message: "Please Enter Valid Middle Name",
                    },
                    pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                    },
                    })}
                    validationMsg={
                        validationMsg.txtMName && validationMsg.txtMName.message
                    }
                    // onBlur={namevalidate}
                    onChange={basic_onchangehandle}
                />
            </div>
            <div className="mb-3">
              <TextField type="text" className="form-control" placeholder="Last Name" name="txtLName" id="txtLName"
               
                  validation={validation({
                  required: "Please Enter Surname",
                  minLength: {
                      value: 3,
                      message: "Please Enter Valid Surname",
                  },
                  pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                  },
                  })}
                  validationMsg={
                      validationMsg.txtLName && validationMsg.txtLName.message
                  }
                  // onBlur={namevalidate}
                  onChange={basic_onchangehandle}
              />
            </div>
            <div className="mb-3">
                <Button name="slide2" type="button" className="btn-next" id="next02" buttonText="Next"  onClick={basicDetailsValidation}/>
            </div>
        </div>
    );
}
export default BasicDetails;
