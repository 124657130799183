import { useState } from "react";
import { LoansList } from "../Constants/LoansList";

export const useLoanList = ()=>{
    // const [isLoanListLoading, setIsLoanListLoading] = useState(false);
    const loanList = async()=>{
      return LoansList;
    }
    
    return {loanList}

} 