
import AnchorAdv from "../../UI/AnchorAdv";
import FbViewPixel from "../../../Utility/FbViewPixel";

const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <header className="border-bottom fs-6">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center d-none d-sm-block d-md-block d-lg-block">
              <p className="m-0 text-secondary">Advertorial</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6 logo">
              <AnchorAdv dataId="SITE_lOGO"><img src="assets/ADV_BC_CCI_V1/img/logo.png" alt="" /></AnchorAdv>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-6 d-none d-sm-block d-md-block d-lg-block text-end">
              <AnchorAdv dataId="TOP-BTN" className="btn claimbtn text-white">START YOUR CLAIM</AnchorAdv>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-center d-block d-sm-none d-md-none d-lg-none">
              <p className="my-2 fw-bolder text-secondary">Advertorial</p>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
export default AdvHeader;