import React from 'react';
import { DobYear, DobMonth, DobDay } from "../Constants/Constants";
import SelectBox from '../Components/UI/SelectBox';

const DateOfBirth = ({ validation, validationMsg, reff, validateAge }) => {

  return (
    <>
      <fieldset className="border p-3 pb-1">
        <legend className="float-none w-auto px-3"> Date Of Birth</legend>
        <div className="row" ref={(el) => (reff.current['DateOfBirth'] = el)}>
          <div className=" form-group col-lg-4 col-12">
            <SelectBox
              className="form-control dob"
              OptionValue={DobDay}
              name="lstDobDay"
              onChange={validateAge}
              myRef={validation({
                required: "Please Select Date"
              })}
              validationMsg={validationMsg.lstDobDay && validationMsg.lstDobDay.message}
            />
          </div>
          <div className=" form-group col-lg-4 col-12">
            <SelectBox
              className="form-control dob"
              OptionValue={DobMonth}
              name="lstDobMonth"
              onChange={validateAge}
              myRef={validation({
                required: "Please Select Month"
              })}
              validationMsg={validationMsg.lstDobMonth && validationMsg.lstDobMonth.message}
            />
          </div>
          <div className=" form-group col-lg-4 col-12">
            <SelectBox
              className="form-control dob"
              OptionValue={DobYear}
              name="lstDobYear"
              onChange={validateAge}
              myRef={validation({
                required: "Please Select Year"
              })}
              validationMsg={validationMsg.lstDobYear && validationMsg.lstDobYear.message}
            />
          </div>
        </div>
      </fieldset>
    </>
  )
}

export default DateOfBirth;