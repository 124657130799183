import React, { useState } from 'react';
import AdvHeader from "../../Includes/Advertorials/AdvHeader";
import AdvFooter from "../../Includes/Advertorials/AdvFooter";
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import "../../../assets/ADV_BC_CCI_V1/style.scss";

const ADV_BC_CCI_V1 = () => {
  const [redirectUrl, setRedirectUrl] = useState({ redirectUrl: '' });

  return (
    <div className="ADV-CLAIMS">
      <AdtopiaLoadAP pageType="AP" splitName="ADV-SR-CCI_V1" />
      {/* <PageLoadApi pageType="AP" splitName="ADV-SR-CCI_V1" /> */}
      <AdvHeader />

      <section className="content_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="py-2 mb-0">It is reported that Australian banks have set aside over $9 billion to refund
                their customers. Find out what you may be owed.</h1>
              <h2 className="pt-1 pb-2 border-bottom text-start">You could be entitled to £1000s in compensation!</h2>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-12">
              <AnchorAdv dataId="SITE_MAINBANER">
                <img src="assets/ADV_BC_CCI_V1/img/mainbanner.jpg" className="w-100" alt="" /></AnchorAdv>
              <div className="liness border-top border-bottom my-2">
                <div className="yellows my-2 py-2">
                  <p className="mb-0 ps-2">
                    <AnchorAdv dataId="PARA_TEXT_LINKS_1">
                      <b>
                        <u className='new_class'>Over the past 7 years, the big banks and major
                          insurance companies have sold over 5.6 million policies for so-called “Junk Insurance” policies
                          on credit cards and loans. That’s more than 1 policy for every 4 Australian adults.
                        </u>
                      </b>
                    </AnchorAdv>
                  </p>
                </div>
              </div>
              <h4>Were You A Victim of The Junk Insurance Scandal?</h4>
              <p>
                For decades, banks around the world made enormous profits by charging their customers for junk insurance that
                didn’t deliver what was promised*.
              </p>
              <p>
                <AnchorAdv dataId="PARA_TEXT_LINKS_2">
                  <b>
                    <u className='new_class'>
                      While the banks charged you premiums for this junk insurance
                    </u>
                  </b>
                </AnchorAdv>
                (and then charged interest on the premiums and often didn’t even disclose the fact), they rarely
                delivered any actual Insurance! A lot of the time, the insurance just plain wasn’t needed, or was not
                appropriate to what was insured. In other areas, the level of payouts was ridiculously low, generating
                fantastic profits for the banks, at their own customers expense.
              </p>
              <p>
                The banks got away with it for a long time. In Australia it took the Royal Commission into the banking
                industry to really highlight the wrongdoings and the widespread selling of junk insurance.{' '}
                <b>
                  The banks were ordered to remediate the millions of customers they had ripped off.
                </b>
              </p>
              <div className="cta-section">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                    <div className="bannersec">
                      <h3 className="mt-2 text-center">HOW TO GET STARTED</h3>
                      <h6 className="w-100 text-white p-3 mb-0">Select Your Lender to Start Your Claim</h6>
                      <div className="age_brdr text-white pb-4">
                        <div className="triangle_shape mx-auto"></div>
                        <AnchorAdv dataId="MID_PARA_NUM" className="btn goldbtn text-white rounded-pill">
                          ANZ
                        </AnchorAdv>
                        <AnchorAdv dataId="MID_PARA_NUM" className="btn goldbtn text-white rounded-pill">
                          Latitude Financial Services
                        </AnchorAdv>
                        <AnchorAdv dataId="MID_PARA_NUM" className="btn goldbtn text-white rounded-pill">
                          GEM Visa
                        </AnchorAdv>
                        <AnchorAdv dataId="MID_PARA_NUM" className="btn goldbtn text-white rounded-pill">
                          Go Mastercard
                        </AnchorAdv>
                        <AnchorAdv dataId="MID_PARA_NUM" className="btn goldbtn text-white rounded-pill">
                          Coles Mastercard
                        </AnchorAdv>
                        <AnchorAdv dataId="MID_PARA_NUM" className="btn goldbtn text-white rounded-pill">
                          GE Money
                        </AnchorAdv>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="mt-3">This Could Mean Hundreds of Dollars in Your Pocket!</h4>
              <p>
                It's suspected that more than 5 million people are eligible for a refund in Australia.
              </p>
              <p>
                If you've had a credit card, a mortgage, car insurance or other warranty products in the last 20 years
                there is a good chance you've been sold junk insurance and are eligible for remediation from your bank or insurer.
              </p>
              <p>
                The extent of the mis-selling of junk insurance is so far largely unaccounted for and unclaimed by millions
                of Australians.
              </p>
              <p>
                If you're unsure if you're eligible for compensation, it's worth check checking!
              </p>
              <h4 className="fw-bold">How Do I Submit A Claim?</h4>
              <p>
                <b>Step 1:</b>
                Select your location on the map below
              </p>
              <p>
                <b>Step 2:</b>
                Answer a few questions to start your claim!
              </p>

              <div className="col-lg-12 col-xs-12 banner11 text-center">
                <h3 className="mt-2 text-center"> HOW TO GET STARTED </h3>
                <p className="text-center">
                  <b>Click Your Location on the Map Below to Get Started</b>
                </p>
                <AnchorAdv dataId="MAIN_CTA_MAP"> <img src="assets/ADV_BC_CCI_V1/img/map.jpg" alt="" className="mt-2 mb-5" />
                </AnchorAdv>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 d-lg-block d-md-none d-sm-none d-none text-center">
              <div className="fixedsticky mb-3">
                <AnchorAdv dataId="RIGHT_SITE_BANNER"><img src="assets/ADV_BC_CCI_V1/img/side_bnr1.png" alt="" className="w-100" />
                </AnchorAdv>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AdvFooter />
    </div>
  )
}
export default ADV_BC_CCI_V1;