import { Api } from "../api/Api";

export const userLender = () => {
  const saveUserLender = async (
    uuid,
    data,
    message_type,
    visitor_parameters
  ) => {
    const response = await Api.post("v1/save-user-lender", {
      uuid,
      data,
      message_type,
      visitor_parameters
    });
    return response;
  };
  return { saveUserLender };
};
