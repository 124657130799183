import React, { useEffect, useState } from "react";
import { useLoanList } from "../../../../Hooks/UseLoanList";

const Loans = ({setLoanSelectedList,reff,setLoanValidationMsg,setIsLoans})=>{
    
    const {loanList}  = useLoanList();
    const [loansloop , setLoansloop] = useState({});

    const [loanSelectedValue] = useState([]);
    const onSelectLoan  = (e)=>{
       if(e.target.checked === true) {
            setIsLoans(true);
            loanSelectedValue.push({id: +e.target.value})
            setLoanValidationMsg(false);
        } else {
        loanSelectedValue.pop({id: +e.target.value})
            if(loanSelectedValue.length <= 0){
                setIsLoans(false);
                setLoanValidationMsg("Please select Loan Type");
            }
        }
        setLoanSelectedList(loanSelectedValue); 
    };



    let loanOption ;
    useEffect (()=>{
       (async ()=>{
           const loans = await loanList();
           setLoansloop({loans:loans.data.response.loans});
       })();
    },[]);


    if(loansloop.loans){
        loanOption = loansloop.loans.map((loanData, index) => {
            
            return (
                <div className="form-check" key={index}>
                    <input type="checkbox" className="form-check-input custom-checkbox" onChange={onSelectLoan} name="chkLoanName[]" id={`loans${index}`}  value={loanData.id}/>
                    <label htmlFor={`loans${index}`}  className="form-check-label">{loanData.loan_name}</label>
                </div>
            );
        });
        }

    return(
        <>
            <fieldset className="scheduler-border" ref={(el) => (reff.current['loansec'] = el)}>
                <legend className="float-none w-auto px-3">Which type of loan products have you taken out in the last 15 years?</legend>
                <div className="col-lg-12">
                    { loanOption}
                </div>
            </fieldset>
        </>
    );
}

export default Loans;