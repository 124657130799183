import React from 'react';

const Thankyouheader =()=>{

return(
   <>

 <header className="thankyoupage">

 <div className="container">
                  <div className="row">
                     <div className="col-xl-12 col-12 col-md-12 col-lg-12 text-center banner-part">
                        <div className="logo">
                           <img src="assets/ThankyouV2/img/logo.png" alt="" />
                        </div>
                        
                     </div>


               
              
                     


                  </div>
               </div>
</header>
  </>
);
};

export default Thankyouheader;