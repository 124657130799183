import React from 'react';


const Privacyfooter = ({
  link1,
  link2,
  text1,
  text2
}) => {
  return (
    <>
     <footer>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h3>© Boomerang Claims Pty Limited  </h3>
                <p>ABN 71 623 924 087
                </p>
                <p>contact@boomerangclaims.com</p>
                <p>Boomerang Claims Limited, Level 2 41A Bay Road, Taren Point, New South Wales, NSW 2229</p>
                <p>Boomerang Claims are proud members of the Australian Financial Complaints Authority. 
                  Our membership number is 89098
                </p>
                <p>When deciding how to proceed with your Junk Insurance claim, remember you always have the right to contact AFCA for free and impartial advice and support with your claim. 
                  Call AFCA free on 1800 931 678
                </p>
                <ul>
                  <li style={{display: link1 ? "block" : "none"}}><a href={link1} target="_blank"> {text1}</a></li>
                  <li style={{display: link2 ? "block" : "none"}}><a href={link2} target="_blank"> {text2}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
    </>
  );
};

export default Privacyfooter;