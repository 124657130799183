import React from 'react';
import "../../../../assets/BMC_V2/css/BMC_V2.scss";
import "../../../../assets/BMC_V2/css/animate.css";
import Privacyheader from "../BMC_V2​/Privacyheader";
import Privacyfooter from "../BMC_V2​/Privacyfooter";


const PrivacyV2 = () => {

  return (
    <>
      <body>
    
       <Privacyheader/>
        <section class="Privacy">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-12 text-start">
                        <h1>Privacy Policy</h1>
                        <p>When you use our services, you’re trusting us with your information. We work hard to protect your information. </p>
                        <p>This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.</p>
                        <p>In this Privacy Policy, ‘we’, ‘us’ or ‘our’ means <b>Boomerang Claims Ltd</b> We are registered in Australia, Registered Company Number ABN 71 623 924 087 and ACN 623 924 087</p>
                        <p>Our registered office is Level 2 41A Bay Road, Taren Point, New South Wales, NSW 2229</p>
                        <p>This privacy policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including through our website and is in line with the Privacy Act 1988 (Cth) (“the Privacy Act”).  Our Privacy Policy seeks to safeguard the privacy of your personal information in accordance with the Australian Privacy Principles (“the APP’s”) contained within the Privacy Act.</p>
                        <p>Please read this Privacy Policy carefully. By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of this Site or the services offered on or through it.</p>
                        <h4>CONSEQUENCES OF NOT PROVIDING YOUR PERSONAL INFORMATION</h4>
                        <p>We use your information to deliver our services. You are not obliged to give us your personal information. However, if you decide not to give us information needed in order to provide you with services, we may not be able to provide those services to you. </p>
                        <h4>1.      Your privacy is important to us</h4>
                        <p>We understand how important your privacy us and we also value your trust.  That’s why we do everything we can to protect your personal information and we always try to be transparent and clear on what we do with your data.  This policy explains just that.</p>
                        <h4>2.      What information we collect</h4>
                        <p>When you contact us, visit our website or use our services, we need to collect personal information about you.  This may include:</p>
                        <ul>
                            <li><b>Information about your identity –</b> including your name, contact details (including telephone number, address and email address), date of birth and gender</li>
                            <li><b>Information about your financial products and types - </b>including your lender details and account details.</li>
                            <li><b>Information about your location - </b>When you visit our website we may capture your location information, IP address, mobile device and network information and any third-party sites you access</li>
                            <li><b>Additional Information - </b>in order to complete your claim we may as you to provide us with any other personal information requested by us and/or provided by you or a third party</li>
                        </ul>
                        <p>We may collect these types of personal information directly from you or from third parties.</p>
                        <h4>3.      How do we use your information?</h4>
                        <p>We collect, hold, use and disclose your personal information so we can:</p>
                        <ul>
                            <li>Confirm your identity</li>
                            <li>Assess your application and your claim</li>
                            <li>Manage your claim</li>
                            <li>Contact and communicate with you</li>
                            <li>Improve our service to you and your experience with us</li>
                            <li>Comply with laws, and assist government or law enforcement agencies</li>
                        </ul>
                        <p>We may also collect, hold, use and disclose your information for other reasons where the law allows or requires us.  From time to time, we may also use your personal information to tell you about products or services we think you might be interested in. To do this, we may contact you by:</p>
                        <ul>
                            <li>
                                Email
                            </li>
                            <li>Phone</li>
                            <li>SMS</li>
                            <li>Social Media</li>
                            <li>Advertising through our website or third-party websites</li>
                            <li>Mail</li>
                        </ul>
                        <p class="text-center"><b>If you no longer wish to receive direct marketing messages from us, or want to stop receiving updates on the status of your case, or if want to change your contact preference, then please contact us using our contact form, or by email at <a href="contact@boomerangclaims.com">contact@boomerangclaims.com,</a> or you can write to us at our registered office. </b></p>
                        <h4>4.      Your information and third parties</h4>
                        <p>We may share your information with third parties for the reasons in section 3 or where the law otherwise allows. These third parties can include:</p>
                        <ul>
                            <li>Our employees, contractors and/or related entities</li>
                            <li>Banks / Lenders and or Financial Institutions personal information / customer care departments</li>
                            <li>third parties to collect and process data, such as Google Analytics or other relevant businesses. This may include parties that store data outside of Australia</li>
                        </ul>
                        <h4>5.      Keeping your information safe</h4>
                        <p>We store your hard copy and electronic records in secure buildings and systems, or using trusted third parties.</p>
                        <p>Boomerang uses the Microsoft Office 365 operating system. Portions of your data may be stored through these systems. Microsoft have confirmed that data is stored in part in Australia and also the United States of America. </p>
                        <p>We have attached a link to the Microsoft Privacy Policy for your information: <a href="https://privacy.microsoft.com/enca/privacystatement" target="_blank"> https://privacy.microsoft.com/ enca/privacystatement.  </a></p>
                        <h4>6.     Cookies and web beacons</h4>
                        <p>We may use cookies on our Site from time to time. Cookies are text files placed in your computer’s browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our Site with personal information, this information may be linked to the data stored in the cookie.</p>
                        <p>We may use web beacons on our Site from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.</p>
                        <h4>7.      Accessing, updating and correcting your information</h4>
                        <p>You can contact us and ask to view your information at any time. If your information isn’t correct or needs updating, please let us know straight away.</p>
                        <p>You can ask us for a copy of your information by asking us using our contact form, emailing us at <a href="#">contact@boomerangclaims.com</a> or writing to us at our registered address. There is no fee to ask for your information.</p>
                        <p>We try our best to make your information available within 30 days after you ask us for it. Before we give you the information, we’ll need to confirm your identity.</p>
                        <h4>8.      Making a privacy complaint</h4>
                        <p>If you are concerned about your privacy, you can make a complaint and we’ll do our best to sort it out.  To make a complaint you can use our contact form or email us directly at <a href="#">contact@boomerangclaims.com.</a> We’ll look into the issue and do our best for you to fix it straight away.</p>
                        <h4>9.      What else can you do?</h4>
                        <p>If your complaint is about how we handle your personal information, you can contact the Office of the Australian Information Commissioner (<a href="https://www.oaic.gov.au/" target="_blank"> OAIC</a> ) or Australian Financial Complaints Authority (<a href="https://www.afca.org.au/" target="_blank"> AFCA</a> ). </p>
                        <p>You can call AFCA for free on: <a href="#"> 1800 931 678 </a></p>
                        <p>You can call OAIC on <a href="#"> 1300 363 992,</a> or you can write to them at this address:</p>
                        <p>Office of the Australian Information Commissioner</p>
                        <p>GPO Box 5218, Sydney NSW 2001</p>
                        <h4>10.   Amendments</h4>
                        <p>We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our Site. We recommend you check our Site regularly to ensure you are aware of our current Privacy Policy.</p>
                        <h4>11.  POLICY REVIEW </h4>
                        <p>This policy will be reviewed at least annually. </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="privacy_end">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-12 text-center">
                        <h4>For any questions or notices, please contact our Privacy Officer by using our contact form or in writing at:</h4>
                        <p>Boomerang Claims Ltd 
                            Level 2 41A Bay Road,
                            Taren Point,
                            New South Wales,
                            NSW 2229</p>
                    </div>
                </div>
            </div>
        </section>
        <div className="BMC_V2">
        <Privacyfooter
        link2={"/TermsV2"}
        text2="Terms of Use"
        />
        </div>
      </body>
    </>
  );
}

export default PrivacyV2;