import React from 'react';
import TextField from '../../../UI/TextField';
// import PostCode from '../../../../Utility/PostCode';
import SelectBox from '../../../UI/SelectBox';
import  DateOfBirth  from '../../../../Utility/DateOfBirth';
import { Salutation } from '../../../../Constants/Constants';
import PostCode from '../../../../Utility/PostCodeFind';

const BasicDetails = ({
	setError,
	validation,
	validationMsg,
	trigger,
	reff,
	getValues, 
	clearErrors,
	basic_onchangehandle,
	validateAge,
})=>{

	const namevalidate = async (e) => {
		if (e.target.value.trim() == "" || e.target.value.trim().length == 0)
			setError(e.target.name, {
				type: "manual",
				message: "Your Name is Invalid. Please Recheck",
			});
		return false;
	};

	return(
		<>
			{/* title */}
			<div className=" form-group" ref={(el) => (reff.current['lstSalutation'] = el)}>
				<SelectBox
					className="form-control"
					OptionValue={Salutation}
					name="lstSalutation"
					myRef={validation({ required: "Please Select Salutation" })}
					validationMsg={
					validationMsg.lstSalutation &&
					validationMsg.lstSalutation.message
					}
					onBlur ={basic_onchangehandle}
					onChange ={basic_onchangehandle}
				>
				</SelectBox>
			</div>
			{/* First Name */}
			<div className=" form-group" ref={(el) => (reff.current['txtFName'] = el)}>
				<TextField
					type="text"
					className="form-control"
					placeholder="First Name"
					name="txtFName"
					id  ="txtFName"
					validation={validation({
					required: "Please Enter First Name",
					minLength: {
							value: 3,
							message: "Please Enter Valid First Name",
					},
					pattern: {
							value: /^[a-zA-Z\s]+$/,
							message: "Your Name is Invalid. Please Recheck",
					},
					})}
					validationMsg={
							validationMsg.txtFName && validationMsg.txtFName.message
					}
					onChange ={basic_onchangehandle}
					onBlur={namevalidate}
				/>
			</div>

			<div className=" form-group" ref={(el) => (reff.current['txtMName'] = el)}>
				<TextField
					type="text"
					className="form-control"
					placeholder="Middle Name"
					name="txtMName"
					id  ="txtMName"
					validation={validation({
					//required: "Please Enter Middle Name",
					minLength: {
							value: 3,
							message: "Please Enter Valid Middle Name",
					},
					pattern: {
							value: /^[a-zA-Z\s]+$/,
							message: "Your Name is Invalid. Please Recheck",
					},
					})}
					validationMsg={
							validationMsg.txtMName && validationMsg.txtMName.message
					}
					
				/>
			</div>

			{/* text SurName */}
			<div className=" form-group" ref={(el) => (reff.current['txtLName'] = el)}>
				<TextField
					type="text"
					className="form-control"
					placeholder="Surname"
					name="txtLName"
					id  ="lname"
					validation={validation({
					required: "Please Enter Surname",
					minLength: {
							value: 3,
							message: "Please Enter Valid Surname",
					},
					pattern: {
							value: /^[a-zA-Z\s]+$/,
							message: "Your Name is Invalid. Please Recheck",
					},
					})}
					validationMsg={
							validationMsg.txtLName && validationMsg.txtLName.message
					}
					onChange ={basic_onchangehandle}
					onBlur={namevalidate}
				/>
				{/* <input autocomplete="off"  placeholder="Surname" name="txtFName" id="txtFName" value="" type="text" className="form-control "/> */}
			</div>

			{/* post code */}
			<PostCode
				className={`form-control`}
				validation={validation}
				validationMsg={validationMsg}
				trigger={trigger}
				reff={reff}
				clearErrors={clearErrors}
				setError={setError}
				textContent="Postcode"
				getValues={getValues}
			/>  
			{/* DOB */}

			<DateOfBirth
				textDob="Your Date of Birth *"
				validation={validation}
				reff={reff}
				validationMsg={validationMsg}
				validateAge={validateAge}
			/>
		</>
  );
}
export default BasicDetails;