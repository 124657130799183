import React from "react";

const Button = ({
  type,
  name,
  className,
  id,
  onClick,
  buttonText,
  disabled,
  value,
  style = {},
}) => {
  return (
    <button
      type={type}
      className={className}
      id={id}
      onClick={onClick}
      disabled={disabled}
      value={value}
      name={name}
      style={style}
    >
      {buttonText}
    </button>
  );
};
export default Button;
