export const LoansList    =   {
    data:{
      response:{
        loans: [
            {
                "id": 1,
                "loan_name": "Home Loans",
                "rank": null
            },
            {
                "id": 2,
                "loan_name": "Personal Loans",
                "rank": null
            },
            {
                "id": 3,
                "loan_name": "Credit Cards",
                "rank": null
            },
            {
                "id": 4,
                "loan_name": "Car Loans",
                "rank": null
            },
        ]
    }
  }  
}
