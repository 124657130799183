import React from 'react';
import "../../../../assets/ThankyouV2/css/thankyoupage.scss";
import Thankyouheader from "../../Layouts/BMC_V2​/Thankyouheader";
import ThankyouFooter  from "../BMC_V2​/ThankyouFooter";
const ThankyouV2 =()=>{
  localStorage.clear();
return(
    <>
  <div className="Thankyou_V2">
         <Thankyouheader/>
   <section>

    <div className="container-fluid thankyou" > 
        <div className="container">
        <h1>Thank You!</h1>
        <img src="assets/ThankyouV2/img/thankyou.gif" />
        <h2>Your application has been submitted successfully</h2>
        <h3>One of our agents will be in touch with you soon</h3>
            
        </div>
    </div>
  </section>
  </div>
  <div className="BMC_V2">
      <ThankyouFooter/>
  </div>
  </>
    );
};
export default ThankyouV2;