import { AppAdtopiaUPID, AppEnv } from "../Constants/EnvConstants";
import { AddressApi } from "../api/AddressApi";
import {CommonEmailValidateApi} from "../api/Api";

export const useEmailValidation = () => {
  const emailValidation = async (email) => {
    const getValidation = await CommonEmailValidateApi.post(
      "v1/validate?ProjectCode=" +
        AppAdtopiaUPID +
        "&Environment=" +
        AppEnv +
        "&Email=" +
        email,
      {}
    );
    return getValidation;
  };
  return { emailValidation };
};