import React from "react";
import Header from "../../Includes/Layouts/MCS_V1/Header";
import Footer from "../../Includes/Layouts/MCS_V1/Footer";
import "../../../assets/MCS_V1/css/MCS_V1.scss";
import "../../../assets/MCS_V1/css/animate.css";
import FormMCS_V1 from "../../Forms/FormMCS_V1";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";

const MCS_V1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MCS_V1" />
      <div className="medicare_landingpage_02" id="medicare_landingpage_02">
        <Header />
        <section className="heding_sec" id="hding">
          <div className="container">
            <div className="col-lg-6 offset-lg-3 col-12 text-center">
              <h1>2022: Find the Best Medicare Plan for You</h1>
            </div>
          </div>
        </section>
        <section className="form-part" id="firstform">
          <div className="container nopad">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
              <FormMCS_V1 />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default MCS_V1;
