import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import { BrowserView, MobileView } from "react-device-detect";
import "../../assets/Signature/css/Signature.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../UI/InputButton";
import { useSignature } from "../../Hooks/useSignature";
import { signCheckText } from "../../Constants/Constants";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const Signature = () => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = 'signature_store';
  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type
        )
        localStorage.clear();
        if (response.data.status === "Success") {
          history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <GetVisitorsParams />
      <div className="signaturePage">
        <section className="signature-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="signature-part">
                  <div className="col-12 text-center">
                    <img
                      src="/assets/Signature/img/logo.png"
                      className="sign-logo"
                      alt=""
                    />
                    <h1>Almost Done...</h1>
                  </div>
                  <div className="modal-content sign-popstyle">
                    <div className="modal-header">
                      <h2 className="h2_style">
                        Finally, we need your electronic signature below to
                        proceed. Please note that your electronic signature will
                        be included on all forms we submit to your lender
											</h2>
                    </div>
                    <div className="ensure_li">
                      <div className="offset-lg-3 col-lg-6 col-12 sign-question nopadmob">
                        <ul className="signature-ul">
                          <li> Sign using your finger, mouse or stylus</li>
                          <li> Keep it fully contained within the box</li>
                          <li> Ensure it is a true likeness of your signature</li>
                        </ul>
                      </div>
                      <div className="signature_canvaseWrap">
                        <div className="">
                          <BrowserView>
                            <SignatureCanvas
                              canvasProps={{
                                height: 300,
                                className: "signature-pad anim_bt d-lg-block",
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                          </BrowserView>
                          <MobileView>
                            <SignatureCanvas
                              canvasProps={{
                                width: 300,
                                height: 300,
                                className: "signature-pad anim_bt d-lg-block",
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                          </MobileView>
                          {state.isCanvasEmpty && (
                            <p id="signatures_required" className="sign_msg">
                              Signature is required!
                            </p>
                          )}
                          {state.isValid && (
                            <p id="signatures_valid" className="sign_msg">
                              Draw valid signature!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-left bord" id="acceptterms_outer">
                      <div className="custom-control custom-checkbox">
                        <CheckBox
                          dataId="acceptterms"
                          anchorText="Terms of Business"
                          anchorStyle=""
                          href=""
                          onChange={(e) => setIsChecked(!isChecked)}
                          className="custom-control-input"
                          labelClassName="custom-control-label"
                          value={isChecked}
                          CheckBoxText={signCheckText}
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <span className="text-danger" id="checkbox_required"></span>
                    </div>
                  </div>
                  {state.isAgreed && (
                    <span className="error_msg">
                      Please Agree to the Terms of Business
                    </span>
                  )}
                  <div className="modal-footer" style={{ display: "block" }}>
                    <InputButton
                      name="clear"
                      className="btn-clear"
                      id="clear"
                      value="Clear"
                      btnType="button"
                      onClick={clearSign}
                    />
                    <InputButton
                      name="save"
                      className="btn-submit"
                      id="save"
                      value="Submit"
                      btnType="button"
                      onClick={handleClick}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Signature;
