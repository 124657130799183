import { useState } from "react";
import { Api } from "../api/Api";
//import {Api,ApiFile} from "../api/Api";

export const useUserDocument = () => {
  const [isLoading, setIsLoading] = useState(false);

  const saveUserDocument = async (
    user_document_data,
    visitor_parameters,
    formParameters,
    query_string,
    message_type = "user_docs_store"
  ) => {
    setIsLoading(true);
    const userDocumentSubmit = await Api.post("v1/user_documents", {
      message_type,
      user_document_data,
      visitor_parameters: visitor_parameters.visitor_parameters,
      form_data: formParameters,
      data: visitor_parameters.data,
      query_string: query_string,
    });

    setIsLoading(false);
    return userDocumentSubmit;
  };

  // const saveUserDocument = async (data) => {
  //     setIsLoading(true);
  //     const userDocumentSubmit = await ApiFile.post("api/v1/user_documents",data);

  //     setIsLoading(false);
  //     return userDocumentSubmit;
  // }

  return {
    isLoading,
    saveUserDocument,
  };
};

export const useSkipUserDocument = () => {
  const [isSkipLoading, setIsSkipLoading] = useState(false);
  const uuid = localStorage.getItem("uuid");

  const skipUserDocument = async (
    skiped_user_document_data,
    visitor_parameters,
    query_string,
    message_type = "skip_user_docs"
  ) => {
    setIsSkipLoading(true);
    const userDocumentSkip = await Api.post("v1/skip_user_documents", {
      message_type,
      skiped_user_document_data,
      visitor_parameters: { uuid, ...visitor_parameters.visitor_parameters },
      data: visitor_parameters.data,
      query_string: query_string,
    });

    setIsSkipLoading(false);
    return userDocumentSkip;
  };

  return {
    isSkipLoading,
    skipUserDocument,
  };
};

export const useUserDocumentFlp = () => {
  const [isLoadingFlp, setIsLoadingFlp] = useState(false);

  const saveUserDocumentFlp = async (
    user_document_data,
    flp_data,
    user_data,
    visitor_parameters,
    query_string,
    message_type = "user_docs_store_flp"
  ) => {
    setIsLoadingFlp(true);
    const userDocumentSubmitFlp = await Api.post("v1/user_documents", {
      message_type,
      user_document_data,
      flp_data,
      user_data,
      visitor_parameters,
      query_string: query_string,
    });

    setIsLoadingFlp(false);
    return userDocumentSubmitFlp;
  };

  return {
    isLoadingFlp,
    saveUserDocumentFlp,
  };
};
