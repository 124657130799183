import React, { useEffect, useState, useRef, useContext} from 'react';
import { useForm } from "react-hook-form";
import "../../assets/BMC_V3/css/BMC_V3.scss";
import "../../assets/BMC_V3/css/animate.css";
import Banks from '../Includes/Layouts/BMC_V3/Banks';
import { userLender } from '../../Hooks/userLender';
import QueryStringContext from "../../Contexts/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useHistory } from "react-router-dom";
import { userInfo } from '../../Hooks/userInfo';
import { Modal } from "react-bootstrap";

const Lender_V2 = () => {

  const [animationclass, setAnimationclass] = useState('show');
  const [lenderClass, setLenderClass] = useState('hide');
  const scrollRef = useRef([]);
  const [lenderSelectedList, setLenderSelectedList] = useState([]);
  const [otherBankSelectedList, setOtherBankSelectedList] = useState([]);
  const [isLenders, setIsLenders] = useState(false);
  const [lenderValidationMsg, setLenderValidationMsg] = useState();
  const [lenderFrmbtnDisabled, setLenderFrmBtnDisabled] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const queryuuid = queryParams.get('uuid');
  const [bankPopupStatus, setBankPopupStatus] = useState(false);
  const [bankNoticeModalShow, setBankNoticeModalShow] = useState(false);
  const [bankSelectionCount, setBankSelectionCount] = useState(0);
  const { getUserInfo } = userInfo();
  const field = "pid";
  const [userFirstName, setUserFirstName] = useState("");
  const {
    queryString
  } = useContext(QueryStringContext);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const {
    saveUserLender
  } = userLender();
  const history = useHistory();
  const {
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const scrollToElement = (element) => {
    scrollRef.current[element]?.scrollIntoView({
      behavior: "smooth",
      block: 'center',
      inline: 'center'
    });
  };

  const firstName = localStorage.getItem('first_name');

  
  const uuid = visitorParameters.visitor_parameters.uuid ? visitorParameters.visitor_parameters.uuid : queryuuid;

  useEffect(() => {

    setTimeout(() => {
      setAnimationclass('hide');
      setLenderClass('show');
    }, 3000
    );
    CheckUser();
  }, []);

  const CheckUser = async () => {
    if(firstName != null) {
       setUserFirstName(firstName);
    //    localStorage.clear();
    } else {
       const userDetails = await getUserInfo(uuid);
       setUserFirstName(userDetails.data.first_name);
    //    localStorage.clear();
    }
  }

  const formSubmit = async (e) => {
    const values = getValues();
    const formData = values;
  
    if(lenderSelectedList.length > 0 || otherBankSelectedList.length > 0) {
      let localBankSelectionCount = otherBankSelectedList.length + lenderSelectedList.length;
      console.log(localBankSelectionCount);
      if ( localBankSelectionCount > 0) {
        setLenderValidationMsg(false);
        setBankSelectionCount(localBankSelectionCount);
        if (localBankSelectionCount <= 3 && !bankPopupStatus) {
          setBankPopupStatus(true);
          setBankNoticeModalShow(true);
        } else {
          setBankNoticeModalShow(false);
          setLenderFrmBtnDisabled(true);
          let bankIds = lenderSelectedList.map((a) => a.id);
          let otherBankIds = otherBankSelectedList.map((a) => a.id);
          formData.lenders = bankIds;
          formData.otherBankList = otherBankIds;
          // console.log(queryuuid);
          
          // console.log(visitorParameters.visitor_parameters);
          const formSUbmitResult = await saveUserLender(
            uuid,
            formData,
            "lender_store",
            visitorParameters.visitor_parameters
          )

          if (formSUbmitResult.data.status === "Success") {
            if (queryString != null) {
              if (queryString.indexOf("&" + field + "=") !== -1) {
                history.push("/id-upload?uuid=" + visitorParameters.visitor_parameters.uuid); // fb fixel firing
              } else {
                history.push(
                  "/id-upload?uuid=" + visitorParameters.visitor_parameters.uuid
                );
              }
            } else {
              history.push(
                "/id-upload?uuid=" + visitorParameters.visitor_parameters.uuid
              );
            }
          }
        }
      } else {
        setLenderFrmBtnDisabled(false);
        setLenderValidationMsg("Please Select Any Lender");
        window.scrollTo(0, 0);
        return false;
      }
    } else {
      setLenderFrmBtnDisabled(false);
      setLenderValidationMsg("Please Select Any Lender");
      window.scrollTo(0, 0);
      return false;
    }
  }

  return (
    <>
      {/* Analyze part */}
      <GetVisitorsParams/>
      <div className="BMC_V3">
        <section className={`analyse ${animationclass}`}>
          <div className="questionspart">
            <div className="container">
              <div className="row">
                <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8">
                  <div className="space text-center">
                    <img src="/assets/BMC_V3/img/loader-review2.gif" alt="" />
                    <p className="htext"><b>{userFirstName}, we need few more details before we can proceed.</b></p>
                    <p> (Don't worry, it will only take few seconds.)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Lender part */}
        <section className={`lenderpage ${lenderClass}`}>
          <div className="questionspart">
            <div className="container">
              <div className="row">
                <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8">
                  <div className="space text-center">
                    <img src="/assets/BMC_V3/img/logo.png" alt="" />
                    {/* <h3>Tell Us About Your Credit Providers, Banks and Lenders</h3> */}
                    <p>{userFirstName}, most of our clients have had 4 or more  financial products from lenders over the last 15 years. Keep in mind, these include home, credit card, auto, and personal loans. Don't worry we will handle all the processing with no additional hassle on your part!</p>
                  </div>
                </div>
                <div className="offset-lg-2 col-lg-8 col-md-12 col-12 col-sm-12">
                  <form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                    {lenderValidationMsg ?
                      <span className={`error_msg`}>{lenderValidationMsg}</span> : ''
                    }
                    <Banks 
                    setLenderSelectedList ={setLenderSelectedList}
                    reff={scrollRef}
                    setLenderValidationMsg={setLenderValidationMsg}
                    setOtherBankSelectedList={setOtherBankSelectedList}
                    setIsLenders={setIsLenders}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`sticky-button ${lenderClass}`}>
          <div className="col-lg-12 p-0 ">
            <button
              type="button"
              id="btn_submit_claim"
              className="btn-sticky-button"
              disabled={lenderFrmbtnDisabled}
              onClick={formSubmit}
            >SUBMIT</button>
          </div>
        </section>
      </div>
    
      <Modal show={bankNoticeModalShow} onHide={() => setBankNoticeModalShow(false)} backdrop="static" aria-labelledby="BankSelectionNotice" centered contentClassName='popbord' className="lender_popup">
        <Modal.Body>
          <p className="text-center">We noticed you only selected {bankSelectionCount} lender. Most of our clients have had CCI from 4 or more lenders throughout their lifetime.</p>
          <div className="text-center">
            <a className='btn-pops' onClick={() => setBankNoticeModalShow(false)}>Yes, I'll add more</a>
            <a className='btn-pops' name="slide1" onClick={formSubmit}>No, that's all</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Lender_V2;