export const LenderList    =   {
    data:{
      response:{
        toplender:[
            {
                "id": 2,
                "bank_name": "ANZ",
                "rank": null
            },
            {
                "id": 27,
                "bank_name": "Beyond Bank Australia",
                "rank": null
            },
            {
                "id": 24,
                "bank_name": "Citi Bank",
                "rank": null
            },
            {
                "id": 55,
                "bank_name": "Companion Credit Union",
                "rank": null
            },
            {
                "id": 53,
                "bank_name": "CPS Credit Union (SA)",
                "rank": null
            },
            
            {
                "id": 25,
                "bank_name": "Esanda",
                "rank": null
            },
            {
                "id": 28,
                "bank_name": "ING",
                "rank": null
            },
            {
                "id": 89,
                "bank_name": "Money3",
                "rank": null
            },
            {
                "id": 31,
                "bank_name": "One Path",
                "rank": null
            },
            {
                "id": 101,
                "bank_name": "Latitude",
                "rank": null
            },
            
            
        ],  
        bigfive: [
            {
                "id": 1,
                "bank_name": "Commonwealth Bank of Australia",
                "rank": null
            },
            {
                "id": 2,
                "bank_name": "ANZ",
                "rank": null
            },
            // {
            //     "id": 4,
            //     "bank_name": "Westpac",
            //     "rank": null
            // },
            {
                "id": 3,
                "bank_name": "NAB",
                "rank": null
            },
            {
                "id": 8,
                "bank_name": "Suncorp",
                "rank": null
            },
        ],
        lender: [
            {
                "id": 7,
                "bank_name": "AMP",
                "rank": null
            },
            {
                "id": 23,
                "bank_name": "St George Bank ltd",
                "rank": null
            },
            {
                "id": 24,
                "bank_name": "Citi Bank",
                "rank": null
            },
            {
                "id": 25,
                "bank_name": "Esanda",
                "rank": null
            },
            {
                "id": 15,
                "bank_name": "Bank of Melbourne",
                "rank": null
            },
            {
                "id": 26,
                "bank_name": "Bank SA",
                "rank": null
            },
            {
                "id": 5,
                "bank_name": "Macquarie",
                "rank": null
            },
            {
                "id": 27,
                "bank_name": "Beyond Bank Australia",
                "rank": null
            },
            {
                "id": 28,
                "bank_name": "ING",
                "rank": null
            },
            {
                "id": 6,
                "bank_name": "Bendigo and Adelaide Bank ltd",
                "rank": null
            },
            {
                "id": 9,
                "bank_name": "Bankwest",
                "rank": null
            },
            {
                "id": 16,
                "bank_name": "Bank of Queensland",
                "rank": null
            },
        ],
        other_banks: [    
            {
                "id": 29,
                "bank_name": "ANZ Royal Bank",
                "rank": null
            },
            {
                "id": 30,
                "bank_name": "Australia New Zealand Banking Group",
                "rank": null
            },
            {
                "id": 31,
                "bank_name": "One Path",
                "rank": null
            },
            {
                "id": 32,
                "bank_name": "AMP Bank",
                "rank": null
            },
            {
                "id": 33,
                "bank_name": "AMP Lending Services",
                "rank": null
            },
            {
                "id": 34,
                "bank_name": "AMP New Zealand",
                "rank": null
            },
            {
                "id": 35,
                "bank_name": "Bank Australia",
                "rank": null
            },
            {
                "id": 13,
                "bank_name": "Bank Mecu",
                "rank": null
            },
            {
                "id": 36,
                "bank_name": "Citi Australia",
                "rank": null
            },
            {
                "id": 37,
                "bank_name": "Macquarie Capital",
                "rank": null
            },
            {
                "id": 38,
                "bank_name": "Bank of New Zealand",
                "rank": null
            },
            {
                "id": 39,
                "bank_name": "Challenger Financial Services",
                "rank": null
            },
            {
                "id": 40,
                "bank_name": "Clydesdale Bank",
                "rank": null
            },
            {
                "id": 41,
                "bank_name": "Commercial Bank of Sydney",
                "rank": null
            },
            {
                "id": 42,
                "bank_name": "National Bank Australasia",
                "rank": null
            },
            {
                "id": 43,
                "bank_name": "Neo Bank",
                "rank": null
            },
            {
                "id": 44,
                "bank_name": "U Bank",
                "rank": null
            },
            {
                "id": 45,
                "bank_name": "AAMI",
                "rank": null
            },
            {
                "id": 46,
                "bank_name": "GIO Insurance",
                "rank": null
            },
            {
                "id": 47,
                "bank_name": "Vero Insurance",
                "rank": null
            },
            // {
            //     "id": 48,
            //     "bank_name": "Westpac New Zealand",
            //     "rank": null
            // },
            {
                "id": 4,
                "bank_name": "Westpac",
                "rank": null
            },
            {
                "id": 49,
                "bank_name": "Bank of New South Wales",
                "rank": null
            },
            {
                "id": 50,
                "bank_name": "EECU ltd",
                "rank": null
            },
            {
                "id": 51,
                "bank_name": "Community CPS Australia ltd",
                "rank": null
            },
            {
                "id": 52,
                "bank_name": "Nexus Mutual",
                "rank": null
            },
            {
                "id": 53,
                "bank_name": "CPS Credit Union (SA)",
                "rank": null
            },
            {
                "id": 54,
                "bank_name": "CPS Credit Union Cooperative (ACT)",
                "rank": null
            },
            {
                "id": 55,
                "bank_name": "Companion Credit Union",
                "rank": null
            },
            {
                "id": 56,
                "bank_name": "My Credit Union",
                "rank": null
            },
            {
                "id": 57,
                "bank_name": "Credit Union SA",
                "rank": null
            },
            {
                "id": 58,
                "bank_name": "ING Australia",
                "rank": null
            },
            {
                "id": 59,
                "bank_name": "ING Life ltd",
                "rank": null
            },
            {
                "id": 60,
                "bank_name": "86 400 Bank",
                "rank": null
            },
            {
                "id": 61,
                "bank_name": "AWA Alliance Bank",
                "rank": null
            },
            {
                "id": 62,
                "bank_name": "BDCU Alliance Bank",
                "rank": null
            },
            {
                "id": 63,
                "bank_name": "Community Sector Banking",
                "rank": null
            },
            {
                "id": 64,
                "bank_name": "Delphi Bank",
                "rank": null
            },
            {
                "id": 65,
                "bank_name": "Rural Bank ltd",
                "rank": null
            },
            {
                "id": 10,
                "bank_name": "Australian Military Bank",
                "rank": null
            },
            {
                "id": 12,
                "bank_name": "Auswide Bank",
                "rank": null
            },
            {
                "id": 66,
                "bank_name": "AWA Alliance Bank",
                "rank": null
            },
            {
                "id": 67,
                "bank_name": "BankVic",
                "rank": null
            },
            {
                "id": 68,
                "bank_name": "Border Bank",
                "rank": null
            },
            {
                "id": 69,
                "bank_name": "Police Bank",
                "rank": null
            },
            {
                "id": 70,
                "bank_name": "BOQ Specialists",
                "rank": null
            },
            {
                "id": 71,
                "bank_name": "Circle Alliance Bank",
                "rank": null
            },
            {
                "id": 20,
                "bank_name": "Regional Australia Bank",
                "rank": null
            },
            {
                "id": 72,
                "bank_name": "Defence Bank",
                "rank": null
            },
            {
                "id": 73,
                "bank_name": "Firefighters Mutual",
                "rank": null
            },
            {
                "id": 74,
                "bank_name": "Greater Bank",
                "rank": null
            },
            {
                "id": 75,
                "bank_name": "IMB Bank",
                "rank": null
            },
            {
                "id": 18,
                "bank_name": "ME Bank",
                "rank": null
            },
            {
                "id": 19,
                "bank_name": "P&N Bank",
                "rank": null
            },
            {
                "id": 76,
                "bank_name": "Q Bank",
                "rank": null
            },
            {
                "id": 77,
                "bank_name": "Qudos Bank",
                "rank": null
            },
            {
                "id": 78,
                "bank_name": "RACQ Bank",
                "rank": null
            },
            {
                "id": 21,
                "bank_name": "Unity Bank ltd",
                "rank": null
            },
            {
                "id": 79,
                "bank_name": "Reliance Bank",
                "rank": null
            },
            {
                "id": 80,
                "bank_name": "RSL Monety",
                "rank": null
            },
            {
                "id": 81,
                "bank_name": "Service One Alliance Bank",
                "rank": null
            },
            {
                "id": 82,
                "bank_name": "Teachers Mutual Bank",
                "rank": null
            },
            {
                "id": 83,
                "bank_name": "UniBank",
                "rank": null
            },
            {
                "id": 22,
                "bank_name": "Volt Bank",
                "rank": null
            },
            {
                "id": 84,
                "bank_name": "Bank of China Australia ltd",
                "rank": null
            },
            {
                "id": 85,
                "bank_name": "Bank of Beirut",
                "rank": null
            },
            {
                "id": 86,
                "bank_name": "Bank of Sydney",
                "rank": null
            },
            {
                "id": 87,
                "bank_name": "HSBC Bank Australia",
                "rank": null
            },
            {
                "id": 88,
                "bank_name": "RABO Bank Australia",
                "rank": null
            },
            {
                "id": 11,
                "bank_name": "Australian Mutual",
                "rank": null
            },
            {
                "id": 89,
                "bank_name": "Money3",
                "rank": null
            },
            {
                "id": 90,
                "bank_name": "Humm90",
                "rank": null
            },
            {
                "id": 91,
                "bank_name": "New England Credit Union",
                "rank": null
            },
            {
                "id": 92,
                "bank_name": "OMM",
                "rank": null
            },         
            // {
            //     "id": 14,
            //     "bank_name": "BankFirst",
            //     "rank": null
            // },
            // {
            //     "id": 17,
            //     "bank_name": "Heritage Bank",
            //     "rank": null
            // }     
            {
                "id": 93,
                "bank_name": "AGC Credit now GE",
                "rank": null
            },
            {
                "id": 94,
                "bank_name": "AIA Australia",
                "rank": null
            },
            {
                "id": 95,
                "bank_name": "Allianz",
                "rank": null
            },
            {
                "id": 96,
                "bank_name": "Avea Insurance",
                "rank": null
            },
            {
                "id": 97,
                "bank_name": "Eric Insurance",
                "rank": null
            },
            {
                "id": 98,
                "bank_name": "GE Capital",
                "rank": null
            },
            {
                "id": 99,
                "bank_name": "GE Finance",
                "rank": null
            },
            {
                "id": 100,
                "bank_name": "GE Money",
                "rank": null
            },
            {
                "id": 101,
                "bank_name": "Latitude",
                "rank": null
            },
            {
                "id": 102,
                "bank_name": "Swann Insurance",
                "rank": null
            }

        ]
    }
  }  
}
