import React,{useEffect, useState, useRef, useReducer, useContext} from "react";
import { FormProvider, useForm } from "react-hook-form";
import Email from "../UI/Email";
import SignStyle from "../../assets/Signature/css/Signature.module.scss";
import { useEmailValidation } from "../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../Hooks/usePhoneValidation";
import { useSignature } from "../../Hooks/useSignature";
import SignaturePd from "signature_pad";
import Telephone from "../UI/Telephone";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import {useDataIngestion} from "../../Hooks/useDataIngestion";
import { useHistory } from "react-router-dom";
import Button from "../UI/Button";
import SignatureCanvas from "react-signature-canvas";
import Toplenders from "../Includes/Layouts/BMC_V3/Toplenders";
import BasicDetails from "../Includes/Layouts/BMC_V3/BasicDetails";
import SignaturePreviewPopup from "../Includes/Layouts/SignaturePreviewPopup";
import PrevAddressPopup from "../Includes/Layouts/PrevAddressPopup";

const FormBMC_V3 = ({ Sass }) =>{

    const {
        emailValidation
    } = useEmailValidation();
    const {
        phoneValidation
    } = usePhoneValidation();
    const [topLenderSelectedList, setTopLenderSelectedList] = useState([]);
    const [signaturePad, setSignaturePad] = useState();
    const { saveSignature, isLoading } = useSignature();
    const [signatureModalShow, setSignatureModalShow] = useState(false);
    const [signaturePreviewModalShow, setSignaturePreviewModalShow] = useState(false);
    const [signaturePreviewImage, setSignaturePreviewImage] = useState('');
    const [isSignValid, setIsSignValid] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const signPadRef = useRef();
    // const canvasRef = useRef();
    const scrollRef = useRef([]);
    const splitForm = useRef(null);
    const [emailCheck, setEmailCheck] = useState('hide');
    const [frmbtnDisabled, setFrmBtnDisabled] = useState(false);
    const [isTopLenders, setIsTopLenders] = useState(false);
    const [topLenderValidationMsg, setTopLenderValidationMsg] = useState();
    const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
    const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
    // const [isAgreedChecked, setIsAgreedChecked] = useState(false);
    const [showRegisterPop, setShowRegisterPop] = useState(false);

    const signatureWrapRef = useRef(null);
    const field = "pid";
    const {
        visitorParameters
    } = useContext(VisitorParamsContext);
    const {
        queryString
    } = useContext(QueryStringContext);
    const {
        saveDataIngestion,
    } = useDataIngestion();
    const history = useHistory();
    const [showprivacy, setshowprivacy] = useState(false);
    const handleprivacyShow = (e) => {
        e.preventDefault();
        setshowprivacy(true);
    };
    const handleprivacyClose = () => {
        setshowprivacy(false);
    };

    // const readyPad = () => {
    //     let wrapper = document.getElementById("signature-pad");
    //     let canvas = wrapper?.querySelector("canvas");

    //     canvas.getContext("2d").scale(1, 1);
    //     let tempSignaturePad = new SignaturePd(canvas, {
    //         backgroundColor: "rgb(255, 255, 255)"
    //     });
    //     setSignaturePad(tempSignaturePad);
    // };

		// use Form
    const formMethods = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
      });
      const {
        register,
        errors,
        trigger,
        setError,
        clearErrors,
        getValues,
        watch,
        handleSubmit,
      } = formMethods;

    // useEffect(() => {
    //     readyPad();
    // }, []);
    const initialState = {
        isAgreed: false,
        isLoanCheck: false,
        isCanvasEmpty: false,

    };

    const signatureReducer = (state, action) => {
        switch (action.type) {
            case "privacyAgreed": {
                return {
                    ...state,
                    isAgreed: action.payload.value,
                };
            }
            case "loansCheck": {
                return {
                    ...state,
                    isLoanCheck: action.payload.value,
                };
            }
            case "signPadChange": {
                return {
                    ...state,
                    isCanvasEmpty: action.payload.value,
                };
            }
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(signatureReducer, initialState);

    const scrollToElement = (element) => {

        scrollRef.current[element]?.scrollIntoView({
            behavior: "smooth",
            block: 'center',
            inline: 'center'
        });
    };

    const handleClose = () => {
        setShowRegisterPop(false);
      };

    const openPrevPopup = async () => {
        setSignaturePreviewModalShow(false);
        setShowRegisterPop(true);
      };


    const emailValidCheck = async () => {
        const values = getValues();
        const email = values['txtEmail'];
        if (email !== "") {
            if (/^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(email)) {
                setEmailCheck('show');
                const getEmailValidation = await emailValidation(email);
                if (getEmailValidation.data.status_code === 0) {
                    scrollToElement('txtEmail');
                    setError("txtEmail", {
                        type: "manual",
                        message: "Email Already Exist",
                    });
                    setEmailCheck('hide');
                    document.getElementById("btn_submit_claim").disabled = false;
                    return 0;
                } else if (getEmailValidation.data.status_code === 2) {
                    scrollToElement('txtEmail');
                    setError("txtEmail", {
                        type: "manual",
                        message: "Email is Invalid",
                    });
                    setEmailCheck('hide');
                    document.getElementById("btn_submit_claim").disabled = false;
                    return 0;
                } else {

                    setEmailCheck('hide');
                    return 1;
                }
            } else {
                scrollToElement('txtEmail');
                setError("txtEmail", {
                    type: "manual",
                    message: "Invalid Email Address",
                });
                document.getElementById("btn_submit_claim").disabled = false;
                return 0;
            }
        } else {
            document.getElementById("btn_submit_claim").disabled = false;
            trigger("txtEmail");
            return 0;
        }
    };

    const initialSubmitClick = async (e) => {
			let errorFlag = 0;
			const values = getValues();
			const form = splitForm.current;
			const formData = values;

        //await trigger("txtEmail");
				const txtTitle = values["lstSalutation"];    
        const txtFName = values["txtFName"];
        const txtLName = values["txtLName"];
				// const txtSearchKey = values["txtPostCode"];
        // const txtAddress = values["address1"];
				const txtSearchKey = values["txtSearchKey"];
				const txtAddress = values["txtAddress"];
        const txtEmail = values["txtEmail"];
				const txtPhone = values["txtPhone"];
        
				let txtTitleResult;        
        let txtFNameResult;
        let txtLNameResult;
        let txtSearchKeyResult;
        let txtAddressResult;
        let lstDobDayResult;
        let lstDobMonthResult;
        let lstDobYearResult;
        let txtPhoneResult;
        let txtEmailResult;

        if (isTopLenders) {
            setTopLenderValidationMsg(false);
            txtFNameResult = await trigger("lstSalutation");
        } else {
            setTopLenderValidationMsg("Please select Any Lender");
            scrollToElement('loansec');
            dispatch({
                type: "loansCheck",
                payload: {
                    value: true
                }
            });
            return false;
        }
         // title
        txtTitleResult = await trigger("lstSalutation");
        if (txtTitleResult) {
          txtFNameResult = await trigger("txtFName");
        } else {
					scrollToElement('lstSalutation');
					return false;
        }
        // First Name
        if (txtFNameResult) {
            if (
                txtFName.trim() == "" ||
                txtFName.trim().length == 0 ||
                txtFName.trim().length < 3
            ) {
                scrollToElement('txtFName');
                setError("txtFName", {
                    type: "manual",
                    message: "Your Name is Invalid. Please Recheck",
                });
                return false;
            } else {
                txtLNameResult = await trigger("txtLName");
            }
        } else {
            scrollToElement('txtFName');
            return false;
        }

        // Lastname
        if (txtLNameResult) {
            if (
                txtLName.trim() == "" ||
                txtLName.trim().length == 0 ||
                txtLName.trim().length < 3
            ) {
                scrollToElement('txtLName');
                setError("txtLName", {
                    type: "manual",
                    message: "Your Name is Invalid. Please Recheck",
                });
                return false;
            } else {
                // txtSearchKeyResult = await trigger("txtPostCode");
								txtSearchKeyResult = await trigger("txtSearchKey");
                //lstDobDayResult = await trigger("lstDobDay");
            }
        } else {
            scrollToElement('txtLName');
            return false;
        }
        if (txtSearchKeyResult) {
            if (
                txtSearchKey.trim() == "" ||
                txtSearchKey.trim().length == 0
            ) {
                // scrollToElement('txtPostCode');
								scrollToElement('txtSearchKey');
                return false;
            } else {
                // txtAddressResult = await trigger("address1");
								txtAddressResult = await trigger("txtAddress");
            }
        } else {
            // scrollToElement('txtPostCode');
						scrollToElement('txtSearchKey');
            return false;
        }
        // Address select dropdown
        if (txtAddressResult) {
            if (
                txtAddress.trim() == "" ||
                txtAddress.trim().length == 0
            ) {
                // setError("address1", {
                //     type: "manual",
                //     message: "Please Select Address",
                // });
								setError("txtAddress", {
									type: "manual",
									message: "Please Select Address",
							});
                return false;
            } else {
                // txtAddressLine1Result = await trigger("txtAddressLine1");
                lstDobDayResult = await trigger("lstDobDay");
            }
        } else {
            scrollToElement('DateOfBirth');
            return false;
        }

        // DOB secttion 
        if (lstDobDayResult) {
            lstDobMonthResult = await trigger("lstDobMonth");
        } else {
            scrollToElement('DateOfBirth');
            return false;
        }
        if (lstDobMonthResult) {
            lstDobYearResult = await trigger("lstDobYear");
        } else {
            scrollToElement('DateOfBirth');
            return false;
        }
        if (lstDobYearResult) {
            txtPhoneResult = await trigger("txtPhone");

        } else {
            scrollToElement('DateOfBirth');
            return false;
        }

        if (txtPhoneResult) {
            if (
                txtPhone.trim() == "" ||
                txtPhone.trim().length == 0
            ) {
                scrollToElement('txtPhone');
                return false;
            } else {
                txtEmailResult = await trigger("txtEmail");
            }
        } else {
            scrollToElement('txtPhone');
            // window.scrollTo(0, 0)
            return false;
        }
        // Email and Phone 

        if (txtEmailResult) {
            if (
                txtEmail.trim() == "" ||
                txtEmail.trim().length == 0
            ) {
                scrollToElement('txtEmail');
                return false;
            }
        } else {
            scrollToElement('txtEmail');
            // window.scrollTo(0, 0)
            return false;
        }

        if (txtEmail && txtPhone) {
            if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
                errorFlag = 0;
            } else {
                return false;
            }
        }
		
        try
        {
          const signPad = signPadRef.current;
            if (signPad.isEmpty()) 
            {
                return dispatch({ type: "signPadChange", payload: { value: true } });
            } 
            else 
            {
                setIsSignValid(false);
                let signdata = signPad.getSignaturePad().toData();
                let signlength = 0;
                signdata.forEach(key => {
                signlength = signlength + key.length;
                });
                if (signlength > 13) 
                {
                    if ( visitorParameters.data !== null || visitorParameters.data !== undefined) 
                    {   
                        let width = signPad.getCanvas().width;
                        let height = signPad.getCanvas().height;
                        let canvasParameters = { 'width': width, 'height': height };

                        setSignaturePreviewImage(signPad.getCanvas().toDataURL("image/png"));
                       
                        //setSignaturePreviewModalShow(true);
                    }
                } 
                else 
                {
                setIsSignValid(true);
                }
            }
        } 
        catch (e) 
        {
          console.warn(e);
        }

        // if (!isAgreedChecked) {
        //     errorFlag = 1;
        //     scrollToElement('isAgreedChecked');
        //     return dispatch({ type: "privacyAgreed", payload: { value: true } });
        //   }
        // else {
            setSignaturePreviewModalShow(true);
        // }  


    };


    // useEffect(() => {
    //     if (isAgreedChecked) {
    //       dispatch({ type: "privacyAgreed", payload: { value: false } });
    //     }
    // }, [isAgreedChecked]);


    const phoneValidCheck = async () => {
        const values = getValues();
        const phone = values['txtPhone'];
        if (phone !== "") {
            const getPhoneValidation = await phoneValidation(phone)
            if (
                getPhoneValidation.data.status_code === 0 ||
                getPhoneValidation.data.status === "Fail"
            ) {
                scrollToElement('txtPhone');
                setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Phone Number",
                });
                document.getElementById("btn_submit_claim").disabled = false;
                return 0;
            } else if (getPhoneValidation.data.status_code === 2) {
                scrollToElement('txtPhone');
                setError("txtPhone", {
                    type: "manual",
                    message: "Phone Number Already Exist",
                });
                document.getElementById("btn_submit_claim").disabled = false;
                return 0;
            } else if (getPhoneValidation.data.status_code === 3) {
                scrollToElement('txtPhone');
                setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Phone Number",
                });
                document.getElementById("btn_submit_claim").disabled = false;
                return 0;
            } else {
                return 1;
            }
        }
    };


    const formSubmit = async () => {
        let errorFlag = 0;
        const values = getValues();
        const form = splitForm.current;
        const formData = values;

        //await trigger("txtEmail");    
				const txtTitle = values["lstSalutation"];
        const txtFName = values["txtFName"];
        const txtLName = values["txtLName"];
        // const txtSearchKey = values["txtPostCode"];
        // const txtAddress = values["address1"];
				const txtSearchKey = values["txtSearchKey"];
        const txtAddress = values["txtAddress"];
        const txtPhone = values["txtPhone"];
        const txtEmail = values["txtEmail"];

        let txtEmailResult;
        let txtFNameResult;
        let txtLNameResult;
        let txtSearchKeyResult;
        let txtAddressResult;
        let lstDobDayResult;
        let lstDobMonthResult;
        let lstDobYearResult;
        let txtPhoneResult;
        let txtTitleResult;

        if (isTopLenders) {
            setTopLenderValidationMsg(false);
            txtFNameResult = await trigger("lstSalutation");
        } else {
            setTopLenderValidationMsg("Please select Any Lender");
            scrollToElement('loansec');
            dispatch({
                type: "loansCheck",
                payload: {
                    value: true
                }
            });
            return false;
        }
         // title
        txtTitleResult = await trigger("lstSalutation");
        if (txtTitleResult) {

            txtFNameResult = await trigger("txtFName");
        } else {
            scrollToElement('lstSalutation');
            return false;
        }
        // First Name
        if (txtFNameResult) {
            if (
                txtFName.trim() == "" ||
                txtFName.trim().length == 0 ||
                txtFName.trim().length < 3
            ) {
                scrollToElement('txtFName');
                setError("txtFName", {
                    type: "manual",
                    message: "Your Name is Invalid. Please Recheck",
                });
                return false;
            } else {
                txtLNameResult = await trigger("txtLName");
            }
        } else {
            scrollToElement('txtFName');
            return false;
        }

        // Lastname

        if (txtLNameResult) {
            if (
                txtLName.trim() == "" ||
                txtLName.trim().length == 0 ||
                txtLName.trim().length < 3
            ) {
                scrollToElement('txtLName');
                setError("txtLName", {
                    type: "manual",
                    message: "Your Name is Invalid. Please Recheck",
                });
                return false;
            } else {
                // txtSearchKeyResult = await trigger("txtPostCode");
								txtSearchKeyResult = await trigger("txtSearchKey");
                //lstDobDayResult = await trigger("lstDobDay");
            }
        } else {
            scrollToElement('txtLName');
            return false;
        }
        if (txtSearchKeyResult) {
            if (
                txtSearchKey.trim() == "" ||
                txtSearchKey.trim().length == 0
            ) {
                // scrollToElement('txtPostCode');
								scrollToElement('txtSearchKey');
                return false;
            } else {
                // txtAddressResult = await trigger("address1");
								txtAddressResult = await trigger("txtAddress");
            }
        } else {
            // scrollToElement('txtPostCode');
						scrollToElement('txtSearchKey');
            return false;
        }
        // Address select dropdown
        if (txtAddressResult) {
            if (
                txtAddress.trim() == "" ||
                txtAddress.trim().length == 0
            ) {
                // setError("address1", {
                //     type: "manual",
                //     message: "Please Select Address",
                // });
								setError("txtAddress", {
									type: "manual",
									message: "Please Select Address",
							});
                return false;
            } else {
                // txtAddressLine1Result = await trigger("txtAddressLine1");
                lstDobDayResult = await trigger("lstDobDay");
            }
        } else {
            scrollToElement('DateOfBirth');
            return false;
        }

        // DOB secttion 
        if (lstDobDayResult) {
            lstDobMonthResult = await trigger("lstDobMonth");
        } else {
            scrollToElement('DateOfBirth');
            return false;
        }
        if (lstDobMonthResult) {
            lstDobYearResult = await trigger("lstDobYear");
        } else {
            scrollToElement('DateOfBirth');
            return false;
        }
        if (lstDobYearResult) {
            txtPhoneResult = await trigger("txtPhone");

        } else {
            scrollToElement('DateOfBirth');
            return false;
        }

        if (txtPhoneResult) {
            if (
                txtPhone.trim() == "" ||
                txtPhone.trim().length == 0
            ) {
                scrollToElement('txtPhone');
                return false;
            } else {
                txtEmailResult = await trigger("txtEmail");
            }
        } else {
            scrollToElement('txtPhone');
            // window.scrollTo(0, 0)
            return false;
        }
        // Email and Phone 

        if (txtEmailResult) {
            if (
                txtEmail.trim() == "" ||
                txtEmail.trim().length == 0
            ) {
                scrollToElement('txtEmail');
                return false;
            }
        } else {
            scrollToElement('txtEmail');
            // window.scrollTo(0, 0)
            return false;
        }



        if (txtEmail && txtPhone) {
            if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
                errorFlag = 0;
            } else {
                return false;
            }
        }
        const signPad = signPadRef.current;
        const signatureData =  signPad.getSignaturePad().toData();
        console.log(signatureData);
        if (signatureData.length <= 0) {
            errorFlag = 1;
            scrollToElement('signature');
            return dispatch({
                type: "signPadChange",
                payload: {
                    value: true
                }
            });
        } else {
            let signlength = 0;
            signatureData.forEach(key => {
                signlength = signlength + key.length;
                });
            setIsSignValid(false);
            scrollToElement('signature');
            dispatch({
                type: "signPadChange",
                payload: {
                    value: false
                }
            });
            if (signlength > 13) {
                errorFlag = 0;
            } else {
                setIsSignValid(true);
                return false;
            }
        }

        if (errorFlag == 0) {
            let toplenderIds = topLenderSelectedList.map((a) => a.id);
            let signature = signPad.getCanvas().toDataURL("image/png");
            formData.top_lender = toplenderIds;
            formData.page_name = 'BMC_V3';
            formData.signature = signature;

            let width = signPad.getCanvas().width;
            let height = signPad.getCanvas().height;
            formData.canvasWidth = width;
            formData.canvasHeight = height;
            if (visitorParameters.data !== null || visitorParameters.data !== undefined) {
                localStorage.setItem("formData", JSON.stringify(formData));
                localStorage.setItem("queryString", queryString);
                localStorage.setItem('first_name', formData.txtFName);
                localStorage.setItem('top_lender_list', formData.top_lender);

                if (formData.txtAddress !== "") 
                {

                    // const txtAddress3 = form["txtAddress3"].value;
                    // const txtCountry = form["txtCountry"].value;
                    // const txtCounty = form["txtCounty"].value;

                    // const txtAddress4 = form["txtAddress4"].value;
                    // const txtLocality = form["txtLocality"].value;
                    // const txtDistrict = form["txtDistrict"].value;
                    // const hdPostcode = form["hdPostcode"].value;

                    // formData.txtAddress3 = txtAddress3;
                    // formData.txtAddress4 = txtAddress4;
                    // formData.txtLocality = txtLocality;
                    // formData.txtDistrict = txtDistrict;
                    // formData.txtCounty = txtCounty;
                    // formData.txtCountry = txtCountry;
                    // formData.hdPostcode = hdPostcode;
                    console.log(values);
                }
                console.log(formData);
                setFrmBtnDisabled(true);
                const formSUbmitResult = await saveDataIngestion(
                    visitorParameters.visitor_parameters,
                    formData,
                    'split_form_submit',
                    'BMC_V3',
                    queryString,
                    visitorParameters.data
                )

                if (formSUbmitResult.data.status === "Success") {
                    if (queryString != null) {
                        if (queryString.indexOf("&" + field + "=") !== -1) {
                            history.push("/fbpixel?split_name=BMC_V3"); // fb fixel firing
                        } else {
                            history.push(
                            "/lender_V2?uuid=" + visitorParameters.visitor_parameters.uuid
                            );
                        }
                    } else {
                        history.push(
                            "/lender_V2?uuid=" + visitorParameters.visitor_parameters.uuid
                        );
                    }
                }
            }
        }
    };
    const clearSign = () => {
        if (signPadRef.current) signPadRef.current.clear();
        setIsSignValid(false);
        setSignaturePreviewModalShow(false);
        setSignaturePreviewImage('');
      };

    const basic_onchangehandle = async (e) => {
        document.getElementById("btn_submit_claim").disabled = false;
        trigger(e.target.name);
    };


    return(
      <>
      <FormProvider {...formMethods}>
       <form   ref={splitForm}  name="split_form" id="user_form" method="POST" autoComplete="off">
            <h1>Register your Refund for Junk Insurance Today. You Could be Owed $1000's!</h1>
            <p>According to the government, Junk Insurance policies like CCI were sold to millions of people in Australia; millions of people have paid a very high price for products that turned out to have very little value, or even no benefit at all.</p>
            
            <div className="row">
                <div className="col-lg-12 col-12  form-section">
                    <h3>Register Your Refund Now</h3>
                    <ul>
                        <li>No Account Number Needed</li>
                        <li>No Paperwork Required</li>
                        <li>Starting your claim is easy and 100% online</li>
                    </ul>
                    <div className=" form-group">
                       <Toplenders 
                            setTopLenderSelectedList ={setTopLenderSelectedList}
                            reff={scrollRef}
                            setTopLenderValidationMsg={setTopLenderValidationMsg}
                            setIsTopLenders={setIsTopLenders}
                        />
                        {topLenderValidationMsg ?
                            <span className={` error_msg`}>{topLenderValidationMsg}</span> : ''
                        }
                    </div>
                    <BasicDetails 
                        reff={scrollRef}
                        setError={setError}
                        validation={register}
                        validationMsg={errors}
                        trigger={trigger}
                        getValues={getValues}
                        clearErrors={clearErrors} 
                        basic_onchangehandle={basic_onchangehandle}
                    />


                    <div className=" form-group" ref={(el) => (scrollRef.current['txtPhone'] = el)}>
                        <Telephone
                            name="txtPhone"
                            className="form-control mt-3"
                            placeholder="Mobile Phone"
                            maxlength="11"
                            onBlur={phoneValidCheck}
                            onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                            validation={register({ required: "Please Enter Valid Phone Number" })}
                            validationMsg={
                                errors.txtPhone && errors.txtPhone.message
                            }
                            onChange ={basic_onchangehandle}
                        ></Telephone>
                    </div>
                    <div className=" form-group" ref={(el) => (scrollRef.current['txtEmail'] = el)}>
                        <Email 
                            name="txtEmail"
                            className="form-control"
                            placeholder="Email Address"
                            onBlur={emailValidCheck}
                            validation={register({
                            required: "Please Enter Valid Email Address",
                            pattern: {
                            value:
                                /^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
                            message: "Invalid Email Address",
                            },
                            })}
                            validationMsg={
                            errors.txtEmail && errors.txtEmail.message
                            }
                            onChange={basic_onchangehandle}
                            >
                        </Email>
                    </div>
                    <p>Please sign your signature in the box below</p>
                    <div className="signature-div clearfix" id="signature-pad" ref={(el) => (scrollRef.current['signature'] = el)}>
                    
                            <SignatureCanvas
                            clearOnResize={false}
                            canvasProps={{
                                className: `${SignStyle["sigCanvas"]} anim_bt d-lg-block`, width: 300, height: 200
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                
                        <div className="c">
                        <span className="text-danger" id="signatures_required" style={{ display: "none" }} >Signatures are required!</span>

                        {/*  */}
                        {state.isCanvasEmpty && (
                          <span id="signatures_required" className="error_msg">
                            Signature is required!
                          </span>
                        )}
                        {isSignValid && (
                          <span id="signatures_required" className="error_msg">
                            Draw valid signature
                          </span>
                        )}

                      </div>
                    </div>


                        <div className="col-lg-12">
                            <button type="button" className="button-clear" onClick={clearSign}>Clear</button>
                        </div>

              {/* <div className="form-group agreed-sec" ref={(el) => (scrollRef.current['isAgreedChecked'] = el)}>
                <input
                  type="checkbox"
                  name="isAgreed"
                  className="cust_box"
                  style={{ cursor: "pointer" }}
                  onChange={(e) => setIsAgreedChecked(!isAgreedChecked)}
                  value={isAgreedChecked}
                  id="acceptterms"
                  />{"  "}
                <label className="form-check-label txt_black" htmlFor="acceptterms">
                By signing and by pressing “Submit” you agree to Boomerangs <a target="_blank" href="./docs/Boomerang_Claims_LOA.pdf">LOA</a> & <a href='./docs/Boomerang_Claims_LOE.pdf' target="_blank">LOE</a>
                </label>
                {state.isAgreed && (
                  <span  className="error_msg">
                    Please agree to continue
                  </span>
                )}             
              </div> */}



                        <div className="col-lg-12">
                            <Button
                            type="button"
                            className="btn button-submit"
                            id="btn_submit_claim"
                            disabled={frmbtnDisabled}
                            onClick={initialSubmitClick}
                            buttonText="Submit my claim"
                            />
                        </div>
                    <div className="regulatory text-center">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12 text-start">
                                <a href="https://www.afca.org.au/" target="_blank"><img src="/assets/BMC_V3/img/logo-afca.svg" alt="" /></a>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-8 col-12 text-start">
                                <p><b>Boomerang Claims</b> are proud members of the Australian Financial Complaints Authority. 
                                    Our membership number is <b>89098</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </form>
      </FormProvider>

        <SignaturePreviewPopup
        show={signaturePreviewModalShow}
        onHide={() => setSignaturePreviewModalShow(true)}
        clearAction={clearSign}
        submitAction={openPrevPopup}
        signaturePreviewImage={signaturePreviewImage}
        isLoading={isLoading}
      />
      <PrevAddressPopup
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          show={showRegisterPop}
          handleClose={handleClose}
          formSubmit={formSubmit}
        />
      </>
    );
}

 export default FormBMC_V3;