import react from 'react';
import ProgressBar from "../../Layouts/BMC_V2​/ProgressBar";
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import Button from "../../../UI/Button";
const Dob = ({
  className, slideChange,validation,validationMsg,trigger,splitForm,setError,getValues,clearError
}) => {

  const basic_onchangehandle = async (e) => {
    trigger(e.target.name);
  }

  const nextSlide = async (e) => {
    let errorFlag = 0;
    let lstDobDayResult = await trigger("lstDobDay");
    var lstDobMonthResult;
    var lstDobYearResult;
      if (lstDobDayResult) {
        lstDobMonthResult = await trigger("lstDobMonth");
      } else  {
        return false;
      }
      if (lstDobMonthResult) {
        lstDobYearResult = await trigger("lstDobYear");
      } 
      else {
        return false;
      }
      if (

        !lstDobDayResult ||
        !lstDobMonthResult ||
        !lstDobYearResult
      ) {
        errorFlag = 1;
      }
      if (errorFlag === 0) {
      
        slideChange(e);
      } else {
        return false;
      }
};

    return(
        <div id="slide4" className={className} >
        <ProgressBar value="60" />
        <div className="mb-3">
          <h4>Date of Birth</h4>
          <SelectBox
              className="form-select"
              OptionValue={DobDay}
              name="lstDobDay"
             
              myRef={validation({
                required: "Please Select Date"
              })}
              validationMsg={validationMsg.lstDobDay && validationMsg.lstDobDay.message}
              onChange={basic_onchangehandle}
            />
        </div>
        <div className="mb-3">
          <SelectBox
              className="form-select"
              OptionValue={DobMonth}
              name="lstDobMonth"
              myRef={validation({
                required: "Please Select Month"
              })}
              validationMsg={validationMsg.lstDobMonth && validationMsg.lstDobMonth.message}
              onChange={basic_onchangehandle}
            />
        </div>
        <div className="mb-3">
          <SelectBox
              className="form-select"
              OptionValue={DobYear}
              name="lstDobYear"
              myRef={validation({
                required: "Please Select Year"
              })}
              validationMsg={validationMsg.lstDobYear && validationMsg.lstDobYear.message}
              onChange={basic_onchangehandle}
            />
        </div>
        <div className="mb-3">
          {/* <input type="button" className="btn-next" id="next04" defaultValue="Next" /> */}
          <Button
            type="button"
            className="btn-next"
            id="next04"
            buttonText="Next"
            name="slide4"
            onClick={nextSlide}/>             
        </div>
   </div>
    );
};

export default Dob;