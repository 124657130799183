import React, { useContext, useState, useReducer } from "react";
import ProgressBar from "../../Layouts/MCS_V1/ProgressBar";
import InputButton from "../../../UI/InputButton";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import AddressTextField from "../../../UI/AddressTextField";
import SelectBox from "../../../UI/SelectBox";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import { urlParams } from "../../../../Utility/QueryString";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
}
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case 'validationSucess': {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,

      }
    }
    case 'getExtraAddressDetails': {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address
      }
    }
    case 'showManualDiv': {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,

      }

    }
    default:
      return state;
  }
}
const PostCode = ({
  className,
  textHeading,
  validation,
  validationMsg,
  trigger,
  splitForm,
  setError,
  getValues,
  clearErrors
}) => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState('0');
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const history = useHistory();
  const basicDetailsSubmit = async (e) => {
    const txtPostCode = await trigger("txtPostCode");
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    formData.pCode_manual = form['pCode_EntryType'].value;
    const txtDobYear = form["DobYear"].value;
    formData.DobYear = '19' + txtDobYear;
    localStorage.setItem('formData', JSON.stringify(formData));
    let pid = "";
    pid = urlParams.get("pid");
    let errorFlag = 0;
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtHouseNumber");
      txtAddress = await trigger("address1");
    }
    else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    }
    else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    }
    else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }
    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      if (pid == "" || pid == null) {
        history.push("/user-info?uuid=" + visitorParameters.visitor_parameters.uuid);
      } else {
        history.push("/user-info?uuid=" + visitorParameters.visitor_parameters.uuid + '&pid=' + pid);
      }
    } else {
      return false;
    }
  };
  const checkValidation = async (e) => {
    document.getElementById("address1").classList.add('anim_ylw');
    e.preventDefault();
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      const getValidation = await getPostcodeAddress(e.target.value);
      if (getValidation.data.status === '0') {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
      if (getValidation.data.length > 0) {
        dispatch({
          type: 'validationSucess',
          payload: {
            lookup: false,
            next_click: true,
            postcode: e.target.value,
            get_address: getValidation.data,
            check_select: true,
            manual_address: "hide",
          }
        });
      }
    }
  };
  const checkPostCodeEntry = () => {
    const company = document.getElementById("txtHouseName").value;
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress3").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (postCodeAddress.length === 0 ||
      postCodeAddress.Company !== company ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town) {
      setPostCodeEntry('1');
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""}
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""}
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={state.getDetails.Line3 !== "#" ? state.getDetails.Line3 : ""}
      />
    </>
  );
  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  }
  const getValue = async (e) => {
    e.preventDefault();
    clearErrors(["txtHouseNumber", "txtAddress3", "txtCounty", "txtTown", "txtCountry"]);
    const getData = await getSplitPostcodeAddress(e.target.value, state.getPostcode)
    dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show" } });
    setPostCodeAddress(getData.data);
  };
  return (
    <>
      <div id="slide4" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="90" />
        <h3 className="pb-2">{textHeading}</h3>
        <HiddenField
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />
        <p className="px-4 text-center">Plans & bonus benefits vary based on where you live.</p>
        <div className="col-lg-12 text-center">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group post">
                <AddressTextField
                  type="text"
                  placeholder="Your Postcode"
                  name="txtPostCode"
                  id="txtPostCode"
                  className="form-control mob-wd"
                  dataId="txtPostCode"
                  onBlur={checkValidation}
                  autoComplete="off"
                  validation={validation({
                    required: "Please Enter Postcode",
                    pattern: {
                      value: /^(?:[A-Za-z]+)(?:[A-Za-z0-9\s]*)$/,
                      message: "Please Enter a Valid Postcode",
                    },
                    minLength: {
                      value: 5,
                      message: "Please Enter a Valid Postcode",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtPostCode && validationMsg.txtPostCode.message
                  }
                />
              </div>
            </div>
            <div
              className="col-lg-12 col-12  p-0 text-right slide8_lkup"
              style={state.lookUp ? { display: "block" } : { display: "none" }}
            >
              <div style={{ width: "100%" }} className="main_1">
                <InputButton
                  style={{ float: "none" }}
                  name="next"
                  className="btn continue_btn next04"
                  value="Lookup Address"
                  btnType="button"
                  onClick={lookupValidation}
                />
              </div>
            </div>
            <div
              className={`form-group`}
              id="currentAddressCollapse"
              style={state.checkSelect ? { display: "block" } : { display: "none" }}
            >
              <div className="input-group post">
                <SelectBox
                  className="form-control"
                  OptionValue={state.getAddress}
                  name="address1"
                  id="address1"
                  onChange={getValue}
                  myRef={validation({ required: "Please select address" })}
                  validationMsg={
                    validationMsg.address1 && validationMsg.address1.message
                  }
                />
              </div>
            </div>
            <div
              className="col-12 text-center"
              style={
                state.getDetails.length === 0 ? { display: "block" } : { display: "none" }
              }
            >
              <a
                className="pfrp"
                id="manual-entry-btn"
                onClick={() => { dispatch({ type: 'showManualDiv', payload: { manual_address: "show", manual_click: 1 } }) }}
                children="[ Having Issues? Click Here to Enter Manually ]"
              ></a>
            </div>
            <div className={`slide8_add row ${state.showManualAddress}`}>
              <div className={`input-group post`}>
                <AddressTextField
                  type="text"
                  placeholder="Company"
                  name="txtHouseName"
                  id="txtHouseName"
                  className="form-control"
                  dataId="txtHouseName"
                  autoComplete="off"
                  validation={validation()}
                  validationMsg={
                    validationMsg.txtHouseName && validationMsg.txtHouseName.message
                  }
                  value={state.getDetails.Company}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
                <span id="addr_err" className="error_msg"></span>
              </div>
              <div className={`input-group post`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name="txtHouseNumber"
                  id="txtHouseNumber"
                  className="form-control  hero-input"
                  dataId="txtHouseNumber"
                  autoComplete="off"
                  validation={validation({
                    required: "Please Enter Address Line 1",
                  })}
                  validationMsg={
                    validationMsg.txtHouseNumber &&
                    validationMsg.txtHouseNumber.message
                  }
                  value={state.getDetails.Line1}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`input-group post`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name="txtAddress3"
                  id="txtAddress3"
                  className="form-control  hero-input"
                  dataId="txtAddress3"
                  autoComplete="off"
                  validation={validation()}
                  validationMsg=""
                  value={state.getDetails.Line2}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`input-group post`}>
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name="txtCounty"
                  id="txtCounty"
                  className="form-control  hero-input"
                  dataId="txtCounty"
                  autoComplete="off"
                  validation={validation()}
                  validationMsg=""
                  value={state.getDetails.County}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`input-group post`}>
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name="txtTown"
                  id="txtTown"
                  className="form-control  hero-input"
                  dataId="txtTown"
                  autoComplete="off"
                  validation={validation({ required: "Please Enter Town" })}
                  validationMsg={
                    validationMsg.txtTown && validationMsg.txtTown.message
                  }
                  value={state.getDetails.Town}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`input-group post`}>
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name="txtCountry"
                  id="txtCountry"
                  className="form-control  hero-input"
                  dataId="txtCountry"
                  autoComplete="off"
                  validation={validation({ required: "Please Enter Country" })}
                  validationMsg={
                    validationMsg.txtCountry && validationMsg.txtCountry.message
                  }
                  value={state.getDetails.Country}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
            </div>
            <div
              className="col-lg-12 col-12 text-center p-0"
              style={state.nextClick ? { display: "block" } : { display: "none" }}
            >
              <div style={{ width: "100%" }} className="main_1">
                <InputButton
                  name="user_dob"
                  className="btn continue_btn next04"
                  id=""
                  value="Continue >"
                  btnType="button"
                  onClick={basicDetailsSubmit}
                  style={{ float: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCode;
