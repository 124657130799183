import React, { useEffect, useState } from "react";
import {Multiselect} from "multiselect-react-dropdown";
import ProgressBar from "../../Layouts/BMC_V2​/ProgressBar";
import Button from "../../../UI/Button";
import { useLenderList } from "../../../../Hooks/UseLenderList";


const Lender = ({
    className, slideChange,setIsLenders,setOtherBankSelectedList,setLenderSelectedList}) => {
       
        const [lendersloop , setLendersloop] = useState({});
        const {lenderList}  = useLenderList();
        const [lenderSelectedValue, setLenderSelectedValue] = useState([]);
        const [otherBankSelectedList1, setOtherBankSelectedList1] = useState([]);
        const [lenderSelectedList, setLenderSelectedListNew] = useState([]);
        const [lenderValidationMsg, setLenderValidationMsg] = useState();
    
      
         
        const lenderValidation = (e) => {
            // if(lenderSelectedList.length > 0 || otherBankSelectedList.length > 0) {
            //     let localBankSelectionCount = otherBankSelectedList.length + lenderSelectedList.length;
            if(lenderSelectedList.length > 0 || otherBankSelectedList1.length > 0) {
                let localBankSelectionCount = otherBankSelectedList1.length + lenderSelectedList.length;
                // console.log(lenderSelectedList);
                // console.log(otherBankSelectedList1);
                // console.log(localBankSelectionCount);
                if ( localBankSelectionCount == 0) 
                      {
                        setLenderValidationMsg("Please Select Any Lender");
                      }
                      else
                      {
                        setLenderValidationMsg("");
                        // console.log('pp' +  e.target.name); 
                        slideChange(e)
                      }
                    }
                    else{
                        setLenderValidationMsg("Please Select Any Lender");
                    }
          };

     

            useEffect (()=>{
                (async ()=>{
                    const lender = await lenderList();
                    setLendersloop({bigfive:lender.data.response.bigfive,lender:lender.data.response.lender, otherBanks:lender.data.response.other_banks});
                })();
            },[]);

        const onSelectOtherBank = (selectedList) => {
            // if((lenderSelectedValue.length == 10) ||((lenderSelectedValue.length + otherBankSelectedList.length) > 10)){
            //     setBankValidationMsg("You could not select more than 10 banks/lenders");
            // }else{
            // setBankValidationMsg("");
            // }
            setIsLenders(true);
            setOtherBankSelectedList(selectedList);
            setOtherBankSelectedList1(selectedList);

        };

         const onSelectLoan  = (e)=>{
                let bankId = e.target.value;
                if(e.target.checked === true) {
                    setIsLenders(true);
                    lenderSelectedValue.push({id: +e.target.value})
                    setLenderValidationMsg(false);
                    setLenderSelectedList(lenderSelectedValue); 
                    setLenderSelectedListNew(lenderSelectedValue); 
                } else {
                    let otherFilterProducts = lenderSelectedValue.filter(el => {
                    return el.id != bankId;
                    });
                    
                    // if(otherFilterProducts.length <= 0 && setOtherBankSelectedList.length <= 0){
                        if(otherFilterProducts.length <= 0 && otherBankSelectedList1.length <= 0){
                        setIsLenders(false);
                        setLenderValidationMsg("Please Select Any Lender");
                    }
                    setLenderSelectedValue(otherFilterProducts);
                    setLenderSelectedList(otherFilterProducts); 
                    setLenderSelectedListNew(otherFilterProducts);
                }        
            };
             const onRemoveOtherBank = (selectedList) => {
                        // if((lenderSelectedValue.length + otherBankSelectedList.length) <= 10){
                        // setBankValidationMsg("");
                        // }
                        ///
                        // if(lenderSelectedValue.length <= 0 && setOtherBankSelectedList.length <= 0){
                            if(lenderSelectedValue.length <= 0 && otherBankSelectedList1.length <= 0){
                            setIsLenders(false);
                            setLenderValidationMsg("Please Select Any Lender");
                        }
                        setOtherBankSelectedList(selectedList);
                        setOtherBankSelectedList1(selectedList);
                    };

    

     let lenderOption1 ;
     if(lendersloop.bigfive){
        lenderOption1 = lendersloop.bigfive.map((lenderData, index) => {
            
            return (
                <>
                <div className="form-check" key={index}>
                    <input type="checkbox" className="form-check-input custom-checkbox " onChange={onSelectLoan} name="chkLenderName[]"  value={lenderData.id} id={`biglenders${index}`} />
                    <label htmlFor={`biglenders${index}`}  className="form-check-label">{lenderData.bank_name}</label>

                    </div>
                </>
            );
        });
    }
    


    // console.log(LenderList);
    return(
        <>
        <div id="slide1"
            className={className}>
            <ProgressBar value="0"/>
            <h4>Tell Us About Your Credit Providers, Banks and Lenders</h4>
            <p>Please select the lenders for us to investigate, if you can't see them on the list don't worry, just pop them in the box below</p>
             <div className="col-lg-12">
                    { lenderOption1 }
            </div>

          
               <div className="col-lg-12">
               <legend className></legend>
                    <div className="mb-3">
                        <Multiselect
                           className="lenderselect"
                            options={lendersloop.otherBanks} // Options to display in the dropdown
                            onSelect={onSelectOtherBank} // Function will trigger on select event
                            onRemove={onRemoveOtherBank} // Function will trigger on remove event
                            displayValue="bank_name" // Property name to display in the dropdown options
                            />
                    </div>
                     
                    {lenderValidationMsg ?
                      <span className={`error_msg`}>{lenderValidationMsg}</span> : ''
                    }
                 </div>
                   
                   
                   <div className="col-lg-12">
                        <div className="mb-3">
                            <Button
                                type="button"
                                className="custom-btn"
                                id="slide1"
                                buttonText="Next"
                                name="slide1"
                                onClick={lenderValidation}/>
                        </div>
                    </div>
           
        </div>

    </>
    );
};
 

export default Lender;
