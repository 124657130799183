import React from 'react';
import "../../../../assets/BMC_V1/css/BMC_V1.scss";
import "../../../../assets/BMC_V1/css/animate.css";
import Accordion from 'react-bootstrap/Accordion'

const FrequentQuestions = () => {

  const scrollTop = () => {
    window.scrollTo(0, 0);
  }; 
  
  return (
    <>
      <section className="accordion-section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 col-sm-12 col-12">
              <h3>FREQUENTLY ASKED QUESTIONS</h3>
              <Accordion className="accordion accordion-flush">

                <Accordion.Item eventKey="0" className="accordion-item">
                  <Accordion.Header className="accordion-header" >What is Junk Insurance?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>Junk Insurance refers to policies added to finance agreements that offered very poor or no value to the consumer. There have been many types of Junk Insurance sold by many different organisations. One of the worst types sold was CCI (Consumer Credit Insurance). The intention was to cover your minimum repayments if you could not meet them yourself due to accident, sickness or unemployment. It's called Junk Insurance because it was not fit for purpose and usually a waste of money. </p> 
                    <p>You can read about it in more detail <a href="https://www.boomerangclaims.com/junk-insurance" target="_blank">here.</a></p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className="accordion-item">
                  <Accordion.Header className="accordion-header" > How do I know if I had it?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>Good question! You may remember it being sold to you at the time. If you still have your paperwork, you may see that your bank or lender added it to the total amount you borrowed. However, in many cases, customers just weren't aware that it had been added on. Sometimes, they weren't told explicitly about it, and sometimes because banks and lenders hid it in the small print. At Boomerang Claims, we can easily find out if you were sold this product, even if you no longer have your paperwork.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="accordion-item">
                  <Accordion.Header className="accordion-header" >How do I know if I’m due a refund?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>If you did have this insurance, you would likely be due a refund. We will work with your bank or lender to find out if you were affected. If you were and, if you choose to use us to make your claim, we will ask you a few more questions which allows us to establish if you have a claim. We then submit the claim to the lender and deal with them for you.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3" className="accordion-item">
                  <Accordion.Header className="accordion-header" > How much could I get if I make a claim?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>That depends on several things. The type and amount of your original finance agreement, and the length of time you were paying for it, have a significant effect on how much you might get back. If you are affected, you could be owed anything from $20 to $20,000, or even more!</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4" className="accordion-item">
                  <Accordion.Header className="accordion-header" > How much do I have to pay to you?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>Firstly, we do not charge you anything to determine if your bank or lender sold you this insurance. We offer this part of our service completely free of charge. The only time we will charge you a fee is if we run the claim for you and we successfully get your money back. If we are unsuccessful, we do not charge you a cent. Where we are successful, we charge a maximum of 25% of the total amount you receive (including GST). However, in some instances, we do offer discounted rates.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5" className="accordion-item">
                  <Accordion.Header className="accordion-header" > Can I make a claim myself?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>Yes, absolutely. If you prefer, you can submit a claim yourself without any help from us, and you can refer a complaint to <a href="https://www.afca.org.au/" target="_blank">AFCA</a> free of charge without any obligation to pay a fee. If you need our help in contacting them or some advice on how to start your complaint with them then all you need to do is <a href="https://www.boomerangclaims.com/contact" target="_blank">contact us</a> and we’re happy to help you. However, using us will save you a lot of time and make the whole process much easier for you, as well as meaning you have the experts on your side to make sure what your bank or lender offers you is fair.</p>
                  </Accordion.Body>
                </Accordion.Item> 

                <Accordion.Item eventKey="6" className="accordion-item">
                  <Accordion.Header className="accordion-header" > How long does the process take? </Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>That depends on which lender and what types of credit you had CCI attached to. On average, we expect a claim to take between 6 and 12 weeks from beginning to end.</p>
                  </Accordion.Body>
                </Accordion.Item> 

                <Accordion.Item eventKey="7" className="accordion-item">
                  <Accordion.Header className="accordion-header" > Will the money be sent straight to me if I am successful?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>If you have any arrears on your finance, then the lender may offset the money you owe to them against any redress they owe to you. If that happens, you are still liable to pay our fee. However, the lender will usually send your money to your nominated bank account or send it to us. In those instances will deduct our fee and send your money directly to you.</p>
                  </Accordion.Body>
                </Accordion.Item> 

                <Accordion.Item eventKey="8" className="accordion-item">
                  <Accordion.Header className="accordion-header" > What if I don’t have any paperwork?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>That's ok. Whilst it can help speed up the process if you have your documents, it doesn't matter if you no longer have them or you can't remember any details. That's one of the biggest reasons to let us help you. As long as you tell us which bank or lender your agreement was with, we have processes that allow us to find out that information for you. </p>
                  </Accordion.Body>
                </Accordion.Item> 
                
                <Accordion.Item eventKey="9" className="accordion-item">
                  <Accordion.Header className="accordion-header" > I didn’t have this insurance.  I would know if I did!</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>It may seem strange to think you wouldn’t know if a bank or lender sold a Junk Insurance policy to you, but you would be surprised. Millions of people were totally unaware they were paying for this product. This is one of the ways that it was missold. It’s nothing to feel embarrassed about as it happened to so many of us.</p>
                  </Accordion.Body>
                </Accordion.Item> 
                
                <Accordion.Item eventKey="10" className="accordion-item">
                  <Accordion.Header className="accordion-header" > Who are Boomerang Claims and what experience do you have?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>We are specialists in claiming back millions of dollars in refunds for Australians who deserve to get their money back. We have been in the industry for the last 15 years and first cut our teeth in Britain, where we helped get back millions of pounds for the public. Many of these banks are global offenders, so we know how they work. If we cannot get a refund for you, you will not pay us anything as we operate a ‘no win no fee’ model.</p>
                  </Accordion.Body>
                </Accordion.Item> 
                
                <Accordion.Item eventKey="11" className="accordion-item">
                  <Accordion.Header className="accordion-header" > How will Boomerang Claims keep my information safe?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>We take your Data Security very seriously. Sometimes we might ask you for information or to send us a copy of your ID. All transmissions to and from our system are encrypted at 256-bit and sent through TLS 1.2, adhering to the FIPS 140-2 certification standard. This means that all of our customer data and files are hosted in a secure physical location in multiple data centres of Amazon Web Services. Any information uploaded to our system is encrypted and housed safely on Amazon Global servers  click <a href="https://aws.amazon.com/compliance/" target="_blank">here</a> for more information on your data security. Remember, we will ONLY use the information you provide us with to help reclaim your money and we will NEVER share or sell your data – click <a href="/privacy" target="_blank"> here</a> to see our <a href="/privacy" target="_blank">Privacy Policy.</a></p>
                  </Accordion.Body>
                </Accordion.Item> 
                
                <Accordion.Item eventKey="12" className="accordion-item">
                  <Accordion.Header className="accordion-header" > How do I start the ball rolling?</Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    <p>All you have to do is <a href="" onClick={scrollTop}>fill in a simple form</a> to get cracking with us, we will take care of the rest!</p>
                  </Accordion.Body>
                </Accordion.Item>   
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FrequentQuestions;