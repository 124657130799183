//import React, { Fragment} from 'react';
import React, { Fragment, useState, useEffect, useReducer, useContext } from 'react';
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import UDStyle from '../../../assets/UserDocument/css/UserDocument.module.scss';
import AnimateStyle from '../../../assets/UserDocument/css/Animated.module.css';
import RadioButton from '../../UI/RadioButton';
import Button from "../../UI/Button";
import { useUserDocument, useSkipUserDocument } from "../../../Hooks/useUserDocument";
import Accordion from 'react-bootstrap/Accordion'

import axios from 'axios';
require('dotenv').config();
const base_api_url = process.env.REACT_APP_API_URL;
const api_auth_token = process.env.REACT_APP_API_AUTH_TOKEN;

const initialState = {
	showSlide1: "show",
	showSlide2: "hide",
}

const userfile = {
	preview: undefined,
	title: undefined
}
const FormReducer = (state, action) => {
	switch (action.type) {
		case 'showSlide': {
			if (action.payload.clickedSlide.slide == 'slide1') {
				return {
					...state,
					showSlide1: "hide",
					showSlide2: "show"
				}
			} else if (action.payload.clickedSlide.slide == 'retake') {
				return {
					...state,
					showSlide1: "show",
					showSlide2: "hide"
				}
			} else {
				return {
					...state
				}
			}
		}
	}
}

const UserDocument = () => {
	const history = useHistory();
	const { visitorParameters } = useContext(VisitorParamsContext);
	const query_string = localStorage.getItem('queryString');
	const formParameters = JSON.parse(localStorage.getItem('formData'));
    const split_name = localStorage.getItem('split_name');
	const { saveUserDocument, isLoading } = useUserDocument();
	const { skipUserDocument, isSkipLoading } = useSkipUserDocument();
	const [clickedSlide, setClickedSlide] = useState([]);
	const [state, dispatch] = useReducer(FormReducer, initialState);
	const [selectedDocType, setSelectedDocType] = useState();
	const [selectedFile, setSelectedFile] = useState();
	const [selectedFileType, setSelectedFileType] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isInValidfFile, setIsInValidfFile] = useState(false);
	const [documentModalShow, setDocumentModalShow] = useState(false);
	const [nationalIDCard, setNationalIDCard] = useState(false);
	const [driversLicense, setDriversLicense] = useState(false);
	const [passport, setPassport] = useState(false);
	const [base64Image, setBase64Image] = useState();
	const [errorMessage, setErrorMessage] = useState();

	const slideChange = (e, slide) => {
		setClickedSlide({ slide: 'slide1' });
	};

	useEffect(() => {
		if (clickedSlide) {
			dispatch({ type: 'showSlide', payload: { clickedSlide } });
		}
	}, [clickedSlide]);

	const selectDocId = (e) => {
		retakeClick();
		// setSelectedDocType(e.target.value);
	};

	const changeCameraHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		let imageFile = event.target.files[0];
		if (imageFile) {
			setIsFilePicked(true);
			let extension = event.target.files[0].type;
			let imgsize = event.target.files[0].size;
			let allowedExtensions = ['image/jpeg', 'image/png'];
			if (allowedExtensions.indexOf(extension) === -1) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'The uploaded ID type should be an image in jpeg,png or jpg' });
			} else if (imgsize > 1024 * 1024 * 20) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'Please choose a file less than 20mb' });
			} else {
				setSelectedFileType(event.target.name);
				setIsInValidfFile(false);
			}

			setNationalIDCard(false);
			setDriversLicense(false);
			setPassport(false);

			if ('nationalIDCard_doc_cam' == event.target.name) {
				setNationalIDCard(true);
			} else if ('driversLicense_doc_cam' == event.target.name) {
				setDriversLicense(true);
			} else if ('passport_doc_cam' == event.target.name) {
				setPassport(true);
			}

			userfile.preview = URL.createObjectURL(imageFile);
			userfile.title = imageFile.name;

			if (imageFile) {
				let reader = new FileReader();
				reader.onload = _handleReaderLoaded.bind(this);
				//reader.readAsBinaryString(imageFile);
				reader.readAsDataURL(imageFile);
			}
		} else {
			setIsInValidfFile(true);
			setIsFilePicked(false);
			userfile.preview = undefined;
			userfile.title = undefined;
		}
	};

	const changeFilesHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		let imageFile = event.target.files[0];
		if (imageFile) {
			setIsFilePicked(true);
			let extension = event.target.files[0].type;
			let imgsize = event.target.files[0].size;
			console.log(imgsize);
			console.log(1024 * 1024 * 10);
			let allowedExtensions = ['image/jpeg', 'image/png'];
			if (allowedExtensions.indexOf(extension) === -1) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'The uploaded ID type should be an image in jpeg,png or jpg' });
			} else if (imgsize > 1024 * 1024 * 20) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'Please choose a file less than 20mb' });
			} else {
				setSelectedFileType(event.target.name);
				setIsInValidfFile(false);
				//_handleFileRead.bind(event.target.files[0]);
			}

			setNationalIDCard(false);
			setDriversLicense(false);
			setPassport(false);

			if ('nationalIDCard_doc' == event.target.name) {
				setNationalIDCard(true);
			} else if ('driversLicense_doc' == event.target.name) {
				setDriversLicense(true);
			} else if ('passport_doc' == event.target.name) {
				setPassport(true);
			}
			userfile.preview = URL.createObjectURL(imageFile);
			userfile.title = imageFile.name;

			if (imageFile) {
				let reader = new FileReader();
				reader.onload = _handleReaderLoaded.bind(this);
				//reader.readAsBinaryString(imageFile);
				reader.readAsDataURL(imageFile);
			}
		} else {
			setIsInValidfFile(true);
			setIsFilePicked(false);
			userfile.preview = undefined;
			userfile.title = undefined;
		}
	};

	const handleClick = () => {
		try {
			if (isFilePicked) {
				setDocumentModalShow(true);
				userfile.preview = URL.createObjectURL(selectedFile);
				userfile.title = selectedFile.name;

				if (selectedFile) {
					//_handleFileRead.bind(this);
					let reader = new FileReader();
					reader.onload = _handleReaderLoaded.bind(this);
					//reader.readAsBinaryString(selectedFile);
					reader.readAsDataURL(selectedFile);
				}
			} else {
				userfile.preview = undefined;
				userfile.title = undefined;
			}
		} catch (e) {
			console.warn(e);
		}
	};

	const _handleReaderLoaded = (readerEvt) => {
		let binaryString = readerEvt.target.result;
		//binaryString = binaryString.replace(";base64", `;name=${selectedFile.name};base64`);
		// setBase64Image({
		//   //base64TextString : btoa(binaryString)
		//   base64TextString : btoa(encodeURIComponent(binaryString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
		//     return String.fromCharCode('0x' + p1);
		//   }))
		// });
		setBase64Image({
			base64TextString: binaryString
		});
	};

	const retakeClick = () => {
		setDocumentModalShow(false);

		setNationalIDCard(false);
		setDriversLicense(false);
		setPassport(false);

		setClickedSlide({ slide: 'retake' });
		setSelectedFile(null);
		setIsFilePicked(false);
	}

	const savedocument = async () => {
		try {
			//console.log(visitorParameters);
			//console.log(visitorParameters.visitor_parameters);
			//console.log(visitorParameters.data);
			//console.log(base64Image.base64TextString);
			if (
				visitorParameters.data !== null ||
				visitorParameters.data !== undefined
			) {

				let formdata = {
					"imageFile": base64Image.base64TextString,
					"captureType": selectedFileType.substring(selectedFileType.indexOf("doc")),
					"documentType": selectedFileType.substr(0, selectedFileType.indexOf('_'))
				}
				const response = saveUserDocument(
					formdata,
					visitorParameters,
					formParameters,
					query_string
				);

				if(split_name == 'BMC_V2') {
					history.push(
						"/thankyouV2?uuid=" + visitorParameters.visitor_parameters.uuid
					);
				}
               else {

				history.push(
					"/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
				);
			}
			}
		} catch (e) {
			console.warn('error');
			console.warn(e);
		}
	};

	const skipID = async () => {
		console.log("Skipped");
		try {
			if (
				visitorParameters.data !== null ||
				visitorParameters.data !== undefined
			) {

				let formdata = {
					"skipedDocument": true
				}
				const response = skipUserDocument(
					formdata,
					visitorParameters,
					query_string
				);

				if(split_name == 'BMC_V2') {
					history.push(
						"/thankyouV2?uuid=" + visitorParameters.visitor_parameters.uuid
					);
				}
               else {

				history.push(
					"/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
				);
			}
			}
		} catch (e) {
			console.warn(e);
		}
	};

	return (
		<Fragment>
			<div className={`${UDStyle['uploadpage']}`}>
				<header>
					<div className="container">
						<div className="row">
							<div className={`col-xl-12 col-12 col-md-12 col-lg-12  logo text-center`}>
								<img src="assets/UserDocument/img/logo.png" alt="" />
							</div>
						</div>
					</div>
				</header>

				<section className={`${UDStyle['content']}`}>
					<div className={`container text-center`} >
						<div className="row">
							<div className={`${UDStyle['intro']} col-xl-12 col-lg-12 col-12`}>
								<img src="assets/UserDocument/img/top-img.jpg" alt="" />
								<h2>Verify Your identity</h2>
								<h3>You must provide 1 proof(s) of address to proceed.</h3>
								<p>Tip - Please provide a photo of one of the following: <strong>National ID Card, Driver's License,
									Passport</strong></p>

							</div>
							<form name="docs_form" id="user_form" method="POST" encType="multipart/form-data" >
								<div className={`${UDStyle['uploading-part']} col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-12`}>
									<Accordion className={`${UDStyle['accordion']} accordion accordion-flush`} id="faqs">
										<Accordion.Item eventKey="1" className={`${UDStyle['accordion-item']} accordion-item`} onClick={selectDocId} >
											<Accordion.Button className={`${UDStyle['accordion-button']} accordion-button collapsed idone`}>National ID Card</Accordion.Button>
											<Accordion.Body>

												<div className={`${UDStyle['upload-area']} col-lg-12 col-12`} >

													{(nationalIDCard && !isInValidfFile)
														? <img src={userfile.preview} alt={userfile.title} />
														: <img src="assets/UserDocument/img/upload-demo.jpg" alt="" />
													}

													<div className="col-lg-12 col-12">
														{!nationalIDCard && (
															<div>
																<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']}`} >
																	<img src="assets/UserDocument/img/browse.png" alt="" /> Browse from Your Device
																	<input type="file" accept="image/*" id="idTypeFile" name="nationalIDCard_doc"
																		onChange={changeFilesHandler}
																		onClick={e => (e.target.value = null)}
																	/>
																</span>
																<a className={`${UDStyle['button-tab']}`}><img src="assets/UserDocument/img/camera.png" alt="" /> Take picture of Your Id <input type="file" accept="image/*" capture="camera" id="idTypeFileCamera" name="nationalIDCard_doc_cam"

																	onChange={changeCameraHandler}
																	onClick={e => (e.target.value = null)}
																/></a>
															</div>
														)}
														{nationalIDCard && (
															<div>

																{isInValidfFile &&
																	<p>{errorMessage.txt}</p>
																}
																{!isInValidfFile &&
																	<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']}`} >
																		Submit
																		<input type="button" value="Submit" onClick={savedocument} />
																	</span>

																}
																<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']} ${UDStyle['red']}`} >
																	Retake Photo
																	<input type="button" value="Retake Photo" onClick={retakeClick} />
																</span>
																{isLoading &&
																	<p>Please Wait...</p>
																}
															</div>
														)}

													</div>
												</div>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="2" className={`${UDStyle['accordion-item']} accordion-item`} onClick={selectDocId} >
											<Accordion.Button className={`${UDStyle['accordion-button']} accordion-button collapsed idone`}>Driver's License</Accordion.Button>
											<Accordion.Body>

												<div className={`${UDStyle['upload-area']} col-lg-12 col-12`} >

													{(driversLicense && !isInValidfFile)
														? <img src={userfile.preview} alt={userfile.title} />
														: <img src="assets/UserDocument/img/upload-demo.jpg" alt="" />
													}

													<div className="col-lg-12 col-12">
														{!driversLicense && (
															<div>
																<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']}`} >
																	<img src="assets/UserDocument/img/browse.png" alt="" /> Browse from Your Device
																	<input type="file" accept="image/*" id="idTypeFile" name="driversLicense_doc"
																		onChange={changeFilesHandler}
																		onClick={e => (e.target.value = null)}
																	/>
																</span>
																<a className={`${UDStyle['button-tab']}`}><img src="assets/UserDocument/img/camera.png" alt="" /> Take picture of Your Id<input type="file" accept="image/*" capture="camera" name="driversLicense_doc_cam"

																	onChange={changeCameraHandler}
																	onClick={e => (e.target.value = null)}
																/></a>
															</div>
														)}
														{driversLicense && (
															<div>

																{isInValidfFile &&
																	<p>{errorMessage.txt}</p>
																}
																{!isInValidfFile &&
																	<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']}`} >
																		Submit
																		<input type="button" value="Submit" onClick={savedocument} />
																	</span>

																}
																<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']} ${UDStyle['red']}`} >
																	Retake Photo
																	<input type="button" value="Retake Photo" onClick={retakeClick} />
																</span>
																{isLoading &&
																	<p>Please Wait...</p>
																}
															</div>
														)}

													</div>
												</div>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="3" className={`${UDStyle['accordion-item']} accordion-item`} onClick={selectDocId} >
											<Accordion.Button className={`${UDStyle['accordion-button']} accordion-button collapsed idone`}>Passport</Accordion.Button>
											<Accordion.Body>

												<div className={`${UDStyle['upload-area']} col-lg-12 col-12`} >

													{(passport && !isInValidfFile)
														? <img src={userfile.preview} alt={userfile.title} />
														: <img src="assets/UserDocument/img/upload-demo.jpg" alt="" />
													}

													<div className="col-lg-12 col-12">
														{!passport && (
															<div>
																<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']}`} >
																	<img src="assets/UserDocument/img/browse.png" alt="" /> Browse from Your Device
																	<input type="file" accept="image/*" id="idTypeFile" name="passport_doc"
																		onChange={changeFilesHandler}
																		onClick={e => (e.target.value = null)}
																	/>
																</span>
																<a className={`${UDStyle['button-tab']}`}><img src="assets/UserDocument/img/camera.png" alt="" /> Take picture of Your Id<input type="file" accept="image/*" capture="camera" id="idTypeFileCamera" name="passport_doc_cam"

																	onChange={changeCameraHandler}
																	onClick={e => (e.target.value = null)}
																/></a>
															</div>
														)}
														{passport && (
															<div>

																{isInValidfFile &&
																	<p>{errorMessage.txt}</p>
																}
																{!isInValidfFile &&
																	<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']}`} >
																		Submit
																		<input type="button" value="Submit" onClick={savedocument} />
																	</span>

																}
																<span className={`${UDStyle['button-tab']} ${UDStyle['browsebut']} ${UDStyle['red']}`} >
																	Retake Photo
																	<input type="button" value="Retake Photo" onClick={retakeClick} />
																</span>
																{isLoading &&
																	<p>Please Wait...</p>
																}
															</div>
														)}

													</div>
												</div>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>


					

								</div>
							</form>
							<div className={`${UDStyle['doc-op']}`}>
								<ul>
									<li> Document should be Clear &amp; Legible</li>
									<li> Entire Document should be Captured</li>
								</ul>
							</div>
							<div className={`form-group ${UDStyle['skip-doc-outer']}`}>
                    <Button
                      type="button"
                      className={`btn-clear ${UDStyle['skip-doc-btn']}`}
                      id="rdoDocumentTypeSkipg"
					  onClick={skipID}
                      buttonText="SKIP - Don't Have to Hand? Provide Later."
                    />
                  </div>

						</div>
					</div>
				</section>
			</div>
		</Fragment>
	)
}

export default UserDocument;