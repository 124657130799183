import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <header>
        <div className="col-lg-12 col-12 header_caption fixed-top d-lg-block d-none">
          <h6>
            Looking for some good news? Boomerang Claims can help you get a refund on your junk insurance today!
          </h6>
        </div>
		  </header>
    </>
  );
};

export default Header;