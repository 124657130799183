import React, { useContext, useState, useReducer, useRef } from "react";
import ProgressBar from "../../Layouts/BMC_V2​/ProgressBar";
import Button from "../../../UI/Button";
import TextField from "../../../UI/TextField";
import Anchor from "../../../UI/Anchor";
import SelectBox from "../../../UI/SelectBox";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import AddressTextField from "../../../UI/AddressTextField";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 1
}
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case 'validationSucess': {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
        clickManualLink: action.payload.manual_click

      }
    }
    case 'getExtraAddressDetails': {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
        clickManualLink: action.payload.manual_click
      }
    }
    case 'showManualDiv': {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click
      }
    }
    default:
      return state;
  }
}

const Postcode = ({
  className,
  slideChange,
  clearErrors,
  setError,
  validation,
  validationMsg,
  trigger,
  splitForm
}) => {
  

  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState('0');

  const basicDetailsSubmit = async (e) => {
  const txtPostCode = await trigger("txtPostCode");
  const form = splitForm.current;
    
  let errorFlag = 0;
    var manual_txtAddress1;
    var  manual_txtAddress2;
    var manual_txtTown;
    var txtAddress;
    if (txtPostCode) {
      manual_txtAddress1 = await trigger("txtAddress1");
      txtAddress = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtAddress1) {
      manual_txtAddress2 = await trigger("txtAddress2");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtAddress1 ||
      !manual_txtAddress2
    ) {
      errorFlag = 1;
    }
    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
        if (errorFlag === 0) {
          slideChange(e);
        } else {
          return false;
        }
  }
  };

  const checkValidation = async (e) => {
    e.preventDefault();
    const txtPostCode = await trigger("txtPostCode");

    if (txtPostCode) {
      state.getDetails="";
      const getValidation = await getPostcodeAddress(e.target.value);
      if (getValidation.data.status === '0' || getValidation.data == "Please Enter Valid Address Keyword!") {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
      if (getValidation.data != "Please Enter Valid Address Keyword!") {
        dispatch({
          type: 'validationSucess',
          payload: {
            lookup: false,
            next_click: true,
            postcode: e.target.value,
            get_address: getValidation.data,
            check_select: true,
            manual_address: "hide",
          }
        });
      }
    }
  };

  const checkPostCodeEntry = () => {
    const company = document.getElementById("txtHouseName").value;
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress3").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (postCodeAddress.length === 0 ||
      postCodeAddress.Company !== company ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town) {
      setPostCodeEntry('1');
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");

  }
  
  const getValue = async (e) => {
    e.preventDefault();
    clearErrors(["txtAddress1", "txtAddress2", "txtTown"]);
    const getData = await getSplitPostcodeAddress(e.target.value, state.getPostcode);
    dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show", manual_click: 0 } });
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtAddress3"
        inputId="txtAddress3"
        inputValue={state.getDetails !== "#" ? state.getDetails.Line3 : ""}
      />
      <HiddenField
        name="txtAddress4"
        inputId="txtAddress4"
        inputValue={state.getDetails !== "#" ? state.getDetails.Line4 : ""}
      />
      <HiddenField
        name="txtLocality"
        inputId="txtLocality"
        inputValue={state.getDetails !== "#" ? state.getDetails.Locality : ""}
      />
      <HiddenField
        name="txtCounty"
        inputId="txtCounty"
        inputValue={state.getDetails !== "#" ? state.getDetails.County : ""}
      />
      <HiddenField
        name="txtCountry"
        inputId="txtCountry"
        inputValue={state.getDetails !== "#" ? state.getDetails.Country : ""}
      />
      <HiddenField
        name="txtDistrict"
        inputId="txtDistrict"
        inputValue={state.getDetails !== "#" ? state.getDetails.District : ""}
      />
      <HiddenField
        name="hdPostcode"
        inputId="hdPostcode"
        inputValue={state.getDetails !== "#" ? state.getDetails.Postcode : ""}
      />
    </>
  );
  return(
    <div id="slide3" className={className}>
      <ProgressBar value="40" />
      <HiddenField
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />
        <div className="mb-3">
          <h4>Enter Your Postcode</h4>
          {/* <input type="text" className="form-control" id placeholder="Enter Postcode" /> */}
              <AddressTextField
                type="text"
                placeholder="Enter Postcode"
                name="txtPostCode"
                className="form-control"
                dataId="txtPostCode"
                onBlur={checkValidation}
                onChange={() => {
                  dispatch({
                    type: 'validationSucess',
                    payload: {
                      lookup: true,
                      next_click: false,
                      check_select: false,
                      manual_address: "hide",
                      get_address: [],
                      manual_click: 1
                    }
                  })
                }}
                validation={validation({
                    required: "Please Enter Postcode",
                    // pattern: {
                    //   value: /^(?:[A-Za-z]+)(?:[A-Za-z0-9\s]*)$/,
                    //   message: "Please Enter a Valid Postcode",
                    // },
                    minLength: {
                      value: 1,
                      message: "Postcode requires minimum 1 character",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtPostCode && validationMsg.txtPostCode.message
                  }
              />
         </div>
               <div className="mb-3"  style={state.lookUp ? { display: "block" } : { display: "none" }}>
                    <Button
                      name="postcodevalid"
                      className="btn-next"
                      value="Lookup Address1"
                      type="button"
                      buttonText="Lookup Address"
                      onClick={lookupValidation}
                    />
                </div>
                

         <div id="address-col">
         <div className="" id="currentAddressCollapse"
          style={state.checkSelect ? { display: "block" } : { display: "none" }} >
            
              <div className="mb-3" >
                      <SelectBox
                        className="form-select"
                        OptionValue={state.getAddress}
                        name="address1"
                        onChange={getValue}
                        myRef={validation({ required: "Please select address" })}
                        validationMsg={
                          validationMsg.address1 && validationMsg.address1.message
                        }
                      />
              </div>
            <div className="mb-3" style={{display: state.getDetails.Line1 ? "block" : "none"}}  id="strdivid" >
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  readonly="readonly"
                  name="txtAddress1"
                  className="form-control"
                  dataId="txtAddress1"
                  autoComplete="off"
                  validation={validation({
                    // required: "Please Enter Address Line 1",
                  })}
                  validationMsg={
                    validationMsg.txtAddress1 &&
                    validationMsg.txtAddress1.message
                  }
                  value={state.getDetails.Line1}
                />
            </div>
              {/* txtAddress2 */}
                <div className="mb-3"style={{display: state.getDetails.Line2 ? "block" : "none"}} id="housedivid">
                  <AddressTextField
                      type="text"
                      placeholder="Address Line 2"
                      readonly="readonly"
                      name="txtAddress2"
                      className="form-control hero-input"
                      dataId="txtAddress2"
                      autoComplete="off"
                      validation={validation()}
                      validationMsg={
                        validationMsg.txtAddress2 && validationMsg.txtAddress2.message
                      }
                      value={state.getDetails.Line2}
                    />
                    {/* <div className="error_msg " /> */}
                </div>
                  <div className="mb-3" style={{display: state.getDetails.Town ? "block" : "none"}} id="housedivid">
                      <AddressTextField
                      type="text"
                      placeholder="Address Line 3"
                      readonly="readonly"
                      name="txtTown"
                      className="form-control"
                      dataId="txtTown"
                      autoComplete="off"
                      keypress={e => (/[^A-Za-z ]+$/.test(e.key)) && e.preventDefault()}
                      validation={validation()}
                      validationMsg={
                        validationMsg.txtTown && validationMsg.txtTown.message
                      }
                      value={state.getDetails.Town}
                    />
                  
       </div>

            <div className="mb-3" id="addressnext">
                <Button name="slide3"
                type="button"
                className="btn-next"
                id="slide3"
                buttonText="Next"
                onClick={basicDetailsSubmit}/>
            </div>
     
            {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
    </div>
  </div>
</div>


    );
};

export default Postcode;