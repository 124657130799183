import React, { useState } from "react";
import ProgressBar from "../../Layouts/MCS_V1/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const BasicQuestionnaireOne = ({
  className,
  slideChange,
  textHeading,
  validation
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "1") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value === "2") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    }
  };
  return (
    <>
      <div id="slide1" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="0" />
        <h3>{textHeading}</h3>
        <div className="form-group">
          <RadioButton
            value="1"
            name="question_1"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but next01 ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="2"
            name="question_1"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but next01 ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;
