import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <header>
        <div className="container">
          <div className="col-lg-8 offset-lg-2 col-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left logo">
                <a href="#"><img src="/assets/MCS_V1/img/logo.png" alt="" /></a>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-lg-block d-md-block d-sm-block d-none">
                <p className="mb-0 mt-2">Speak with a Licensed Agent</p>
                <p className="mb-0 ph_num">(833) 888-8888 (TTY:111)</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-lg-none d-md-none d-sm-none d-block">
                <a href="#" className="btn hder_btn">
                  <img src="/assets/MCS_V1/img/Call_white.png" />
                            Call an Agent
                         </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;