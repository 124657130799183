import React,{useEffect, useState, useRef, useReducer, useContext} from "react";
import Lender from "../Includes/Layouts/BMC_V2​/Lender";
import BasicDetails from "../Includes/Layouts/BMC_V2​/BasicDetails";
import Postcode from "../Includes/Layouts/BMC_V2​/Postcode";
import Dob from "../Includes/Layouts/BMC_V2​/Dob";
import ContactDetails from "../Includes/Layouts/BMC_V2​/ContactDetails";
import Signature from "../Includes/Layouts/BMC_V2​/Signature";
import { useForm } from "react-hook-form";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import {useDataIngestion} from "../../Hooks/useDataIngestion";
import { userLender } from '../../Hooks/userLender';
import { useHistory } from "react-router-dom";


const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
  }

  const FormReducer = (state, action) => {
    switch (action.type) {
      case 'showSlide': {
        if (action.payload.clickedSlide.slide == 'slide1') {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show"
          }
        } else if (action.payload.clickedSlide.slide == 'slide2') {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show"
          }
        } else if (action.payload.clickedSlide.slide == 'slide3') {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show"
          }
        } else if (action.payload.clickedSlide.slide == 'slide4') {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show"
          }
        }
        else if (action.payload.clickedSlide.slide == 'slide5') {
            return {
              ...state,
              showSlide5: "hide",
              showSlide6: "show"
            }
          }
          // else if (action.payload.clickedSlide.slide == 'slide6') {
          //   return {
          //     ...state,
          //     showSlide5: "hide",
          //     showSlide6: "show"
          //   }
          // }
        else {
          return {
            ...state
          }
        }
      }
     
    }
  }

const FormBMC_V2 =()=>{
  const [isLenders, setIsLenders] = useState(false);
  const [lenderValidationMsg, setLenderValidationMsg] = useState()
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const splitForm = useRef(null);
  const {visitorParameters } = useContext(VisitorParamsContext);
  const {queryString} = useContext(QueryStringContext);
  const {saveDataIngestion} = useDataIngestion();
  const [isSignValid, setIsSignValid] = useState(false);
  const field = "pid";
  const history = useHistory();
  const {saveUserLender} = userLender();
  const [signPad, setSignPad] = useState('');
  const [lenderSelectedList, setLenderSelectedList] = useState([]);
  const [otherBankSelectedList, setOtherBankSelectedList] = useState([]);
  const [userSingnature, setSignature] = useState('');
  const slideChange = (e) => {
    window.scrollTo(0, 0);
    setClickedSlide({ slide: e.target.name });
  };
  useEffect(() => {
    if (clickedSlide) {
     dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (signPad != '') {
      setSignPad(signPad);
      // SetModalClass('show');
      formSubmit();
    }

  }, [signPad]);

      const {
        register,
        errors,
        trigger,
        setError,
        clearErrors,
        getValues,
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
      });

      const formSubmit = async () => {
        let errorFlag = 0;
        const values = getValues();
        const form = splitForm.current;
        const formData = values;
        if (errorFlag == 0) {
        let bankIds = lenderSelectedList.map((a) => a.id);
          let otherBankIds = otherBankSelectedList.map((a) => a.id);
          formData.page_name = 'BMC_V2';
          formData.lenders = bankIds;
          formData.otherBankList = otherBankIds;
          formData.signature = signPad;

          if (visitorParameters.data !== null || visitorParameters.data !== undefined) {
            localStorage.setItem("formData", JSON.stringify(formData));
            localStorage.setItem("queryString", queryString);
            localStorage.setItem('first_name', formData.txtFName);

            if (formData.txtAddress !== "") 
                {
                    const txtAddress3 = form["txtAddress3"].value;
                    const txtCountry = form["txtCountry"].value;
                    const txtCounty = form["txtCounty"].value;
                    const txtAddress4 = form["txtAddress4"].value;
                    const txtLocality = form["txtLocality"].value;
                    const txtDistrict = form["txtDistrict"].value;
                    const hdPostcode = form["hdPostcode"].value;

                    formData.txtAddress3 = txtAddress3;
                    formData.txtAddress4 = txtAddress4;
                    formData.txtLocality = txtLocality;
                    formData.txtDistrict = txtDistrict;
                    formData.txtCounty = txtCounty;
                    formData.txtCountry = txtCountry;
                    formData.hdPostcode = hdPostcode;
                    //console.log(values);
                }
                //console.log(visitorParameters);
                const formSUbmitResult = await saveDataIngestion(
                    visitorParameters.visitor_parameters,
                    formData,
                    'split_form_submit',
                    'BMC_V2',
                    queryString,
                    visitorParameters.data
                );

                const saveLender = await saveUserLender(
                  visitorParameters.visitor_parameters.uuid,
                  formData,
                  "lender_store",
                  visitorParameters.visitor_parameters
                );
                if (formSUbmitResult.data.status === "Success") {
                   
                  const timer = setTimeout(() => {
                    history.push(
                      "/id-upload?uuid=" + visitorParameters.visitor_parameters.uuid
                    );
                  }, 3500);
                  
              }
            }
        }
    };

  
  return(
      <>
      <div className="col-xl-6 col-12 col-md-12 col-lg-6 form-part">
          <div className="col-12 form-div animated bounceIn">
            <div className="col-lg-8 offset-lg-2">
              {/* <form id="customForm"  autoComplete="off"> */}
            <form  ref={splitForm}  name="split_form" id="customForm" method="POST" autoComplete="off">
                <h3>Register Your Refund Now</h3>
          <Lender
            className={`${state.showSlide1}`}
            slideChange={slideChange}
            setIsLenders={setIsLenders}
            setLenderValidationMsg={setLenderValidationMsg}
            setLenderSelectedList ={setLenderSelectedList}
            setOtherBankSelectedList={setOtherBankSelectedList}
          />

          <BasicDetails
            className={`animated fadeInRight  ${state.showSlide2}`}
            slideChange={slideChange}
            setError={setError}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            getValues={getValues}
            clearErrors={clearErrors} 
          />

          <Postcode
            className={`animated fadeInRight ${state.showSlide3}`}
            slideChange={slideChange}
            clearErrors={clearErrors} 
            setError={setError}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            splitForm={splitForm}
          />
          <Dob
            className={`animated fadeInRight ${state.showSlide4}`}
            slideChange={slideChange}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            splitForm={splitForm}
            setError={setError}
            getValues={getValues}
            clearErrors={clearErrors}
          

          />
          <ContactDetails
              className={`animated fadeInRight ${state.showSlide5}`}
              slideChange={slideChange}
              validation={register}
              validationMsg={errors}
              setError={setError}
              getValues={getValues}
              trigger={trigger}
          />

          <Signature
            className={`animated fadeInRight ${state.showSlide6}`}
            slideChange={slideChange}
            validation={register}
            validationMsg={errors}
            getValues={getValues}
            formSubmit={formSubmit}
            setSignPad={setSignPad}
          />
          <div className="col-12 afca text-center">
                  <img src="assets/BMC_V2/img/afca.png" alt="" />
                  <p><strong> Boomerang Claims</strong> are proud members of the Australian Financial Complaints Authority. Our membership number is 89098</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
  );
};
export default FormBMC_V2;