import React from 'react';
import TC from "../../../../assets/Pdf/Terms.pdf";
import Privacy from "../../../../assets/Pdf/Privacy.pdf";
import Cookie from "../../../../assets/Pdf/Cookies.pdf";
const AdvFooter = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p>Copyright © 2022 medicare-saver.com - All rights reserved</p>
              <div className="copyright">
                <ul>
                  <li><a href={`${TC}`} rel="noreferrer" target="_blank"> Terms and Conditions</a></li>
                  <li><a href={`${Privacy}`} rel="noreferrer" target="_blank"> Privacy Policy</a></li>
                  <li><a href={`${Cookie}`} rel="noreferrer" target="_blank"> Cookie Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default AdvFooter;
