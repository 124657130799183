import React from "react";

const Loader = ({ showLoad }) => {
  return (
    <>
      <section className={`bg-sec ${showLoad}`}>
        <div className="img-div">
          <img src="/assets/Followup/img/wave.png" />
        </div>
        <div className={`container-fluid text-center`}>
          <div className="row">
            <div className="col-12 ">
              <div id="myDiv" className={`space text-center`}>
                <img src="/assets/Followup/img/logo_bg.png" alt="" className="bglogo" />
              </div>
            </div>
            <div className="col-12 loader-mob">
              <img id="mymg" src="/assets/Followup/img/review.gif" alt="" />
              <h3 className="flp-loader text-center">Analysing your application....</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loader;
