import React, { Fragment, useState } from "react";

const Footer = ({ Sass }) => {

  const [privacyModalShow, setPrivacyModalShow] = useState(false);
  const [termsModalShow, setTermsModalShow] = useState(false);

  return (

    <Fragment>
      <footer className={`text-white py-4`}>
        <div className={`container`}>
          <div className={'row'}>
            <div className={'col-lg-7 col-md-6 col-12 text-left'}>
              <h3>© Boomerang Claims Pty Limited  </h3>
              <p>ABN 71 623 924 087</p>
              <p>contact@boomerangclaims.com</p>
              <p>Boomerang Claims Limited, Level 2 41A Bay Road, Taren Point, New South Wales, NSW 2229</p>
              <p>Boomerang Claims are proud members of the Australian Financial Complaints Authority.
                Our membership number is 89098
              </p>
              <p>When deciding how to proceed with your Junk Insurance claim, remember you always have the right to
                contact AFCA for free and impartial advice and support with your claim.
                Call AFCA free on 1800 931 678
              </p>
            </div>
            <div className={'col-lg-5 col-md-6 col-12 text-end'}>
              <img src="/assets/ADV_BC_CCI_V1/img/logo.png" alt="" />
            </div>

            <div className="col-lg-12 col-md-12 col-12 text-center">
              <ul className="list-unstyled d-inline">
                <li className="d-inline-block border-right border-2 px-2 my-2">
                  <a href="/privacy" target="_blank"> Privacy Policy</a>
                </li>
                <li className="d-inline-block px-2 my-2">
                  <a href="/terms" target="_blank"> Terms of Use</a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
