import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import "./App.scss";
import UserFormMCS_V1 from "./Components/Forms/UserFormMCS_V1";
import ADV_BC_CCI_V1 from "./Components/Pages/Advertorials/ADV_BC_CCI_V1";
import AMCS_ADV_1 from "./Components/Pages/Advertorials/AMCS_ADV_1";
import FbPixel from "./Components/Pages/FbPixel";
import Followup from "./Components/Pages/Followup/Followup";
import HomePage from "./Components/Pages/HomePage";
import Lender from "./Components/Pages/Lender";
import BMC_V2 from "./Components/Pages/Splits/BMC_V2";
import ThankyouV2 from "./Components/Includes/Layouts/BMC_V2​/ThankyouV2";
import privacyV2 from "./Components/Includes/Layouts/BMC_V2​/PrivacyV2";
import TermsV2 from "./Components/Includes/Layouts/BMC_V2​/TermsV2";
import Lender_V2 from "./Components/Pages/Lender_V2";
import NotFound from "./Components/Pages/NotFound";
import Privacy from "./Components/Pages/Privacy";
import Signature from "./Components/Pages/Signature";
import BMC_V1 from "./Components/Pages/Splits/BMC_V1";
import BMC_V3 from "./Components/Pages/Splits/BMC_V3";
import MCS_V1 from "./Components/Pages/Splits/MCS_V1";
import UserDocument from "./Components/Pages/Splits/UserDocument";
import Terms from "./Components/Pages/Terms";
import Thankyou from "./Components/Pages/Thankyou";
import Unqualified from "./Components/Pages/Unqualified";
import { QueryStringProvider } from "./Contexts/QueryString";
import { RedirectProvider } from "./Contexts/RedirectContext";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import DocView from "./Components/Includes/Layouts/BMC_P_V5/DocView";
import PdfView from "./Components/Includes/Layouts/BMC_P_V5/PdfView";

const BMC_P_V5 = lazy(() => import("./Components/Pages/Splits/BMC_P_V5"));
const BMC_P_V5_1 = lazy(() => import("./Components/Pages/Splits/BMC_P_V5_1"));
const BMC_P_V5_2 = lazy(() => import("./Components/Pages/Splits/BMC_P_V5_2"));
const BMC_P_V6 = lazy(() => import("./Components/Pages/Splits/BMC_P_V6"));
const ProceedPage = lazy(() =>
  import("./Components/Includes/Layouts/BMC_P_V5_2/ProceedPage")
);
const FollowupProceedPage = lazy(() =>
  import("./Components/Pages/Followup/FollowupProceedPage")
);

const ExpediteRefundsPage = lazy(() =>
  import("./Components/Includes/Layouts/BMC_P_V5_2/ExpediteRefunds")
);
const FollowupExpediteRefundsPage = lazy(() =>
  import("./Components/Pages/Followup/FollowupExpediteRefunds")
);

const Lender_P_V5 = lazy(() => import("./Components/Pages/Lender_P_V5"));
const Lender_P_V5_1 = lazy(() => import("./Components/Pages/Lender_P_V5_1"));

const Lender_P_V6 = lazy(() => import("./Components/Pages/Lender_P_V6"));

const Lender_P_V5_2 = lazy(() => import("./Components/Pages/Lender_P_V5_2"));
const Thankyou_P_V5 = lazy(() => import("./Components/Pages/Thankyou_P_V5"));
const Followup_P_V5 = lazy(() =>
  import("./Components/Pages/Followup/Followup_P_V5")
);
const AgreementUpload_P_V5 = lazy(() =>
  import("./Components/Pages/AgreementUpload_P_V5")
);

const AgreementUpload_P_V5_2 = lazy(() =>
  import("./Components/Pages/AgreementUpload_P_V5_2")
);

const AgreementUpload_Followup_P_V5 = lazy(() =>
  import("./Components/Pages/AgreementUpload_Followup_P_V5")
);

const AgreementUpload_Followup_P_V6 = lazy(() =>
  import("./Components/Pages/AgreementUpload_Followup_P_V6")
);

const AgreementUpload_Followup_P_V5_2 = lazy(() =>
  import("./Components/Pages/AgreementUpload_Followup_P_V5_2")
);
const AgreementUpload_P_V6 = lazy(() =>
  import("./Components/Pages/AgreementUpload_P_V6")
);

const IdUploadV5 = lazy(() => import("./Components/Pages/Splits/IdUploadV5"));
const IdUploadV6 = lazy(() => import("./Components/Pages/Splits/IdUploadV6"));
const IdUpload_Followup_P_V5 = lazy(() =>
  import("./Components/Pages/Splits/IdUpload_Followup_P_V5")
);

const Followup_P_V6 = lazy(() =>
  import("./Components/Pages/Followup/Followup_P_V6")
);

const IdUpload_Followup_P_V6 = lazy(() =>
  import("./Components/Pages/Splits/IdUpload_Followup_P_V6")
);

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <Suspense fallback={<div>Loading...</div>}>
              <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/MCS_V1"]} component={MCS_V1} />
                <Route exact path={["/BMC_V1"]} component={BMC_V1} />
                <Route exact path={["/BMC_V3"]} component={BMC_V3} />
                <Route exact path={["/BMC_P_V5"]} component={BMC_P_V5} />
                <Route exact path={["/BMC_P_V5.1"]} component={BMC_P_V5_1} />
                <Route exact path={["/BMC_P_V5.2"]} component={BMC_P_V5_2} />
                <Route exact path={["/BMC_P_V6"]} component={BMC_P_V6} />
                <Route exact path={["/proceed-page"]} component={ProceedPage} />
                <Route
                  exact
                  path={["/followup/proceed-page"]}
                  component={FollowupProceedPage}
                />
                <Route
                  exact
                  path={["/expedite-refunds"]}
                  component={ExpediteRefundsPage}
                />
                <Route
                  exact
                  path={["/followup/expedite-refunds"]}
                  component={FollowupExpediteRefundsPage}
                />
                <Route exact path={["/lender"]} component={Lender} />
                <Route exact path={["/lender_V2"]} component={Lender_V2} />
                <Route exact path={["/lender_P_V5"]} component={Lender_P_V5} />
                <Route
                  exact
                  path={["/lender_P_V5.1"]}
                  component={Lender_P_V5_1}
                />

                <Route exact path={["/lender_P_V6"]} component={Lender_P_V6} />

                <Route
                  exact
                  path={["/lender_P_V5.2"]}
                  component={Lender_P_V5_2}
                />
                <Route exact path={["/id-upload"]} component={UserDocument} />
                <Route exact path={["/id-upload-v5"]} component={IdUploadV5} />
                <Route exact path={["/id-upload-v6"]} component={IdUploadV6} />

                <Route
                  exact
                  path={["/followup/id-upload-v5"]}
                  component={IdUpload_Followup_P_V5}
                />

                <Route
                  exact
                  path={["/followup/id-upload-v6"]}
                  component={IdUpload_Followup_P_V6}
                />

                <Route exact path={["/AMCS_ADV_1"]} component={AMCS_ADV_1} />
                <Route
                  exact
                  path={["/BC-CCI_YH-ADV-V1"]}
                  component={ADV_BC_CCI_V1}
                />
                <Route exact path={"/signature"} component={Signature} />
                <Route exact path={["/thankyou"]} component={Thankyou} />
                <Route
                  exact
                  path={["/thankyou_p_v5"]}
                  component={Thankyou_P_V5}
                />
                <Route exact path={"/privacy"} component={Privacy} />
                <Route exact path={["/terms"]} component={Terms} />
                <Route exact path={["/unqualified"]} component={Unqualified} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={["/user-info"]} component={UserFormMCS_V1} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/followup"]} component={Followup} />
                <Route
                  exact
                  path={["/followup_p_v5"]}
                  component={Followup_P_V5}
                />

                <Route
                  exact
                  path={["/followup_p_v6"]}
                  component={Followup_P_V6}
                />

                <Route
                  exact
                  path={["/agreement-upload"]}
                  component={AgreementUpload_P_V5}
                />

                <Route
                  exact
                  path={["/agreement-upload-v2"]}
                  component={AgreementUpload_P_V5_2}
                />

                <Route
                  exact
                  path={["/followup/agreement-upload"]}
                  component={AgreementUpload_Followup_P_V5}
                />

                <Route
                  exact
                  path={["/followup/agreement-upload-v6"]}
                  component={AgreementUpload_Followup_P_V6}
                />

                <Route
                  exact
                  path={["/followup/agreement-upload-v2"]}
                  component={AgreementUpload_Followup_P_V5_2}
                />
                <Route
                  exact
                  path={["/agreement-upload-v6"]}
                  component={AgreementUpload_P_V6}
                />
                <Route exact path={["/BMC_V2"]} component={BMC_V2} />
                <Route exact path={["/thankyouV2"]} component={ThankyouV2} />
                <Route exact path={["/privacyV2"]} component={privacyV2} />
                <Route exact path={["/TermsV2"]} component={TermsV2} />
                <Route
                  exact
                  path={["/user_docs/:filename"]}
                  component={DocView}
                />

                <Route
                  exact
                  path={["/:docType/doc058/:filename"]}
                  component={PdfView}
                />
              </BrowserRouter>
            </Suspense>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
