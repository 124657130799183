import { useState } from "react";
import { Api } from "../api/Api";

export const useDataIngestion = () => {
  const [isLoading, setIsLoading] = useState(false);

  const saveDataIngestion = async (
    visitor_parameters,
    data,
    message_type,
    page_name,
    query_string,
    visitor_data,
    otherProps = {}
  ) => {
    setIsLoading(true);

    try {
      const response = await Api.post("v1/data-ingestion-pipeline", {
        visitor_parameters,
        data,
        message_type,
        page_name,
        query_string,
        visitor_data,
        ...otherProps,
      });
      setIsLoading(false);

      return response;
    } catch (error) {}

    setIsLoading(false);
  };
  return { saveDataIngestion, isLoading };
};
