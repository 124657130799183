import React from "react";

const InputButton = ({ value, className, name, id, onClick, btnType, disabled, style }) => {
  return (
    <input
      className={className}
      name={name}
      id={id}
      style={style}
      onClick={onClick}
      type={btnType}
      disabled={disabled}
      value={value}
    />
  );
};
export default InputButton;
