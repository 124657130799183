import React from "react";

const BirthDateModal = ({ modalClass }) => {
  const closModal = (e) => {
    document.body.classList.remove("no-scroll");
    document.getElementById("analayzeModal").classList.remove('show');
    document.getElementById("analayzeModal").classList.add('hide');
    document.body.classList.remove("modal-open");
  }
  return (
    <div
      id="analayzeModal" className={`${modalClass}  analyseClass`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="TermsModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg formmodal" id="analyzemodal" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => { closModal(e); }}></button>
            </div>
            <div className="modal-body">
              Your age can influence your options.
                  </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default BirthDateModal;
