import React from "react";
const UnqualifiedHeader = () => {
  return (
    <>
      <header className="privacy_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 text-center ">
              <a href="/BMC_P_V5">
                <img src="/assets/BMC_V3/img/logo.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default UnqualifiedHeader;
