import React from 'react';
import TC from "../../../../assets/Pdf/Terms.pdf";
import Privacy from "../../../../assets/Pdf/Privacy.pdf";
import Cookie from "../../../../assets/Pdf/Cookies.pdf";
const Footer = () => {

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p>DISCLAIMER: The material on this website is intended only to provide a summary and
                                general overview on matters of interest.</p>
              <p>Copyright © 2022 medicare-saver.com - All rights reserved</p>
              <div className="copyright">
                <ul>
                  <li><a href={`${TC}`}
                    target="_blank"> Terms and Conditions</a></li>
                  <li><a href={`${Privacy}`}
                    target="_blank"> Privacy Policy</a></li>
                  <li><a href={`${Cookie}`}
                    target="_blank"> Cookie Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;